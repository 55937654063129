import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import {
  TProfilePoints,
  IProfilePoint,
  TIntervalTypes,
  IInterval,
  TIntervals,
  TPipeTypes,
  IElement,
  TElementTypes,
  TElements,
  IArea,
  TAreas,
  IEquip,
  TEquips,
  TEquipElementTypes,
  IEquipElementType,
  TEquipElements,
  IEquipElement,
  TEquipAreas,
  IEquipArea,
  TCarvingTypes,
  TCustomConnectionTypes,
  TLockTypes,
  TSteelGrades,
  ISteelGrade,
  IEquipConnection,
  ICustomConnectionType,
  IFileUpload,
  ICheckStructData,
  IWellboreValidation,
  TProfileShapes,
  IActivationData,
  IWellboreByStatus,
  IActivateTransferWellbore,
  ICarvingType,
  ILockType,
  TProfilePointStatuses,
  IProfileCheckStructData,
  ITransferInclinometryData,
  IMoveToData,
} from '@/types'
import Vue from 'vue'
import {
  getProfilePointsByWellboreId,
  deleteProfilePoint,
  addProfilePoint,
  saveProfilePoint,
  getIntervalTypes,
  addInterval,
  getIntervals,
  deleteInterval,
  saveInterval,
  getPipeTypes,
  getElementTypes,
  addIntervalType,
  addElementType,
  addElement,
  getElement,
  getElements,
  saveElement,
  deleteElement,
  getAreas,
  addArea,
  saveArea,
  deleteArea,
  getEquips,
  addEquip,
  saveEquip,
  deleteEquip,
  getEquipElementTypes,
  addEquipElementType,
  addEquipElement,
  getEquipElements,
  saveEquipElement,
  deleteEquipElement,
  getEquipAreas,
  addEquipArea,
  saveEquipArea,
  deleteEquipArea,
  getSteelGrades,
  addSteelGrade,
  saveEquipElementType,
  saveSteelGrade,
  getWellboreVisualizaion,
  getCarvingTypes,
  getLockTypes,
  getCustomConnectionTypes,
  getEquipElementConnection,
  addEquipElementConnection,
  saveEquipElementConnection,
  deleteEquipElementConnection,
  getProfileShapes,
  addPipeType,
  addCustomConnectionType,
  getProfilePointsFile,
  uploadProfilePointsFile,
  cloneEquip,
  checkStruct,
  getWellboreValidateInfo,
  getEquip,
  activateIntegration,
  getWellboreByStatus,
  getWellboreActivationInfo,
  activateWellboreTransfer,
  deactivateIntegration,
  addCarvingConnectionType,
  saveCarvingConnectionType,
  addLockConnectionType,
  saveLockConnectionType,
  saveCustomConnectionType,
  addProfileShape,
  getCustomTypeById,
  getCarvingTypeById,
  getLockTypeById,
  getProfileShapeById,
  checkFullStruct,
  getSteelgradeById,
  getProfileGraph,
  getProfilePointStatuses,
  checkInterval,
  checkEquip,
  clearProfilePoints,
  checkProfile,
  normalizeInclinometry,
  transferInclinometry,
  addProfilePointIntegration,
  uploadProfilePointsFileIntegration,
  checkIntegration,
  arrangeIntervals,
  arrangeIntervalsElements,
  arrangeIntervalsElementsAreas,
  arrangeEquipElements,
  arrangeEquipElementsAreas,
  moveToIntervals,
} from '@/services/wellbore'

import { getPage } from '@/services/common'
import { Dictionary } from 'vue-router/types/router'

export interface IWellboreState {
  profilePoints: TProfilePoints
  intervalTypes: TIntervalTypes
  intervals: TIntervals
  pipeTypes: TPipeTypes
  elementTypes: TElementTypes
  elements: TElements
  areas: TAreas
  equips: TEquips
  equipeElementTypes: TEquipElementTypes
  equipeElements: TEquipElements
  equipeAreas: TEquipAreas
  lockTypes: TLockTypes
  carvingTypes: TCarvingTypes
  customConnectionTypes: TCustomConnectionTypes
  profileShapes: TProfileShapes
}

interface ICounts {
  profilePoints: number | null
  intervalTypes: number | null
  intervals: number | null
  elementTypes: number | null
  pipeTypes: number | null
  equips: number | null
  equipeElementTypes: number | null
  profileShapes: number | null
  steelgrades: number | null
  lockTypes: number | null
  carvingTypes: number | null
  customConnectionTypes: number | null
}

type TCounts =
  | 'profilePoints'
  | 'intervalTypes'
  | 'intervals'
  | 'elementTypes'
  | 'pipeTypes'
  | 'equips'
  | 'equipeElementTypes'
  | 'customConnectionTypes'
  | 'profileShapes'
  | 'steelgrades'
  | 'lockTypes'
  | 'carvingTypes'

@Module({ dynamic: true, store, name: 'wellbore' })
class WellboreState extends VuexModule implements IWellboreState {
  public profilePoints: TProfilePoints = []

  public intervalTypes: TIntervalTypes = []

  public intervals: TIntervals = []

  public elementTypes: TElementTypes = []

  public elements: TElements = []

  public pipeTypes: TPipeTypes = []

  public pointStatuses: TProfilePointStatuses = []

  public areas: TAreas = []

  public equips: TEquips = []

  public equipeElementTypes: TEquipElementTypes = []

  public equipeElements: TEquipElements = []

  public equipeAreas: TEquipAreas = []

  public lockTypes: TLockTypes = []

  public carvingTypes: TCarvingTypes = []

  public customConnectionTypes: TCustomConnectionTypes = []

  public profileShapes: TProfileShapes = []

  public steelgrades: TSteelGrades = []

  public wellboreValidation: IWellboreValidation | null = null

  public counts: ICounts = {
    profilePoints: null,
    intervalTypes: null,
    intervals: null,
    elementTypes: null,
    pipeTypes: null,
    equips: null,
    equipeElementTypes: null,
    customConnectionTypes: null,
    profileShapes: null,
    steelgrades: null,
    lockTypes: null,
    carvingTypes: null,
  }

  @Mutation
  public SetProfilePoints(profilePoints: TProfilePoints) {
    profilePoints.map((item) => {
      this.profilePoints.push(item)
    })
  }

  @Mutation
  public SetProfilePointsStatuses(statuses: TProfilePointStatuses) {
    this.pointStatuses = statuses
  }

  @Mutation
  public SetIntervalTypes(intervalTypes: TIntervalTypes) {
    intervalTypes.map((item) => {
      this.intervalTypes.push(item)
    })
  }

  @Mutation
  public SetIntervals(intervals: TIntervals) {
    intervals.map((item) => {
      this.intervals.push(item)
    })
  }

  @Mutation
  public SaveProfilePoint(point: IProfilePoint) {
    const index = this.profilePoints.findIndex((item) => item.id === point.id)
    Vue.set(this.profilePoints, index, point)
  }

  @Mutation
  public SetInterval(interval: IInterval) {
    const index = this.intervals.findIndex((item) => item.id === interval.id)
    const elements_count = this.intervals[index].elements_count
    Vue.set(this.intervals, index, { ...interval, elements_count })
  }

  @Mutation
  public SetPipeTypes(pipeTypes: TPipeTypes) {
    pipeTypes.map((item) => {
      this.pipeTypes.push(item)
    })
  }

  @Mutation
  public SetElementTypes(elementTypes: TElementTypes) {
    elementTypes.map((item) => {
      this.elementTypes.push(item)
    })
  }

  @Mutation
  public SetElements(elements: TElements) {
    this.elements = elements
  }

  @Mutation
  public SetArrayElements(elements: TElements) {
    elements.map((item) => {
      this.elements.push(item)
    })
  }

  @Mutation
  public AddElement(element: IElement) {
    Vue.set(this.elements, this.elements.length, element)
  }

  @Mutation
  public DeleteElement(intId: number) {
    const index = this.elements.findIndex((item: IElement) => item.id === intId)
    this.elements.splice(index, 1)
  }

  @Mutation
  public SetElement(element: IElement) {
    const index = this.elements.findIndex((item) => item.id === element.id)
    Vue.set(this.elements, index, element)
  }

  @Mutation
  public SetAreas(areas: TAreas) {
    this.areas = areas
  }

  @Mutation
  public AddArea(area: IArea) {
    Vue.set(this.areas, this.areas.length, area)
  }

  @Mutation
  public DeleteArea(areaId: number) {
    const index = this.areas.findIndex((item: IArea) => item.id === areaId)
    this.areas.splice(index, 1)
  }

  @Mutation
  public SetArea(area: IArea) {
    const index = this.areas.findIndex((item) => item.id === area.id)
    Vue.set(this.areas, index, area)
  }

  @Mutation
  public SetEquips(equips: TEquips) {
    equips.map((item) => {
      this.equips.push(item)
    })
  }

  @Mutation
  public SetEquip(equip: IEquip) {
    const index = this.equips.findIndex((item) => item.id === equip.id)
    Vue.set(this.equips, index, equip)
  }

  @Mutation
  public SetEquipElementTypes(elementTypes: TEquipElementTypes) {
    elementTypes.map((item) => {
      this.equipeElementTypes.push(item)
    })
  }

  @Mutation
  public SetEquipElementType(elementType: IEquipElementType) {
    const index = this.equipeElementTypes.findIndex(
      (item) => item.id === elementType.id
    )
    Vue.set(this.equipeElementTypes, index, elementType)
  }

  @Mutation
  public SetEquipElements(elements: TEquipElements) {
    this.equipeElements = elements
  }

  @Mutation
  public AddEquipElement(element: IEquipElement) {
    Vue.set(this.equipeElements, this.equipeElements.length, element)
  }

  @Mutation
  public DeleteEquipElement(eqId: number) {
    const index = this.equipeElements.findIndex(
      (item: IEquipElement) => item.id === eqId
    )
    this.equipeElements.splice(index, 1)
  }

  @Mutation
  public SetEquipElement(element: IEquipElement) {
    const index = this.equipeElements.findIndex(
      (item) => item.id === element.id
    )
    Vue.set(this.equipeElements, index, element)
  }

  @Mutation
  public SetEquipAreas(areas: TEquipAreas) {
    this.equipeAreas = areas
  }

  @Mutation
  public AddEquipArea(area: IEquipArea) {
    Vue.set(this.equipeAreas, this.equipeAreas.length, area)
  }

  @Mutation
  public DeleteEquipArea(areaId: number) {
    const index = this.equipeAreas.findIndex(
      (item: IEquipArea) => item.id === areaId
    )
    this.equipeAreas.splice(index, 1)
  }

  @Mutation
  public SetEquipArea(area: IEquipArea) {
    const index = this.equipeAreas.findIndex((item) => item.id === area.id)
    Vue.set(this.equipeAreas, index, area)
  }

  @Mutation
  public SetSteelGrades(steelgrades: TSteelGrades) {
    steelgrades.map((item) => {
      this.steelgrades.push(item)
    })
  }

  @Mutation
  public SetSteelGrade(steelgrade: ISteelGrade) {
    const index = this.steelgrades.findIndex(
      (item) => item.id === steelgrade.id
    )
    Vue.set(this.steelgrades, index, steelgrade)
  }

  @Mutation
  public SetArrayEquipAreas(areas: TEquipAreas) {
    areas.map((item) => {
      this.equipeAreas.push(item)
    })
  }

  @Mutation
  public SetLockTypes(types: TLockTypes) {
    types.map((item) => {
      this.lockTypes.push(item)
    })
  }

  @Mutation
  public SetCarvingTypes(types: TCarvingTypes) {
    types.map((item) => {
      this.carvingTypes.push(item)
    })
  }

  @Mutation
  public SetProfileShapes(types: TProfileShapes) {
    types.map((item) => {
      this.profileShapes.push(item)
    })
  }

  @Mutation
  public SetCustomConnectionTypes(types: TCustomConnectionTypes) {
    types.map((item) => {
      this.customConnectionTypes.push(item)
    })
  }

  @Mutation
  public SaveLockConnectionTypes(connection: ILockType) {
    const index = this.lockTypes.findIndex((item) => item.id === connection.id)
    Vue.set(this.lockTypes, index, connection)
  }

  @Mutation
  public SaveCustomConnectionTypes(connection: ICustomConnectionType) {
    const index = this.customConnectionTypes.findIndex(
      (item) => item.id === connection.id
    )
    Vue.set(this.customConnectionTypes, index, connection)
  }

  @Mutation
  public SaveCarvingConnectionTypes(connection: ICarvingType) {
    const index = this.carvingTypes.findIndex(
      (item) => item.id === connection.id
    )

    const result =
      typeof connection.profile_shape === 'object'
        ? {
            ...connection,
            profile_shape: connection.profile_shape?.id,
          }
        : connection
    Vue.set(this.carvingTypes, index, result)
  }

  @Mutation
  public SetCountWellbore(data: { type: TCounts; count: number | null }) {
    this.counts[data.type] = data.count
  }

  @Mutation
  public ClearWellboreState() {
    this.profilePoints = []
    this.intervalTypes = []
    this.intervals = []
    this.elementTypes = []
    this.pipeTypes = []
    this.equips = []
    this.equipeElementTypes = []
    this.lockTypes = []
    this.carvingTypes = []
    this.customConnectionTypes = []
    this.profileShapes = []
    this.steelgrades = []
    this.counts.profilePoints = null
    this.counts.intervalTypes = null
    this.counts.intervals = null
    this.counts.elementTypes = null
    this.counts.pipeTypes = null
    this.counts.equips = null
    this.counts.equipeElementTypes = null
    this.counts.customConnectionTypes = null
    this.counts.profileShapes = null
    this.counts.steelgrades = null
  }

  @Mutation
  public ClearData(data: TCounts) {
    this[data] = []
  }

  @Mutation
  public SetValidation(data: IWellboreValidation) {
    this.wellboreValidation = data
  }

  @Action
  public CLEAR_DATA(data: TCounts) {
    this.SetCountWellbore({ type: data, count: null })
    this.ClearData(data)
  }

  @Action
  public async RELOAD_PROFILEPOINTS(wellboreId: number) {
    await this.GET_PROFILEPOINTS({ wellboreId, clear: true })
    await this.GET_WELLBORE_VALIDATION(wellboreId)
  }

  @Action
  public async RELOAD_INTERVALS(wellboreId: number) {
    await this.GET_INTERVALS({ wellboreId, clear: true })
  }

  @Action
  public async RELOAD_ELEMENTS(intervalId: number) {
    await this.GET_ELEMENTS(intervalId)
  }

  @Action
  public async RELOAD_EQUIP_ELEMENTS(intervalId: number) {
    await this.GET_EQUIP_ELEMENTS(intervalId)
  }

  @Action
  public async RELOAD_EQUIP_AREAS(intervalId: number) {
    await this.GET_EQUIP_AREAS(intervalId)
  }

  @Action
  public async RELOAD_ELEMENTS_AREAS(elementId: number) {
    await this.GET_AREAS(elementId)
  }

  @Action
  public async RELOAD_INTERVAL_TYPES() {
    await this.GET_INTERVAL_TYPES(true)
  }

  @Action
  public async RELOAD_PIPE_TYPES() {
    await this.GET_PIPE_TYPES(true)
  }

  @Action
  public async RELOAD_ELEMENT_TYPES() {
    await this.GET_ELEMENT_TYPES(true)
  }

  @Action
  public async RELOAD_EQUIP_ELEMENT_TYPES() {
    await this.GET_EQUIP_ELEMENT_TYPES(true)
  }

  @Action
  public async RELOAD_EQUIPS(wellboreId: number) {
    await this.GET_EQUIPS({ wellboreId, clear: true })
  }

  @Action
  public async RELOAD_STEEL_GRADES() {
    await this.GET_STEEL_GRADES(true)
  }

  @Action
  public async RELOAD_PROFILE_SHAPES() {
    await this.GET_PROFILE_SHAPES(true)
  }

  @Action
  public async RELOAD_CUSTOM_CONNECTION_TYPE() {
    await this.GET_CUSTOM_CONNECTION_TYPES(true)
  }

  @Action
  public async RELOAD_LOCK_CONNECTION_TYPE() {
    await this.GET_LOCK_TYPES(true)
  }

  @Action
  public async RELOAD_CARVING_CONNECTION_TYPE() {
    await this.GET_CARVING_TYPES(true)
  }

  @Action
  public async GET_PROFILEPOINTS(data: {
    wellboreId: number
    clear?: boolean
  }) {
    const { wellboreId, clear } = data
    const page = clear
      ? 1
      : getPage(this.counts.profilePoints, this.profilePoints.length)

    if (page !== null) {
      const data = await getProfilePointsByWellboreId(wellboreId, page)
      if (clear) void this.CLEAR_DATA('profilePoints')
      this.SetCountWellbore({ type: 'profilePoints', count: data.count })
      if (data.results) {
        this.SetProfilePoints(data.results)
      }
    }
  }

  @Action
  public async CLEAR_PROFILEPOINTS(data: { id: number }) {
    const result = await clearProfilePoints(data.id)
    return result
  }

  @Action
  public async GET_PROFILEPOINTS_STATUSES() {
    if (!this.pointStatuses.length) {
      const result = await getProfilePointStatuses()
      this.SetProfilePointsStatuses(result.results)
    }
  }

  @Action
  public async GET_PROFILEPOINTS_FILE(smart = false) {
    const file = await getProfilePointsFile(smart)
    return file
  }

  @Action
  public async UPLOAD_PROFILEPOINTS_FILE(data: IFileUpload) {
    const result = await uploadProfilePointsFile(data)
    return result
  }

  @Action
  public async UPLOAD_PROFILEPOINTS_FILE_INTEGRATION(data: IFileUpload) {
    const result = await uploadProfilePointsFileIntegration(data)
    return result
  }

  @Action
  public async DELETE_PROFILEPOINTS(data: {
    pointId: number
    wellboreId: number
  }) {
    await deleteProfilePoint(data.pointId)
    await this.RELOAD_PROFILEPOINTS(data.wellboreId)
  }

  @Action
  public async ADD_PROFILEPOINTS(point: IProfilePoint) {
    const response = await addProfilePoint(point)
    if (response) {
      await this.RELOAD_PROFILEPOINTS(Number(point.wellbore))
      return response
    }
  }

  @Action
  public async SAVE_PROFILEPOINTS(point: IProfilePoint) {
    const response = await saveProfilePoint(point)
    if (response) this.SaveProfilePoint(response)
  }

  @Action
  public async ADD_PROFILEPOINTS_INTEGRATION(point: IProfilePoint) {
    const response = await addProfilePointIntegration(point)
    if (response) {
      await this.RELOAD_PROFILEPOINTS(Number(point.wellbore))
    }
    return response
  }

  @Action
  public async GET_INTERVAL_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.intervalTypes, this.intervalTypes.length)

    if (page !== null) {
      const data = await getIntervalTypes(page)
      if (clear) void this.CLEAR_DATA('intervalTypes')
      this.SetCountWellbore({ type: 'intervalTypes', count: data.count })
      if (data.results) {
        this.SetIntervalTypes(data.results)
      }
    }
  }

  @Action
  public async GET_INTERVALS(data: { wellboreId: number; clear?: boolean }) {
    const { wellboreId, clear } = data
    const page = clear
      ? 1
      : getPage(this.counts.intervals, this.intervals.length)

    if (page !== null) {
      const data = await getIntervals(wellboreId, page)
      if (clear) void this.CLEAR_DATA('intervals')
      this.SetCountWellbore({ type: 'intervals', count: data.count })
      if (data.results) {
        this.SetIntervals(data.results)
      }
    }
  }

  @Action
  public async ADD_INTERVAL_TYPE(name: string) {
    const typeResp = await addIntervalType(name)
    if (typeResp) {
      await this.RELOAD_INTERVAL_TYPES()
      return typeResp
    }
  }

  @Action
  public async ADD_INTERVAL(interval: IInterval) {
    const intervalResp = await addInterval(interval)
    if (intervalResp) {
      await this.RELOAD_INTERVALS(Number(interval.wellbore))
      return intervalResp
    }
  }

  @Action
  public async SAVE_INTERVAL(interval: IInterval) {
    const intervalResp = await saveInterval(interval)
    const result = this.intervals
    if (result.length && intervalResp) {
      this.SetInterval({ ...interval, ...intervalResp })
    }
  }

  @Action
  public async DELETE_INTERVAL(data: { intId: number; wellboreId: number }) {
    await deleteInterval(data.intId)
    await this.RELOAD_INTERVALS(data.wellboreId)
  }

  @Action
  public async GET_PIPE_TYPES(clear?: true) {
    const page = clear
      ? 1
      : getPage(this.counts.pipeTypes, this.pipeTypes.length)

    if (page !== null) {
      const data = await getPipeTypes(page)
      if (clear) void this.CLEAR_DATA('pipeTypes')
      this.SetCountWellbore({ type: 'pipeTypes', count: data.count })
      if (data.results) {
        this.SetPipeTypes(data.results)
      }
    }
  }

  @Action
  public async ADD_PIPE_TYPE(name: string) {
    const typeResp = await addPipeType(name)
    if (typeResp) {
      await this.RELOAD_PIPE_TYPES()
      return typeResp
    }
  }

  @Action
  public async GET_ELEMENT_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.elementTypes, this.elementTypes.length)

    if (page !== null) {
      const data = await getElementTypes(page)
      if (clear) void this.CLEAR_DATA('elementTypes')
      this.SetCountWellbore({ type: 'elementTypes', count: data.count })
      if (data.results) {
        this.SetElementTypes(data.results)
      }
    }
  }

  @Action
  public async ADD_ELEMENT_TYPES(name: string) {
    const result = await addElementType(name)
    if (result) {
      await this.RELOAD_ELEMENT_TYPES()
      return result
    }
  }

  @Action
  public async GET_ELEMENTS(intervalId: number) {
    const elements = await getElements(intervalId)
    this.SetElements(elements)
  }

  @Action
  public async GET_ELEMENT(elementId: number) {
    const element = await getElement(elementId)
    return element
  }

  @Action
  public async ADD_ELEMENT(element: IElement) {
    const elementResp = await addElement(element)
    if (elementResp) {
      this.AddElement(elementResp)
    }
    return elementResp
  }

  @Action
  public async SAVE_ELEMENT(element: IElement) {
    const elementResp = await saveElement(element)
    const result = this.elements
    if (result.length && elementResp) {
      this.SetElement({
        ...element,
        ...elementResp,
        elementtype: element.elementtype,
      })
      return elementResp
    }
  }

  @Action
  public async DELETE_ELEMENT(elementId: number) {
    await deleteElement(elementId)
    this.DeleteElement(elementId)
  }

  @Action
  public async GET_AREAS(elementId: number) {
    const areas = await getAreas(elementId)
    this.SetAreas(areas)
  }

  @Action
  public async ADD_AREA(area: IArea) {
    const areaResp = await addArea(area)
    if (areaResp) {
      this.AddArea(areaResp)
    }
    return areaResp
  }

  @Action
  public async SAVE_AREA(area: IArea) {
    const areaResp = await saveArea(area)
    const result = this.areas
    if (result.length && areaResp) {
      this.SetArea(areaResp)
      return areaResp
    }
  }

  @Action
  public async DELETE_AREA(areaId: number) {
    await deleteArea(areaId)
    this.DeleteArea(areaId)
  }

  @Action
  public async GET_EQUIPS(data: {
    wellboreId: number | null
    clear?: boolean
    query?: Dictionary<string>
  }) {
    if (data.wellboreId !== null) {
      const { wellboreId, clear, query } = data
      const page = clear ? 1 : getPage(this.counts.equips, this.equips.length)
      const id =
        wellboreId === null || wellboreId === 0 ? undefined : wellboreId
      if (page !== null && id) {
        const data = await getEquips(id, page, query)
        if (clear) void this.CLEAR_DATA('equips')
        this.SetCountWellbore({ type: 'equips', count: data.count })
        if (data.results) {
          this.SetEquips(data.results)
        }
      }
    }
  }

  @Action
  public async GET_EQUIP(id: number) {
    const result = await getEquip(id)
    if (result) {
      return result
    }
  }

  @Action
  public async ADD_EQUIP(data: { equip: IEquip; wellboreId: number }) {
    const result = await addEquip(data.equip)
    if (result) {
      await this.RELOAD_EQUIPS(data.wellboreId)
      return result
    }
  }

  @Action
  public async SAVE_EQUIP(equip: IEquip) {
    const equipResp = await saveEquip(equip)
    const result = this.equips
    if (result.length && equipResp) {
      this.SetEquip(equipResp)
    }
  }

  @Action
  public async DELETE_EQUIP(data: { equipId: number; wellboreId: number }) {
    await deleteEquip(data.equipId)
    await this.RELOAD_EQUIPS(data.wellboreId)
  }

  @Action
  public async CLONE_EQUIP(data: { equipId: number; wellboreId: number }) {
    await cloneEquip(data.equipId)
    await this.RELOAD_EQUIPS(data.wellboreId)
  }

  @Action
  public async GET_EQUIP_ELEMENT_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.equipeElementTypes, this.equipeElementTypes.length)

    if (page !== null) {
      const data = await getEquipElementTypes(page)
      if (clear) void this.CLEAR_DATA('equipeElementTypes')
      this.SetCountWellbore({ type: 'equipeElementTypes', count: data.count })
      if (data.results) {
        this.SetEquipElementTypes(data.results)
      }
    }
  }

  @Action
  public async ADD_EQUIP_ELEMENT_TYPES(elemType: IEquipElementType) {
    const result = await addEquipElementType(elemType)
    if (result) {
      await this.RELOAD_EQUIP_ELEMENT_TYPES()
      return result
    }
  }

  @Action
  public async SAVE_EQUIP_ELEMENT_TYPES(type: IEquipElementType) {
    const typeResp = await saveEquipElementType(type)
    const result = this.equipeElementTypes
    if (result.length && typeResp) {
      this.SetEquipElementType(typeResp)
    }
  }

  @Action
  public async GET_EQUIP_ELEMENTS(equipId: number) {
    const elements = await getEquipElements(equipId)
    this.SetEquipElements(elements)
  }

  @Action
  public async ADD_EQUIP_ELEMENT(element: IEquipElement) {
    const elementResp = await addEquipElement(element)
    if (elementResp) {
      this.AddEquipElement(elementResp)
    }
    return elementResp
  }

  @Action
  public async SAVE_EQUIP_ELEMENT(element: IEquipElement) {
    const elementResp = await saveEquipElement(element)
    const result = this.equipeElements
    if (result.length && elementResp) {
      this.SetEquipElement({
        ...element,
        ...elementResp,
        equipe_elementtype: element.equipe_elementtype,
      })
      return elementResp
    }
  }

  @Action
  public async DELETE_EQUIP_ELEMENT(elementId: number) {
    await deleteEquipElement(elementId)
    this.DeleteEquipElement(elementId)
  }

  @Action
  public async GET_EQUIP_AREAS(elementId: number) {
    const areas = await getEquipAreas(elementId)
    this.SetEquipAreas(areas)
  }

  @Action
  public async ADD_EQUIP_AREA(area: IEquipArea) {
    const areaResp = await addEquipArea(area)
    if (areaResp) {
      this.AddEquipArea(areaResp)
      return areaResp
    }
  }

  @Action
  public async SAVE_EQUIP_AREA(area: IEquipArea) {
    const areaResp = await saveEquipArea(area)
    const result = this.equipeAreas
    if (result.length && areaResp) {
      this.SetEquipArea(areaResp)
      return areaResp
    }
  }

  @Action
  public async DELETE_EQUIP_AREA(areaId: number) {
    await deleteEquipArea(areaId)
    this.DeleteEquipArea(areaId)
  }

  @Action
  public async GET_STEEL_GRADES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.steelgrades, this.steelgrades.length)

    if (page !== null) {
      const data = await getSteelGrades(page)
      if (clear) void this.CLEAR_DATA('steelgrades')
      this.SetCountWellbore({ type: 'steelgrades', count: data.count })
      if (data.results) {
        this.SetSteelGrades(data.results)
      }
    }
  }

  @Action
  public async ADD_STEEL_GRADE(steel: ISteelGrade) {
    const steelgrade = await addSteelGrade(steel)
    if (steelgrade) {
      await this.RELOAD_STEEL_GRADES()
      return steelgrade
    }
  }

  @Action
  public async SAVE_STEEL_GRADE(steelgrade: ISteelGrade) {
    const steelgradeResp = await saveSteelGrade(steelgrade)
    const result = this.steelgrades
    if (result.length && steelgradeResp) {
      this.SetSteelGrade(steelgradeResp)
    }
  }

  @Action
  public async GET_WELLBORE_VISUALIZATION(data: {
    wellboreId: number
    equipId: number | null
  }): Promise<Blob | undefined> {
    const result = await getWellboreVisualizaion(data.wellboreId, data.equipId)
    return result
  }

  @Action
  public async GET_LOCK_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.lockTypes, this.lockTypes.length)

    if (page !== null) {
      const data = await getLockTypes(page)
      if (clear) void this.CLEAR_DATA('lockTypes')
      this.SetCountWellbore({ type: 'lockTypes', count: data.count })
      if (data.results) {
        this.SetLockTypes(data.results)
      }
    }
  }

  @Action
  public async GET_CARVING_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.carvingTypes, this.carvingTypes.length)

    if (page !== null) {
      const data = await getCarvingTypes(page)
      if (clear) void this.CLEAR_DATA('carvingTypes')
      this.SetCountWellbore({ type: 'carvingTypes', count: data.count })
      if (data.results) {
        this.SetCarvingTypes(data.results)
      }
    }
  }

  @Action
  public async GET_CUSTOM_CONNECTION_TYPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(
          this.counts.customConnectionTypes,
          this.customConnectionTypes.length
        )

    if (page !== null) {
      const data = await getCustomConnectionTypes(page)
      if (clear) void this.CLEAR_DATA('customConnectionTypes')
      this.SetCountWellbore({
        type: 'customConnectionTypes',
        count: data.count,
      })
      if (data.results) {
        this.SetCustomConnectionTypes(data.results)
      }
    }
  }

  @Action
  public async ADD_PROFILE_SHAPE(name: string) {
    const resp = await addProfileShape(name)
    if (resp) {
      await this.RELOAD_PROFILE_SHAPES()
      return resp
    }
  }

  @Action
  public async ADD_CUSTOM_CONNECTION_TYPE(item: ICustomConnectionType) {
    const typeResp = await addCustomConnectionType(item)
    if (typeResp) {
      await this.RELOAD_CUSTOM_CONNECTION_TYPE()
      return typeResp
    }
  }

  @Action
  public async ADD_CARVING_TYPE(item: ICarvingType) {
    const typeResp = await addCarvingConnectionType(item)
    if (typeResp) {
      await this.RELOAD_CARVING_CONNECTION_TYPE()
      return typeResp
    }
  }

  @Action
  public async ADD_LOCK_TYPE(item: ILockType) {
    const typeResp = await addLockConnectionType(item)
    if (typeResp) {
      await this.RELOAD_LOCK_CONNECTION_TYPE()
      return typeResp
    }
  }

  @Action
  public async SAVE_CUSTOM_CONNECTION_TYPE(item: ICustomConnectionType) {
    const result = await saveCustomConnectionType(item)
    this.SaveCustomConnectionTypes(item)
    return result
  }

  @Action
  public async SAVE_LOCK_TYPE(item: ILockType) {
    const result = await saveLockConnectionType(item)
    this.SaveLockConnectionTypes(item)
    return result
  }

  @Action
  public async SAVE_CARVING_TYPE(item: ICarvingType) {
    const result = await saveCarvingConnectionType(item)
    this.SaveCarvingConnectionTypes(item)
    return result
  }

  @Action
  public async GET_PROFILE_SHAPES(clear?: boolean) {
    const page = clear
      ? 1
      : getPage(this.counts.profileShapes, this.profileShapes.length)

    if (page !== null) {
      const data = await getProfileShapes(page)
      if (clear) void this.CLEAR_DATA('profileShapes')
      this.SetCountWellbore({ type: 'profileShapes', count: data.count })
      if (data.results) {
        this.SetProfileShapes(data.results)
      }
    }
  }

  @Action
  public async GET_STEEL_GRADE_BY_ID(id: number) {
    const result = await getSteelgradeById(id)
    return result
  }

  @Action
  public async GET_LOCK_TYPE_BY_ID(id: number) {
    const result = await getLockTypeById(id)
    return result
  }

  @Action
  public async GET_CARVING_TYPE_BY_ID(id: number) {
    const result = await getCarvingTypeById(id)
    return result
  }

  @Action
  public async GET_CUSTOM_TYPE_BY_ID(id: number) {
    const result = await getCustomTypeById(id)
    return result
  }

  @Action
  public async GET_PROFILE_SHAPE_BY_ID(id: number) {
    const result = await getProfileShapeById(id)
    return result
  }

  @Action
  public async GET_EQUIP_ELEMENT_CONNECTION(elementId: number) {
    const result = await getEquipElementConnection(elementId)
    return result
  }

  @Action
  public async ADD_EQUIP_ELEMENT_CONNECTION(connection: IEquipConnection) {
    const result = await addEquipElementConnection(connection)
    return result
  }

  @Action
  public async SAVE_EQUIP_ELEMENT_CONNECTION(connection: IEquipConnection) {
    const result = await saveEquipElementConnection(connection)
    return result
  }

  @Action
  public async DELETE_EQUIP_ELEMENT_CONNECTION(equipId: number) {
    await deleteEquipElementConnection(equipId)
  }

  @Action
  public async CHECK_STRUCT(data: ICheckStructData) {
    const result = await checkStruct(data)
    return result
  }

  @Action
  public async CHECK_PROFILE(data: IProfileCheckStructData) {
    const result = await checkProfile(data)
    return result
  }

  @Action
  public async CHECK_INTERVAL(data: { id: number; elementId?: number }) {
    const result = await checkInterval(data.id, data.elementId)
    return result
  }

  @Action
  public async CHECK_EQUIP(data: { id: number; elementId?: number }) {
    const result = await checkEquip(data.id, data.elementId)
    return result
  }

  @Action
  public async CHECK_FULL_STRUCT(data: ICheckStructData) {
    const result = await checkFullStruct(data)
    return result
  }

  @Action
  public async ACTIVATE_INTEGRATION(data: IActivationData) {
    const result = await activateIntegration(data)
    return result
  }

  @Action
  public async DEACTIVATE_INTEGRATION(id: number) {
    const result = await deactivateIntegration(id)
    return result
  }

  @Action
  public async GET_WELLBORE_VALIDATION(wellboreId: number) {
    const result = await getWellboreValidateInfo(wellboreId)
    if (result) this.SetValidation(result)
  }

  @Action
  public async GET_WELLBORE_BY_STATUS(data: IWellboreByStatus) {
    const result = await getWellboreByStatus(data)
    return result
  }

  @Action
  public async GET_WELLBORE_ACTIVATION_INFO(wellboreId: string) {
    const result = await getWellboreActivationInfo(wellboreId)
    return result
  }

  @Action
  public async ACTIVATE_TRANSFER_WELLBORE(data: IActivateTransferWellbore) {
    const result = await activateWellboreTransfer(data)
    return result
  }

  @Action
  public async GET_PROFILE_GRAPH(id: number) {
    const result = await getProfileGraph(id)
    return result
  }

  @Action
  public async NORMALIZE_INCLINOMETRY(wellboreId: number) {
    const result = await normalizeInclinometry(wellboreId)
    return result
  }

  @Action
  public async TRANSFER_INCLINOMETRY(data: ITransferInclinometryData) {
    const result = await transferInclinometry(data)
    return result
  }

  @Action
  public async CHECK_INTEGRATION(wellbore: number) {
    const result = await checkIntegration(wellbore)
    return result
  }

  @Action
  public async ARRANGE_INTERVALS(id: number) {
    const result = await arrangeIntervals(id)
    return result
  }

  @Action
  public async ARRANGE_INTERVALS_ELEMENTS(id: number) {
    const result = await arrangeIntervalsElements(id)
    return result
  }

  @Action
  public async ARRANGE_INTERVALS_ELEMENTS_AREAS(id: number) {
    const result = await arrangeIntervalsElementsAreas(id)
    return result
  }

  @Action
  public async ARRANGE_EQUIP_ELEMENTS(id: number) {
    const result = await arrangeEquipElements(id)
    return result
  }

  @Action
  public async ARRANGE_EQUIP_ELEMENTS_AREAS(id: number) {
    const result = await arrangeEquipElementsAreas(id)
    return result
  }

  @Action
  public async MOVE_TO_INTERVALS(data: IMoveToData) {
    const result = await moveToIntervals(data)
    return result
  }

  @Action
  public CLEAR_WELLBORE_STATES() {
    this.ClearWellboreState()
  }
}

export const Wellbore = getModule(WellboreState)

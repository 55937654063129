
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPButton from './WPButton.vue'

@Component({
  components: { WPButton },
})
export default class WPButtonAddList extends Vue {
  @Prop({ default: false })
  private show?: boolean

  @Prop({ default: false })
  private visible?: boolean

  @Prop({ default: true })
  private margin?: boolean
}

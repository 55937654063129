
import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  WPCard,
  WPProgressBar,
  WPButtonMenu,
  WPSelect,
  WPGroup,
  WPGroupMultiline,
  WPCheckbox,
  WPTextfieldMeasure,
  WPTextfield,
  WPExpPanel,
  WPLayoutBorder,
  WPFromTo,
  WPButtonIcon,
  WPBlockBtn,
  WPInfoBlock,
  WPDivider,
  WPBlockHint,
  WPRadioSwitch,
  WPCombobox,
  WPExpCheckbox,
  WPTextInfo,
} from '@/components'

import CalculatorsLayout from './CalculatorsLayout.vue'

import { CalculatorMixin } from '@/mixins'

import {
  ICalcStrengthInputDataForm,
  ICalcStrAddLoads,
  ICalcStrAddTorques,
  IWedgegrip,
} from '@/types'

import { Calculator } from '@/store/calculators'
import { Unit } from '@/store/units'
import { Field } from '@/store/field'
import { Wellbore } from '@/store/wellbore'
import { eventBus } from '@/helpers/eventBus'
import { Init } from '@/store/init'

interface ICalculatorStrKeys {
  external_pressure_uc: number
  rock_density_uc: number
  // channel_area_uc: number
  load_PRI_uc: number
  drilling_mud_density_uc: number
  drilling_fluid_consumption_uc: number
  rotation_frequency_equipmentset_uc: number
  rotation_frequency_ZD_uc: number
  tool_speed_uc: number
  pressure_drop_ZTS_uc: number
  pressure_drop_VZD_uc: number
  pressure_drop_doloto_uc: number
  calculation_step_uc: number
  operation_interval_up_uc: number
  operation_interval_down_uc: number
}

interface IWedgeGripKeys {
  angle_coverage_uc: number
  wedge_length_uc: number
  angle_incline_uc: number
  angle_friction_uc: number
}

interface IComponentKeys {
  strAdditionalFromUc: number
  strAdditionalToUc: number
  strTorquesFromUc: number
  strTorquesToUc: number
}

interface IVerificationKeys {
  hook_tensile_force_uc: number
  rotor_torque_uc: number
}

interface IValidationForms {
  strCondition: boolean
  strKZP: boolean
  strInterval: boolean
  calcStep: boolean
  strWedgegrips?: boolean
  strVerif?: boolean
}

export interface IAdditionalConditions {
  strVerif: boolean
  strWedgegrips: boolean
}

const mockWedgegrip: IWedgegrip = {
  angle_coverage: null,
  angle_coverage_uc: 1201,
  wedge_quantity: null,
  wedge_length: null,
  wedge_length_uc: 100,
  coverage: null,
  magnitude: null,
  angle_incline: null,
  angle_incline_uc: 1201,
  angle_friction: null,
  angle_friction_uc: 1201,
  name: '',
}

@Component({
  components: {
    WPCard,
    WPProgressBar,
    WPButtonMenu,
    WPSelect,
    WPCheckbox,
    WPGroup,
    WPGroupMultiline,
    WPTextfieldMeasure,
    WPTextfield,
    WPExpPanel,
    WPLayoutBorder,
    WPFromTo,
    WPButtonIcon,
    WPBlockBtn,
    WPInfoBlock,
    WPDivider,
    WPBlockHint,
    CalculatorsLayout,
    WPRadioSwitch,
    WPCombobox,
    WPExpCheckbox,
    WPTextInfo,
  },
})
export default class CalculatorsStrength extends Mixins(CalculatorMixin) {
  public readonly calcCode = 2

  private formData: ICalcStrengthInputDataForm = {
    technological_operation: null,
    direction: null,
    resistance_coefficient: null,
    fortress_rocks: null,
    circulation: true,
    external_pressure: null,
    external_pressure_uc: 500,
    rock_density: null,
    rock_density_uc: 700,
    sludge_concentration: null,
    oscillation: false,
    // channel_area: null,
    // channel_area_uc: 200,
    load_PRI: null,
    load_PRI_uc: 600,
    drilling_mud_density: null,
    drilling_mud_density_uc: 700,
    drilling_fluid_consumption: null,
    drilling_fluid_consumption_uc: 1701,
    rotation_frequency_equipmentset: null,
    rotation_frequency_equipmentset_uc: 1301,
    rotation_frequency_ZD: null,
    rotation_frequency_ZD_uc: 1301,
    tool_speed: null,
    tool_speed_uc: 1601,
    pressure_drop_ZTS: null,
    pressure_drop_ZTS_uc: 500,
    pressure_drop_VZD: null,
    pressure_drop_VZD_uc: 500,
    pressure_drop_doloto: null,
    pressure_drop_doloto_uc: 500,
    custom_coefficient: true,
    base: null,
    drilling_way: null,
    profile: null,
    drilling_conditions: null,
    coefficient_reserve_strength: {
      coefficient_reserve_strength: null,
      coefficient_strength_nippele: null,
      coefficient_strength_muff: null,
      coefficient_reserve_airproof: null,
    },
    calculation_step: null,
    calculation_step_uc: 100,
    additional_loads: [],
    additional_torques: [],
    wedge_grip: null,
    azimuth_type_calc_result: 1,
    base_calc: true,
    operation_interval_up: null,
    operation_interval_down: null,
    operation_interval_down_uc: 100,
    operation_interval_up_uc: 100,
    verification: {
      hook_tensile_force: null,
      hook_tensile_force_uc: 604,
      rotor_torque: null,
      rotor_torque_uc: 405,
      need_save: false,
    },
  }

  private wedgeGripForm: IWedgegrip = {
    ...mockWedgegrip,
  }

  private resistanceCoefficientDown: number | null = null

  private resistanceCoefficientUp: number | null = null

  private strAdditionalFrom: number | null = null

  private strAdditionalTo: number | null = null

  private strAdditionalFromUc = 100

  private strAdditionalToUc = 600

  private strTorquesFrom: number | null = null

  private strTorquesTo: number | null = null

  private strTorquesFromUc = 100

  private strTorquesToUc = 400

  private torquesFormValid = false

  private loadsFormValid = false

  private maxValueStep: number | null = null

  private maxValueStepUc: number | null = null

  private projectDepth: number | null = null

  private projectDepthUc = 100

  private maxValueDown = 0

  private maxValueUp = 0

  private timer: number | null = null

  private validations: IValidationForms = {
    strCondition: false,
    strKZP: false,
    strInterval: true,
    calcStep: true,
    strVerif: false,
    strWedgegrips: false,
  }

  private additional: IAdditionalConditions = {
    strVerif: false,
    strWedgegrips: false,
  }

  private selectLoadingWedgegrips = false

  private searchTextWedgegrips: string | null = null

  private directionsSelect = [
    {
      value: 'up',
      text: this.$t('common.up'),
      disabled: false,
    },
    {
      value: 'down',
      text: this.$t('common.down'),
      disabled: false,
    },
  ]

  private get azimuts() {
    return [
      {
        text: this.$t('wellboreProfile.optionM'),
        value: 1,
      },
      {
        text: this.$t('wellboreProfile.optionG'),
        value: 2,
      },
      {
        text: this.$t('wellboreProfile.optionGr'),
        value: 3,
      },
    ]
  }

  private get selectFortRocks() {
    return [
      {
        name: this.$t('calcs.strength.selects.soft'),
        value: 7.8,
      },
      {
        name: this.$t('calcs.strength.selects.medium'),
        value: 6.9,
      },
      {
        name: this.$t('calcs.strength.selects.solid'),
        value: 5.5,
      },
    ]
  }

  private get selectBase() {
    return [
      {
        name: this.$t('calcs.strength.selects.land'),
        value: 'stationary',
      },
      {
        name: this.$t('calcs.strength.selects.float'),
        value: 'floating',
      },
    ]
  }

  private get selectDrillingWay() {
    return [
      {
        name: this.$t('calcs.strength.selects.vzd'),
        value: 'VZD',
      },
      {
        name: this.$t('calcs.strength.selects.vsp'),
        value: 'VSP',
      },
    ]
  }

  private get selectProfile() {
    return [
      {
        name: this.$t('calcs.strength.selects.vertical'),
        value: 'vertical',
      },
      {
        name: this.$t('calcs.strength.selects.directional'),
        value: 'inclined',
      },
    ]
  }

  private get selectDrillingConditions() {
    return [
      {
        name: this.$t('calcs.strength.selects.normal'),
        value: 'normal',
      },
      {
        name: this.$t('calcs.strength.selects.complicated'),
        value: 'complicated',
      },
    ]
  }

  private get stages() {
    return [
      this.$t('calcs.strength.stages.force'),
      this.$t('calcs.strength.stages.load'),
      this.$t('calcs.strength.stages.torque'),
      this.$t('calcs.strength.stages.bending'),
      this.$t('calcs.strength.stages.stress'),
      this.$t('calcs.strength.stages.fatigue'),
      this.$t('calcs.strength.stages.extension'),
      this.$t('calcs.strength.stages.coefs'),
    ]
  }

  private get validKZPSelects() {
    return !!(
      this.formData.base &&
      this.formData.profile &&
      this.formData.drilling_way &&
      this.formData.drilling_conditions
    )
  }

  private get calculatePossibility() {
    const verif = this.additional.strVerif ? this.validations.strVerif : true
    const wedgegrip = this.additional.strWedgegrips
      ? this.validations.strWedgegrips
      : true

    return !!(
      this.validations.strCondition &&
      this.validations.strKZP &&
      this.validations.strInterval &&
      this.validations.calcStep &&
      verif &&
      wedgegrip
    )
  }

  private get wedgegrips() {
    return Calculator.wedgegrips
  }

  private get techOperations() {
    return Calculator.techOperations
  }

  private get initOptions() {
    return Init.settings
  }

  private get queryWedgegrips() {
    return this.searchTextWedgegrips
      ? { name: this.searchTextWedgegrips }
      : undefined
  }

  private get selectedWedgeGrip() {
    let id = Number(this.formData.wedge_grip)

    if (id) {
      const result = this.wedgegrips.find((item) => item.id === id)
      return result ? result : this.wedgeGripForm
    } else {
      return this.wedgeGripForm
    }
  }

  private get isNotEditableWedgegrip(): boolean {
    return (
      typeof this.formData.wedge_grip === 'number' &&
      this.wedgeGripForm.user === null
    )
  }

  private get getTechOperation() {
    return this.formData.technological_operation
      ? this.techOperations.find(
          (item) => item.value === this.formData.technological_operation
        )?.name
      : this.$t('common.notSelected')
  }

  private get isAdditionalResultsShowDisabled() {
    return !(this.additional.strVerif || this.additional.strWedgegrips)
  }

  private deleteAddLoads(index: number) {
    this.formData.additional_loads.splice(index, 1)
  }

  private deleteAddTorques(index: number) {
    this.formData.additional_torques.splice(index, 1)
  }

  private getAddLoadsInfo(item: ICalcStrAddLoads) {
    const loadUc = this.getUnitSymbol(item.depth_additional_load_uc)
    return `${String(this.$t('calcs.strength.info.depth'))}: ${String(
      item.depth_additional_load
    )} ${String(loadUc)}`
  }

  private getAddLoadsSubInfo(item: ICalcStrAddLoads) {
    const depthUc = this.getUnitSymbol(item.additional_load_uc)
    return `${String(this.$t('calcs.strength.info.effort'))}: ${String(
      item.additional_load
    )} ${String(depthUc)}`
  }

  private getAddTorquesInfo(item: ICalcStrAddTorques) {
    const loadUc = this.getUnitSymbol(item.depth_additional_torque_uc)
    return `${String(this.$t('calcs.strength.info.depth'))}: ${String(
      item.depth_additional_torque
    )} ${String(loadUc)}`
  }

  private getAddTorquesSubInfo(item: ICalcStrAddTorques) {
    const depthUc = this.getUnitSymbol(item.additional_torque_uc)
    return `${String(this.$t('calcs.strength.info.torque'))}: ${String(
      item.additional_torque
    )} ${String(depthUc)}`
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private changeMeasure(val: number, key: keyof ICalculatorStrKeys) {
    this.formData[key] = val
  }

  private changeVerifMeasure(val: number, key: keyof IVerificationKeys) {
    if (this.formData.verification) this.formData.verification[key] = val
  }

  private changeWedgegripMeasure(val: number, key: keyof IWedgeGripKeys) {
    this.wedgeGripForm[key] = val
  }

  private changeSingleMeasure(val: number, key: keyof IComponentKeys) {
    this[key] = val
  }

  private changeAdditional(val: boolean, key: keyof IAdditionalConditions) {
    this.additional[key] = val
    if (this.validations[key] === undefined) this.validations[key] = false
  }

  private addLoad() {
    const item: ICalcStrAddLoads = {
      depth_additional_load: this.strAdditionalFrom,
      depth_additional_load_uc: this.strAdditionalFromUc,
      additional_load: this.strAdditionalTo,
      additional_load_uc: this.strAdditionalToUc,
    }
    this.formData.additional_loads.push(item)
    this.strAdditionalFrom = null
    this.strAdditionalFromUc = 100
    this.strAdditionalTo = null
    this.strAdditionalToUc = 600
    this.resetLoadsForm()
  }

  private addTorque() {
    const item: ICalcStrAddTorques = {
      depth_additional_torque: this.strTorquesFrom,
      depth_additional_torque_uc: this.strTorquesFromUc,
      additional_torque: this.strTorquesTo,
      additional_torque_uc: this.strTorquesToUc,
    }
    this.formData.additional_torques.push(item)
    this.strTorquesFrom = null
    this.strTorquesFromUc = 100
    this.strTorquesTo = null
    this.strTorquesToUc = 400
    this.resetTorquesForm()
  }

  private resetLoadsForm() {
    const form = this.$refs.loadsForm as Vue & {
      resetValidation: () => void
    }
    form.resetValidation()
  }

  private resetTorquesForm() {
    const form = this.$refs.torquesForm as Vue & {
      resetValidation: () => void
    }
    form.resetValidation()
  }

  private async checkInterval(isClose = false) {
    const up = await Unit.CONVERSE_UNITS({
      sourceVal: this.formData.operation_interval_up,
      sourceUnit: this.formData.operation_interval_up_uc,
      destUnit: 100,
    })
    const down = await Unit.CONVERSE_UNITS({
      sourceVal: this.formData.operation_interval_down,
      sourceUnit: this.formData.operation_interval_down_uc,
      destUnit: 100,
    })

    if (up && down && Number(up) > Number(down)) {
      if (!isClose) eventBus.$emit('showError', 'calcVolumeIntervalError')
      this.validations.strInterval = false
      if (isClose) this.close()
      return
    }

    this.close()
  }

  private async recalculateMaxLength() {
    const sourceVal = Number(this.maxValueStep)
    const sourceUnit = Number(this.maxValueStepUc)
    const destUnit = Number(this.formData.calculation_step_uc)
    const result = await Unit.CONVERSE_UNITS({
      sourceVal,
      sourceUnit,
      destUnit,
    })
    this.maxValueStep = result !== null ? result : null
    this.maxValueStepUc = this.formData.calculation_step_uc
  }

  private getWedgeGripCombobox(id: number) {
    const result = this.wedgegrips.find((item) => item.id === id)
    return result || this.selectedWedgeGrip
  }

  private clearWedgeGrip() {
    this.formData.wedge_grip = null
    this.wedgeGripForm = {
      angle_coverage: null,
      angle_coverage_uc: 1201,
      wedge_quantity: null,
      wedge_length: null,
      wedge_length_uc: 100,
      coverage: null,
      magnitude: null,
      angle_incline: null,
      angle_incline_uc: 1201,
      angle_friction: null,
      angle_friction_uc: 1201,
      name: '',
    }
    this.validations.strWedgegrips = false
  }

  private onChangeValidForm(val: boolean, item: keyof IValidationForms) {
    this.validations[item] = val
  }

  private onInputWedgeGrip(val: string | IWedgegrip) {
    if (val === null) {
      this.clearWedgeGrip()
    } else if (typeof val === 'string') {
      this.formData.wedge_grip = val
      this.wedgeGripForm.name = val
    } else {
      this.formData.wedge_grip = Number(val.id)
    }
  }

  private async onSaveWedgegrip() {
    if (typeof this.formData.wedge_grip === 'string') {
      const obj = {
        ...this.wedgeGripForm,
        name: this.formData.wedge_grip,
      }
      delete obj.id
      const newWG = await Calculator.CREATE_WEDGE_GRIP(obj)
      if (newWG) {
        this.formData.wedge_grip = Number(newWG.id)
      }
    } else if (
      typeof this.formData.wedge_grip === 'number' &&
      this.wedgeGripForm.user !== null
    ) {
      await Calculator.SAVE_WEDGE_GRIP(this.wedgeGripForm)
    }
    if (typeof this.formData.wedge_grip === 'number') {
      void this.close()
    }
  }

  private async onChangeSelectsKZP() {
    if (this.validKZPSelects) {
      const { base, profile, drilling_conditions, drilling_way } = this.formData
      const res = await Calculator.GET_KZP({
        base,
        drilling_way,
        profile,
        drilling_conditions,
      })
      if (res !== null) {
        this.formData.coefficient_reserve_strength.coefficient_reserve_strength =
          res
        this.formData.coefficient_reserve_strength.coefficient_strength_nippele =
          res
        this.formData.coefficient_reserve_strength.coefficient_strength_muff =
          res
        this.formData.coefficient_reserve_strength.coefficient_reserve_airproof =
          res
      }
    }
  }

  private async onSearchWedgegrips(search = '') {
    this.searchTextWedgegrips = search
    this.selectLoadingWedgegrips = true
    void Calculator.CLEAR_DATA('wedgegrips')
    await this.onLoadWedgegrips()
    this.selectLoadingWedgegrips = false
  }

  private async onLoadWedgegrips() {
    await Calculator.GET_WEDGE_GRIPS(this.queryWedgegrips)
  }

  @Watch('formData.operation_interval_down_uc')
  private async onChangeDownUc(val: number) {
    const depth = await Unit.CONVERSE_UNITS({
      sourceVal: this.projectDepth,
      sourceUnit: this.projectDepthUc,
      destUnit: val,
    })
    this.maxValueDown = depth !== null ? depth : 0
  }

  @Watch('formData.operation_interval_up_uc')
  private async onChangeUpUc(val: number) {
    const depth = await Unit.CONVERSE_UNITS({
      sourceVal: this.projectDepth,
      sourceUnit: this.projectDepthUc,
      destUnit: val,
    })
    this.maxValueUp = depth !== null ? depth : 0
  }

  @Watch('resistanceCoefficientDown')
  private onChangeResCoefDown(val: number) {
    if (this.formData.direction === 'down')
      this.formData.resistance_coefficient = val
  }

  @Watch('resistanceCoefficientUp')
  private onChangeResCoefUp(val: number) {
    if (this.formData.direction === 'up')
      this.formData.resistance_coefficient = val
  }

  @Watch('formData.direction')
  private onChangeDirection(val: string) {
    if (val === 'down')
      this.formData.resistance_coefficient = this.resistanceCoefficientDown

    if (val === 'up')
      this.formData.resistance_coefficient = this.resistanceCoefficientUp
  }

  @Watch('formData.technological_operation')
  private onChangeTechOperation(val: number) {
    const selectedTechOp = this.techOperations.find(
      (item) => item.value === val
    )

    if (selectedTechOp) {
      const bothDir = selectedTechOp.up && selectedTechOp.down
      const onlyUp = selectedTechOp.up && !selectedTechOp.down
      const onlyDown = !selectedTechOp.up && selectedTechOp.down
      if (bothDir) {
        this.directionsSelect = [
          {
            value: 'up',
            text: this.$t('common.up'),
            disabled: false,
          },
          {
            value: 'down',
            text: this.$t('common.down'),
            disabled: false,
          },
        ]
        if (!Calculator.inputData) this.formData.direction = 'down'
      } else if (onlyUp || onlyDown) {
        this.directionsSelect = [
          {
            value: 'up',
            text: this.$t('common.up'),
            disabled: true,
          },
          {
            value: 'down',
            text: this.$t('common.down'),
            disabled: true,
          },
        ]
        this.formData.direction = onlyUp ? 'up' : 'down'
      }
    }
  }

  @Watch('formData.wedge_grip')
  private onChangeWedgegrip(val: number | string | null) {
    if (val === null) {
      this.formData.wedge_grip = null
      this.wedgeGripForm = {
        angle_coverage: null,
        angle_coverage_uc: 1201,
        wedge_quantity: null,
        wedge_length: null,
        wedge_length_uc: 100,
        coverage: null,
        magnitude: null,
        angle_incline: null,
        angle_incline_uc: 1201,
        angle_friction: null,
        angle_friction_uc: 1201,
        name: '',
      }
      return
    }

    if (typeof val === 'number') {
      const item = Calculator.wedgegrips.find((item) => item.id === val)

      if (item) {
        this.wedgeGripForm = { ...item }
      }
      return
    }

    if (typeof val === 'string') {
      this.wedgeGripForm.name = val
    }
  }

  @Watch('formData.custom_coefficient')
  private async onChangeCustomKZP(val: boolean) {
    if (this.formData.coefficient_reserve_strength && val === false) {
      this.formData.coefficient_reserve_strength.coefficient_reserve_strength =
        null
      this.formData.coefficient_reserve_strength.coefficient_strength_nippele =
        null
      this.formData.coefficient_reserve_strength.coefficient_strength_muff =
        null
      this.formData.coefficient_reserve_strength.coefficient_reserve_airproof =
        null
      await this.onChangeSelectsKZP()
    }
  }

  @Watch('formData.base')
  private async onChangeSelectsKZPWatcherBase() {
    if (this.formData.custom_coefficient === false)
      await this.onChangeSelectsKZP()
  }

  @Watch('formData.profile')
  private async onChangeSelectsKZPWatcherProfile() {
    if (this.formData.custom_coefficient === false)
      await this.onChangeSelectsKZP()
  }

  @Watch('formData.drilling_way')
  private async onChangeSelectsKZPWatcherDrWay() {
    if (this.formData.custom_coefficient === false)
      await this.onChangeSelectsKZP()
  }

  @Watch('formData.drilling_conditions')
  private async onChangeSelectsKZPWatcherDrCond() {
    if (this.formData.custom_coefficient === false)
      await this.onChangeSelectsKZP()
  }

  @Watch('formData.calculation_step_uc')
  private async onChangeRepeatLengthUc(val: number) {
    await this.recalculateMaxLength()
  }

  @Watch('isAdditionalResultsShowDisabled')
  private onChangeAdditionalResultsDisabled(val: boolean) {
    if (val) this.formData.base_calc = true
  }

  @Watch('formData.circulation')
  private onChangeCirculation(val: boolean) {
    if (!val) {
      this.formData.drilling_fluid_consumption = 0
      this.formData.pressure_drop_ZTS = 0
      this.formData.pressure_drop_VZD = 0
      this.formData.pressure_drop_doloto = 0
    } else {
      this.formData.drilling_fluid_consumption = null
      this.formData.pressure_drop_ZTS = null
      this.formData.pressure_drop_VZD = null
      this.formData.pressure_drop_doloto = null
    }
  }

  private async mounted() {
    await Calculator.GET_WEDGE_GRIPS()
    await Calculator.GET_TECH_OPERATIONS()
    if (Calculator.inputData) {
      let result = Calculator.inputData as ICalcStrengthInputDataForm
      if (!result.wedge_grip) {
        result = {
          ...result,
          wedge_grip: null,
        }
      } else {
        this.additional.strWedgegrips = true
        this.validations.strWedgegrips = true
      }
      if (!result.verification) {
        result.verification = {
          hook_tensile_force: null,
          hook_tensile_force_uc: 604,
          rotor_torque: null,
          rotor_torque_uc: 405,
          need_save: false,
        }
      } else {
        this.additional.strVerif = true
        this.validations.strVerif = true
      }

      this.validations.strCondition = true
      this.validations.strKZP = true
      this.validations.calcStep = true

      this.formData = { ...this.formData, ...result }
    }

    this.timer = setTimeout(() => Calculator.CLEAR_INPUT_DATA(), 0)

    const wellbore = await Field.GET_WELLBORE(Number(this.params.wellbore))
    const equip = await Wellbore.GET_EQUIP(Number(this.params.equip))
    if (wellbore) {
      this.maxValueStep = wellbore.project_depth
      this.maxValueStepUc = wellbore.project_depth_uc
      this.projectDepth = wellbore.project_depth
      this.projectDepthUc = wellbore.project_depth_uc
      this.maxValueUp = wellbore.project_depth
      this.maxValueDown = wellbore.project_depth
      this.resistanceCoefficientDown = wellbore.resistance_coefficient_down
      this.resistanceCoefficientUp = wellbore.resistance_coefficient_up

      if (equip) {
        this.formData.operation_interval_up = equip.up_work || 0
        this.formData.operation_interval_up_uc = equip.up_work_uc || 100
        this.formData.operation_interval_down_uc = equip.down_work_uc || 0
        this.formData.operation_interval_down = equip.down_work || 100
      }
    }
  }

  private beforeDestroy() {
    if (this.timer !== null) clearTimeout(this.timer)
  }
}

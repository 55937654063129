
import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  WPCard,
  WPButton,
  WPOpacity,
  WPEmptyList,
  WPTextfield,
  WPTextfieldMeasure,
  WPGroup,
  WPBlockBtn,
  WPInfoBlock,
  WPLoader,
  WPLayoutBorder,
  WPBlockHint,
  WPDivider,
  WPSelect,
  WPSwitch,
  WPCheckbox,
  WPGroupMultiline,
} from '@/components'
import TheWellboreEquipElements from './TheWellboreEquipElements.vue'

import { RouteParamsMixin } from '@/mixins'

import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'
import { TEquips, IEquip, TEquipElements } from '@/types'
import { eventBus } from '@/helpers/eventBus'
import { Init } from '@/store/init'

export interface IEquipUnitKeys {
  up_work_uc: number
  down_work_uc: number
  length_uc: number
}

const mockEquip: IEquip = {
  name: null,
  description: null,
  up_work: 0,
  up_work_uc: 100,
  down_work: null,
  down_work_uc: 100,
  for_drilling: false,
  length: null,
  length_uc: 100,
  automatic_length_update: false,
}

@Component({
  components: {
    WPCard,
    WPButton,
    WPOpacity,
    WPLoader,
    WPEmptyList,
    WPTextfield,
    WPTextfieldMeasure,
    WPGroup,
    WPBlockBtn,
    WPInfoBlock,
    WPLayoutBorder,
    TheWellboreEquipElements,
    WPDivider,
    WPBlockHint,
    WPSelect,
    WPSwitch,
    WPCheckbox,
    WPGroupMultiline,
  },
})
export default class TheWellboreEquip extends Mixins(RouteParamsMixin) {
  private readonly emptyTitle = this.$t('equips.empty')

  private maxDepth = 0

  private isEditDialog = false

  private editId: number | null = null

  private valid = false

  private busy = false

  private busyEquip = false

  private isElementEditing = false

  private equip: IEquip = {
    name: null,
    description: null,
    up_work: 0,
    up_work_uc: 100,
    down_work: null,
    down_work_uc: 100,
    interval_number_for_use: null,
    for_drilling: false,
    length: null,
    length_uc: 100,
    automatic_length_update: false,
  }

  private get equips(): TEquips {
    return Wellbore.equips
  }

  private get equipElements(): TEquipElements {
    return Wellbore.equipeElements
  }

  private get initOptions() {
    return Init.settings
  }

  private get equipsFullLoaded() {
    return Wellbore.counts.equips === Wellbore.equips.length
  }

  private get isShowAutoBorderUpdate() {
    return (
      this.initOptions.enable_wellbore_integration &&
      this.wellbore?.activate_integration &&
      Number(this.wellbore?.status) === 3
    )
  }

  private changeValid(val: boolean) {
    this.valid = val
  }

  private changeMeasure(val: number, key: keyof IEquipUnitKeys) {
    this.equip[key] = val
  }

  private getMeasure(key: keyof IEquipUnitKeys) {
    return Unit.units.find((item) => item.code === this.equip[key])?.symbol
  }

  private getBlockInfo(item: IEquip) {
    const unitLength = String(this.getUnitSymbol(item.up_work_uc))
    const unitLengthEnd = String(this.getUnitSymbol(item.down_work_uc))
    return `${String(this.$t('equips.depth'))}: ${String(
      item.up_work
    )} ${unitLength} – ${String(item.down_work)} ${unitLengthEnd}`
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private cancel() {
    if (this.isEditDialog) {
      this.cancelEdit()
    } else {
      void this.$router.push({
        name: 'TheWellbore',
        params: {
          id: String(this.fieldId),
          wellId: String(this.wellId),
          wellboreId: String(this.wellboreId),
        },
      })
    }
  }

  private cancelEdit() {
    this.editId = null
    this.equip = { ...mockEquip }
    this.isEditDialog = false
    if (Object.keys(this.$route.query).length)
      void this.$router.replace({ query: {} })
  }

  private async arrangeElements(elementId: number) {
    if (!this.busyEquip) {
      this.busyEquip = true
      const result = await Wellbore.ARRANGE_EQUIP_ELEMENTS(elementId)
      if (result) await Wellbore.RELOAD_EQUIP_ELEMENTS(elementId)
      this.busyEquip = false
    }
  }

  private openEditDialog() {
    this.isEditDialog = true
  }

  private async addEquip() {
    this.busy = true
    let equip: IEquip = {
      ...this.equip,
      interval_number_for_use: this.equip.interval_number_for_use
        ? Number(this.equip.interval_number_for_use)
        : null,
      wellbore: Number(this.wellboreId),
    }

    if (this.editId) {
      await Wellbore.SAVE_EQUIP(equip)
    } else {
      const item = await Wellbore.ADD_EQUIP({
        equip,
        wellboreId: Number(this.wellboreId),
      })
      if (item) {
        this.editPoint(item)
      } else {
        this.isEditDialog = false
        this.equip = { ...mockEquip }
      }
    }
    this.busy = false
  }

  private editPoint(item: IEquip) {
    this.isEditDialog = true
    this.equip = { ...item }
    this.editId = Number(item.id)
  }

  private async recalculateDepthLimit() {
    const currentDepth = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.wellbore?.current_depth),
      sourceUnit: Number(this.wellbore?.current_depth_uc),
      destUnit: this.equip.down_work_uc,
    })
    this.maxDepth = currentDepth !== null ? currentDepth : 0
  }

  private onChangeEditing(value: boolean) {
    this.isElementEditing = value
  }

  private async onLoadEquips() {
    if (!this.busy && !this.equipsFullLoaded) {
      this.busy = true
      await Wellbore.GET_EQUIPS({ wellboreId: Number(this.wellboreId) })
      this.busy = false
    }
  }

  @Watch('equips')
  private onEndLoadEquips(arr: TEquips) {
    const id = Number(this.$route.query.id)
    const item = arr.find((item) => Number(item.id) === id)
    if (item) this.editPoint(item)
  }

  @Watch('equip.down_work_uc')
  private async onChangeDownWorkUc(val: number) {
    this.equip.up_work_uc = val
    await this.recalculateDepthLimit()
  }

  @Watch('isEditDialog')
  private onChangeEditDialogValue(val: boolean) {
    eventBus.$emit('disableWellboreTypes', val)
  }

  private async onDelete(id: number) {
    this.busy = true
    await Wellbore.DELETE_EQUIP({
      equipId: id,
      wellboreId: Number(this.wellboreId),
    })
    this.busy = false
  }

  private async onClone(id: number) {
    await Wellbore.CLONE_EQUIP({
      equipId: id,
      wellboreId: Number(this.wellboreId),
    })
  }

  private async mounted() {
    await this.recalculateDepthLimit()
  }
}

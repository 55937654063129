import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'
import {
  ILoginData,
  IResetPassword,
  IResetPasswordConfirm,
  IRegisterData,
  IResetPasswordNew,
} from '@/types'
import {
  login,
  register,
  resetPassword,
  resetPasswordConfirm,
  resetPasswordNew,
} from '@/services/auth'
import { clearAllStates } from '@/services/common'
import { clearAuthTokens, isLoggedIn, setAuthTokens } from 'axios-jwt'

interface IUser {
  username: string
}

export interface IAuthState {
  isAuthentication: boolean
  user: IUser | null
}

@Module({ dynamic: true, store, name: 'auth' })
class AuthState extends VuexModule implements IAuthState {
  public isAuthentication = false

  public user = null

  @Mutation
  public Login() {
    this.isAuthentication = true
  }

  @Mutation
  public Logout() {
    this.isAuthentication = false
  }

  @Action
  public async LOGIN(loginData: ILoginData) {
    const response = await login(loginData)

    if (response?.access && response?.refresh) {
      void setAuthTokens({
        accessToken: response.access,
        refreshToken: response.refresh,
      })

      this.Login()
      return true
    }

    return false
  }

  @Action
  public async REGISTER(regData: IRegisterData) {
    const response = await register(regData)

    if (response?.id) {
      const responseLogin = await login(regData)

      if (responseLogin?.access && responseLogin?.refresh) {
        void setAuthTokens({
          accessToken: responseLogin.access,
          refreshToken: responseLogin.refresh,
        })

        this.Login()
        return response
      }
    }

    return false
  }

  @Action
  public async UPDATE_AUTHENTICATION() {
    const isLogged = await isLoggedIn()
    if (isLogged) this.Login()
  }

  @Action
  public LOGOUT() {
    void clearAuthTokens()
    this.Logout()
    void clearAllStates()
  }

  @Action
  public async RESET_PASSWORD(data: IResetPassword) {
    const result = await resetPassword(data)
    return result
  }

  @Action
  public async RESET_PASSWORD_CONFIRM(data: IResetPasswordConfirm) {
    const result = await resetPasswordConfirm(data)
    return result
  }

  @Action
  public async RESET_PASSWORD_NEW(data: IResetPasswordNew) {
    const result = await resetPasswordNew(data)
    return result
  }
}

export const Auth = getModule(AuthState)


import { Component, Vue } from 'vue-property-decorator'
import {
  WPTextfield,
  WPTextfieldPassword,
  WPButton,
  WPCard,
} from '@/components'

import { Auth } from '@/store/auth'
import { eventBus } from '@/helpers/eventBus'
import { User } from '@/store/user'

@Component({
  components: {
    WPTextfield,
    WPButton,
    WPTextfieldPassword,
    WPCard,
  },
})
export default class PasswordNew extends Vue {
  private passwordConfirm = ''

  private password = ''

  private passwordOld = ''

  private loading = false

  private get username() {
    return User.user.username
  }

  private get valid() {
    return (
      this.passwordOld &&
      this.password &&
      this.passwordConfirm &&
      this.password === this.passwordConfirm
    )
  }

  private resetForm() {
    this.passwordConfirm = ''
    this.password = ''
    this.passwordOld = ''
    const form = this.$refs.passNewForm as Vue & { resetValidation: () => void }
    if (form) form.resetValidation()
  }

  private async send() {
    this.loading = true
    const result = await Auth.RESET_PASSWORD_NEW({
      new_password: this.password,
      current_password: this.passwordOld,
    })
    if (result !== null) {
      this.back()
    }
    this.loading = false
  }

  private back() {
    void this.resetForm()
    void eventBus.$emit('closePassword')
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { Field } from '@/store/field'
import { Unit } from '@/store/units'
import {
  WPTitle,
  WPOpacity,
  WPList,
  WPCard,
  WPTextfield,
  WPDivider,
} from '@/components'

@Component({
  components: { WPDivider, WPTextfield, WPCard, WPList, WPOpacity, WPTitle },
})
export default class Fields extends Vue {
  private isAddDialog = false

  private fieldTitle = ''

  private fieldId = 0

  private isEdit = false

  private isShownFieldList = false

  private loading = false

  private busy = false

  private search = ''

  private cardHeight = 200

  private get items() {
    return Field.fields
  }

  private get emptyCount() {
    return Field.counts.fields === 0
  }

  private get query() {
    return this.search ? { name: this.search } : undefined
  }

  private openAddDialog() {
    this.fieldTitle = ''
    this.isAddDialog = true
  }

  private async addField() {
    this.loading = true
    if (this.isEdit) {
      await Field.SAVE_FIELD({ id: this.fieldId, name: this.fieldTitle })
    } else {
      await Field.ADD_FIELD({ name: this.fieldTitle })
    }
    this.cancel()
    if (this.search) {
      void Field.CLEAR_DATA('fields')
      await this.onSearch(this.search, true)
    }
    this.loading = false
  }

  private cancel() {
    this.isAddDialog = false
    this.isEdit = false
  }

  private async onLoad() {
    if (!this.busy) {
      this.busy = true
      await Field.GET_FIELDS(this.query)
      this.busy = false
    }
  }

  private async onSearch(val: string, force?: boolean) {
    if (this.search !== val || force) {
      this.search = val
      await this.onLoad()
    }
  }

  private onClickItem(id: string) {
    void Field.CLEAR_DATA('wells')
    void Field.CLEAR_DATA('clusters')
    void this.$router.push({ name: 'TheField', params: { id: id } })
  }

  private async onDeleteItem(id: number) {
    await Field.DELETE_FIELD(id)
    if (this.search) {
      void Field.CLEAR_DATA('fields')
      await this.onSearch(this.search, true)
    }
  }

  private onEditItem(id: number) {
    const field = this.items.find((item) => item.id === id)
    if (field) {
      this.fieldTitle = field.name
      this.fieldId = id
      this.isEdit = true
      this.isAddDialog = true
    }
  }

  private async mounted() {
    void Field.CLEAR_DATA('fields')
    await this.onLoad()
    if (!Unit.units.length) await Unit.GET_UNITS()
    if (!Unit.unitTypes.length) await Unit.GET_UNITTYPES()
    this.isShownFieldList = true

    this.$nextTick(() => {
      const card: HTMLElement | null =
        document?.querySelector('.wp-fields__list')
      this.cardHeight = card ? card.offsetHeight - 200 : 200
    })
  }

  private beforeDestroy() {
    if (this.search) void Field.CLEAR_DATA('fields')
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { Auth } from '@/store/auth'

@Component({
  name: 'WPLogo',
})
export default class WPLogo extends Vue {
  @Prop({ default: '' })
  private readonly height?: string

  @Prop({ default: false })
  private readonly fullWidth?: boolean

  @Prop({ default: false })
  private readonly black?: boolean

  private get authorized() {
    return Auth.isAuthentication
  }

  private goToMain() {
    const name = this.authorized ? 'Fields' : 'Login'
    if (this.$route.name !== name) void this.$router.push({ name: name })
  }
}

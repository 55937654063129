var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VThemeProvider',{attrs:{"root":""}},[_c('VApp',{class:{
      'wp-app_green': _vm.getTheme === 'green',
      'wp-app_blue': _vm.getTheme === 'blue',
      'wp-app_orange': _vm.getTheme === 'orange',
      'wp-app_black': _vm.getTheme === 'black',
    }},[(_vm.authorized)?_c('WPHeader',{on:{"logout":_vm.logout}}):_c('WPLocaleSwitcher'),_c('VMain',[_c('div',{staticClass:"wp-content",class:{
          'wp-layout-content': _vm.isShowBreadcrumbs,
          'wp-layout-content-solo': !_vm.isShowBreadcrumbs,
        }},[_c('WPBreadcrumbs',{attrs:{"field":_vm.selectedFieldName,"well":_vm.selectedWellName,"wellbore":_vm.selectedWellboreName,"wellbore-type":_vm.selectedWellboreTypeName,"calculator":_vm.selectedCalculator,"calculator-page":_vm.isCalculatorsPage,"calculator-results":_vm.isCalculatorsResultsPage},on:{"toField":_vm.toField,"toWell":_vm.toWell,"toWellbore":_vm.toWellbore}}),_c('RouterView'),_c('WPAlert',{attrs:{"visible":_vm.alert.visible,"type":_vm.alert.type,"status":_vm.alert.status},on:{"dismiss":function($event){_vm.alert.visible = false}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.alert.text)}})]),_c('WPDialog',{attrs:{"value":_vm.dialog.visible,"title":_vm.dialog.title,"text":_vm.dialog.text,"btn-dismiss-text":_vm.dialog.btnDismissText,"btn-accept-text":_vm.dialog.btnAcceptText},on:{"input":_vm.changeDialogVisible}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
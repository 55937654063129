import axiosInstance from '@/services/api'
import {
  ISettings,
  ISupportRequest,
  TSupportTypes,
  IUserData,
  ISupportRequestLang,
} from '@/types'

import { eventBus, getError } from '@/helpers/eventBus'

const DEFAULT_SETTINGS: ISettings = {
  theme: 'blue',
  language: 'ru-ru',
  measure_type: 'metric',
  round_digits: 2,
}

const DEFAULT_USER: IUserData = {
  id: 0,
  email: '',
  username: '',
}

export async function getSettings(
  withoutNotification = false
): Promise<ISettings> {
  try {
    const response = await axiosInstance.get<ISettings>('/accounts/settings/')
    if (response?.data) {
      return response.data
    }
    return DEFAULT_SETTINGS
  } catch (error: unknown) {
    if (!withoutNotification) eventBus.$emit('showError', getError(error))
    return DEFAULT_SETTINGS
  }
}

export async function saveSettings(settings: ISettings) {
  try {
    const response = await axiosInstance.patch<ISettings>(
      '/accounts/settings/',
      settings
    )
    if (response?.data) {
      eventBus.$emit('showAlert', 'profileSaved')
      return response.data
    }

    return DEFAULT_SETTINGS
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DEFAULT_SETTINGS
  }
}

export async function getSupportTypes(lang?: string): Promise<TSupportTypes> {
  try {
    const headers = {
      'Accept-Language': lang === 'en-us' ? 'en,ru;q=0.9' : 'ru,en;q=0.9',
    }
    const response = await axiosInstance.get<TSupportTypes>(
      '/accounts/support/types/',
      {
        headers: headers,
      }
    )
    if (response?.data) {
      return response.data
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function sendSupportRequest(data: ISupportRequestLang) {
  try {
    const headers = data.lang
      ? {
          'Accept-Language':
            data.lang === 'en-us' ? 'en,ru;q=0.9' : 'ru,en;q=0.9',
        }
      : {}
    const req: ISupportRequest = {
      type: data.type,
      email: data.email,
      message: data.message,
    }
    const response = await axiosInstance.post<ISupportRequest>(
      '/accounts/support/',
      req,
      {
        headers: headers,
      }
    )
    if (response?.data) {
      eventBus.$emit('showAlert', 'support')
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getUserInfo(
  withoutNotification = false
): Promise<IUserData> {
  try {
    const response = await axiosInstance.get<IUserData>('/accounts/users/me/')
    if (response?.data) {
      return response.data
    }

    return DEFAULT_USER
  } catch (error: unknown) {
    if (!withoutNotification) eventBus.$emit('showError', getError(error))
    return DEFAULT_USER
  }
}


import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  WPCard,
  WPButton,
  WPButtonIcon,
  WPTextfield,
  WPTextfieldMeasure,
  WPRadioGroup,
  WPBlockBtn,
  WPDivider,
  WPInfoBlock,
  WPOpacity,
  WPEmptyList,
  WPGroup,
  WPSwitch,
  WPLayoutBorder,
  WPGroupMultiline,
  WPCheckbox,
  WPBlockHint,
  WPExpPanel,
  WPCombobox,
  WPLoader,
  WPButtonAddList,
  WPDroppableArea,
} from '@/components'
import TheWellboreIntervalElements from './TheWellboreIntervalElements.vue'

import { RouteParamsMixin } from '@/mixins'
import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'

import {
  IInterval,
  TIntervalTypes,
  TIntervals,
  IIntervalType,
  TPipeTypes,
  TElements,
} from '@/types'

import { eventBus } from '@/helpers/eventBus'
import { init } from 'echarts/core'

export interface IIntervalUnitKeys {
  up_interval_uc: number
  dolotos_diameter_uc: number
  down_interval_uc: number
  up_cementing_uc: number
  down_cementing_uc: number
  up_pipe_uc: number
  down_pipe_uc: number
}

const mockInterval: IInterval = {
  intervaltype: null,
  up_interval: 0,
  up_interval_uc: 100,
  down_interval: null,
  down_interval_uc: 100,
  dolotos_diameter: null,
  dolotos_diameter_uc: 100,
  cavernosity: 1,
  cased: false,
  wellbore: 0,
  wall_friction_up: 0.3,
  wall_friction_down: 0.3,
  automatic_border_update: false,
  number: null,
}

@Component({
  components: {
    WPCard,
    WPButtonIcon,
    WPButton,
    WPTextfield,
    WPTextfieldMeasure,
    WPRadioGroup,
    WPBlockBtn,
    WPDivider,
    WPInfoBlock,
    WPOpacity,
    WPEmptyList,
    WPGroup,
    WPSwitch,
    WPLayoutBorder,
    WPGroupMultiline,
    WPCheckbox,
    TheWellboreIntervalElements,
    WPBlockHint,
    WPExpPanel,
    WPLoader,
    WPCombobox,
    WPButtonAddList,
    WPDroppableArea,
  },
})
export default class TheWellboreIntervals extends Mixins(RouteParamsMixin) {
  private isEditDialog = false

  private editId: number | null = null

  private upIntervalMaxValue = 0

  private downIntervalMaxValue = 0

  private downPipeMaxValue = 0

  private upPipeMaxValue = 0

  private upTopCementingMaxValue = 0

  private upBotCementingMaxValue = 0

  private downTopCementingMaxValue = 0

  private downBotCementingMaxValue = 0

  private valid = false

  private busy = false

  private busyInterval = false

  private isParamsIntervalOpened = true

  private maxLengthElements = 0

  private isShowAddBtnList = false

  private isShowAddBtnListId = 0

  private isElementEditing = false

  private isDragOver: number | null = null

  private ddNamespace = 'intervals'

  private interval: IInterval = {
    intervaltype: null,
    up_interval: 0,
    up_interval_uc: 100,
    down_interval: null,
    down_interval_uc: 100,
    dolotos_diameter: null,
    dolotos_diameter_uc: 100,
    cavernosity: 1,
    cased: false,
    wellbore: 0,
    number: 1,
    wall_friction_up: 0.3,
    wall_friction_down: 0.3,
    automatic_border_update: false,
  }

  private listIntervals: TIntervals = []

  private get intervalsFullLoaded() {
    return Wellbore.counts.intervals === Wellbore.intervals.length
  }

  private get emptyTitle() {
    return this.$t('intervals.empty')
  }

  private get intervalTypes(): TIntervalTypes {
    return Wellbore.intervalTypes
  }

  private get elements(): TElements {
    return Wellbore.elements
  }

  private get intervals(): TIntervals {
    return Wellbore.intervals
  }

  private get pipeTypes(): TPipeTypes {
    return Wellbore.pipeTypes
  }

  private get isShowAutoBorderUpdate() {
    return (
      this.wellbore?.activate_integration && Number(this.wellbore?.status) === 3
    )
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private getBlockInfo(
    text: string,
    value: number | string,
    code?: number,
    secValue?: number | string,
    secCode?: number
  ) {
    const unit = code ? this.getUnitSymbol(code) : ''
    const secUnit = secCode ? this.getUnitSymbol(secCode) : ''
    let result = `${text} ${String(value)}`
    if (secValue && secCode) {
      result = `${text}. ${String(this.$t('intervals.from'))} ${String(
        value
      )} ${String(unit)} ${String(this.$t('intervals.to'))} ${String(
        secValue
      )} ${String(secUnit)}`
      return result
    }
    if (code) result = result + ` ${String(unit)}`
    return result
  }

  private getBlockFooterInfo(item: IInterval) {
    if (item.cased) {
      const upPipeUnit = this.getUnitSymbol(Number(item.up_pipe_uc))
      const downPipeUnit = this.getUnitSymbol(Number(item.down_pipe_uc))
      const upDown = {
        text: `${String(this.$t('intervals.pipe'))}:`,
        value: `${String(item.up_pipe)} ${String(upPipeUnit)} – ${String(
          item.down_pipe
        )} ${String(downPipeUnit)}`,
      }

      const upCemUnit = this.getUnitSymbol(Number(item.up_cementing_uc))
      const downCemUnit = this.getUnitSymbol(Number(item.down_cementing_uc))
      const cementing = {
        text: `${String(this.$t('intervals.intervalCementing'))}:`,
        value: item.cementing
          ? `${String(item.up_cementing)} ${String(upCemUnit)} – ${String(
              item.down_cementing
            )} ${String(downCemUnit)}`
          : this.$t('intervals.notCementing'),
      }
      const count = {
        text: `${String(this.$t('intervals.count'))}:`,
        value: item.elements_count ? String(item.elements_count) : 0,
      }

      return [upDown, cementing, count]
    } else return []
  }

  private toggleAddBtnList(val: boolean, id: number) {
    this.isShowAddBtnList = val
    this.isShowAddBtnListId = id
  }

  private showAddBtnList(id: number, index: number) {
    const currentBtn = this.isShowAddBtnList && this.isShowAddBtnListId === id
    const isNotLast = index !== this.listIntervals.length - 1
    let isHasEmptyNumber = false
    if (isNotLast) {
      const numCurr = this.listIntervals[index].number || 0
      const numNext = this.listIntervals[index + 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return currentBtn && isHasEmptyNumber
    } else return false
  }

  private visibleAddBtnList(index: number, isOne = false) {
    if (this.listIntervals.length) {
      const isNotLast = index !== this.listIntervals.length - 1
      let isHasEmptyNumber = false
      if (isNotLast) {
        const numCurr = this.listIntervals[index].number || 0
        const numNext = this.listIntervals[index + 1].number || 0
        isHasEmptyNumber = isOne
          ? numNext - numCurr === 2
          : numNext - numCurr > 1
        return isHasEmptyNumber
      } else return false
    } else return false
  }

  private visibleAddBtnListPrev(index: number, isOne = false) {
    if (this.listIntervals.length) {
      const isNotFirst = index !== 0
      let isHasEmptyNumber = false
      if (isNotFirst) {
        const numPrev = this.listIntervals[index - 1].number || 0
        const numCurr = this.listIntervals[index].number || 0
        isHasEmptyNumber = isOne
          ? numCurr - numPrev === 2
          : numCurr - numPrev > 1
        return isHasEmptyNumber
      } else {
        const num = this.listIntervals[0].number
        return num !== 1
      }
    } else return false
  }

  private changeMeasure(val: number, key: keyof IIntervalUnitKeys) {
    this.interval[key] = val
  }

  private getMeasure(key: keyof IIntervalUnitKeys) {
    return Unit.units.find((item) => item.code === this.interval[key])?.symbol
  }

  private getIntervalTypeTitle(
    intType: number | IIntervalType | string | null
  ) {
    if (typeof intType !== 'string' && intType !== null) {
      return typeof intType === 'number'
        ? this.intervalTypes.find((item) => item.id === intType)?.name || ''
        : intType.name
    } else return ''
  }

  private async addInterval() {
    this.busy = true
    let interval: IInterval = {
      ...this.interval,
      wellbore: Number(this.wellboreId),
    }

    if (interval.up_interval && interval.down_interval) {
      const upInt = await Unit.CONVERSE_UNITS({
        sourceVal: interval.up_interval,
        sourceUnit: Number(interval.up_interval_uc),
        destUnit: 100,
      })
      const downInt = await Unit.CONVERSE_UNITS({
        sourceVal: interval.down_interval,
        sourceUnit: Number(interval.down_interval_uc),
        destUnit: 100,
      })
      if (upInt && downInt && upInt > downInt) {
        eventBus.$emit('showError', 'lengthIntervalError')
        this.interval.down_interval = null
        this.busy = false
        return
      }
    }

    if (interval.up_pipe && interval.down_pipe) {
      const upPipe = await Unit.CONVERSE_UNITS({
        sourceVal: interval.up_pipe,
        sourceUnit: Number(interval.up_pipe_uc),
        destUnit: 100,
      })
      const downPipe = await Unit.CONVERSE_UNITS({
        sourceVal: interval.down_pipe,
        sourceUnit: Number(interval.down_pipe_uc),
        destUnit: 100,
      })
      if (upPipe && downPipe && upPipe > downPipe) {
        eventBus.$emit('showError', 'pipeIntervalError')
        this.interval.up_pipe = null
        this.interval.down_pipe = null
        this.busy = false
        return
      }
    }

    if (interval.up_cementing && interval.down_cementing) {
      const upCementing = await Unit.CONVERSE_UNITS({
        sourceVal: interval.up_cementing,
        sourceUnit: Number(interval.up_cementing_uc),
        destUnit: 100,
      })
      const downCementing = await Unit.CONVERSE_UNITS({
        sourceVal: interval.down_cementing,
        sourceUnit: Number(interval.down_cementing_uc),
        destUnit: 100,
      })
      if (upCementing && downCementing && upCementing > downCementing) {
        eventBus.$emit('showError', 'pipeCementingIntervalError')
        this.interval.up_cementing = null
        this.interval.down_cementing = null
        this.busy = false
        return
      }
    }

    if (typeof interval.intervaltype === 'string') {
      const type = await Wellbore.ADD_INTERVAL_TYPE(interval.intervaltype)
      interval.intervaltype = type && type.id ? type.id : null
    }

    if (typeof interval.intervaltype === 'object') {
      interval.intervaltype = Number(interval.intervaltype?.id)
    }

    if (typeof interval.pipetype === 'string') {
      const type = await Wellbore.ADD_PIPE_TYPE(interval.pipetype)
      interval.pipetype = type && type.id ? type.id : null
    }

    if (typeof interval.pipetype === 'object') {
      interval.pipetype = Number(interval.pipetype?.id)
    }

    if (this.editId) {
      await Wellbore.SAVE_INTERVAL(interval)
    } else {
      const item = await Wellbore.ADD_INTERVAL(interval)
      if (item) {
        await this.editPoint(item)
      }
    }
    await this.recalculateRemain()
    this.busy = false
  }

  private async editPoint(item: IInterval) {
    this.editId = Number(item.id)
    this.interval = { ...item }
    await this.recalculateRemain()
    await this.recalculatePipeLimit()
    this.isEditDialog = true
  }

  private async recalculateRemain() {
    if (this.interval.id) {
      const resCheck = await Wellbore.CHECK_INTERVAL({
        id: Number(this.interval.id),
      })
      this.maxLengthElements = resCheck?.length || 0
    }
  }

  private cancel() {
    if (this.isEditDialog) {
      this.cancelEdit()
    } else {
      void this.$router.push({
        name: 'TheWellbore',
        params: {
          id: String(this.fieldId),
          wellId: String(this.wellId),
          wellboreId: String(this.wellboreId),
        },
      })
    }
  }

  private changeValid(val: boolean) {
    this.valid = val
  }

  private openEditDialog(interval?: IInterval, index?: number, first = false) {
    this.isEditDialog = true
    if (interval) {
      this.interval.up_interval = interval.down_interval
      this.interval.up_interval_uc = interval.down_interval_uc
      this.interval.number = interval.number ? interval.number + 1 : 1
      if (index !== undefined) {
        const nextInterval = this.listIntervals[index + 1]
        this.interval.down_interval = nextInterval.up_interval
        this.interval.down_interval_uc = nextInterval.up_interval_uc
      }
    } else {
      if (this.listIntervals.length) {
        const int = this.listIntervals[this.listIntervals.length - 1]
        this.interval.number = first ? 1 : Number(int.number) + 1
        this.interval.up_interval = first ? 0 : int.down_interval
        this.interval.up_interval_uc = first ? 100 : int.down_interval_uc
        if (first) {
          const intFirst = this.listIntervals[0]
          this.interval.down_interval = intFirst.up_interval
          this.interval.down_interval_uc = intFirst.up_interval_uc
        }
      } else {
        this.interval.number = 1
        this.interval.up_interval = 0
      }
    }
  }

  private cancelEdit() {
    this.isEditDialog = false
    this.editId = null
    this.interval = { ...mockInterval }

    if (Object.keys(this.$route.query).length)
      void this.$router.replace({ query: {} })
  }

  private syncAll(val: boolean) {
    if (val && this.interval.cased && this.interval.full_pipe) {
      this.syncPipe()
      if (this.interval.cementing && this.interval.full_cementing) {
        this.syncCementing()
      }
    }
  }

  private syncPipe() {
    this.interval.up_pipe = 0
    this.interval.up_pipe_uc = 100
    this.interval.down_pipe = this.interval.down_interval
    this.interval.down_pipe_uc = this.interval.down_interval_uc
  }

  private syncCementing() {
    this.interval.up_cementing = this.interval.up_pipe
    this.interval.up_cementing_uc = this.interval.up_pipe_uc
    this.interval.down_cementing = this.interval.down_pipe
    this.interval.down_cementing_uc = this.interval.down_pipe_uc
  }

  private async recalculateDepthLimit() {
    const currentDepth = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.wellbore?.project_depth),
      sourceUnit: Number(this.wellbore?.project_depth_uc),
      destUnit: this.interval.down_interval_uc,
    })
    this.downIntervalMaxValue = currentDepth !== null ? currentDepth : 0

    const currentDepthUp = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.wellbore?.project_depth),
      sourceUnit: Number(this.wellbore?.project_depth_uc),
      destUnit: this.interval.up_interval_uc,
    })
    this.upIntervalMaxValue = currentDepthUp !== null ? currentDepthUp : 0
  }

  private async recalculatePipeLimit() {
    if (this.editId) {
      const currentDown = await Unit.CONVERSE_UNITS({
        sourceVal: Number(this.interval.down_interval),
        sourceUnit: Number(this.interval.down_interval_uc),
        destUnit: Number(this.interval.down_pipe_uc),
      })

      this.downPipeMaxValue = currentDown !== null ? currentDown : 0

      const currentUp = await Unit.CONVERSE_UNITS({
        sourceVal: Number(this.interval.down_interval),
        sourceUnit: Number(this.interval.down_interval_uc),
        destUnit: Number(this.interval.up_pipe_uc),
      })

      this.upPipeMaxValue = currentUp !== null ? currentUp : 0
    }
  }

  private async recalculateUpCementingLimit() {
    const current = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.interval.up_pipe),
      sourceUnit: Number(this.interval.up_pipe_uc),
      destUnit: Number(this.interval.up_cementing_uc),
    })

    const currentB = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.interval.down_pipe),
      sourceUnit: Number(this.interval.down_pipe_uc),
      destUnit: Number(this.interval.up_cementing_uc),
    })

    this.upTopCementingMaxValue = current !== null ? current : 0
    this.upBotCementingMaxValue = currentB !== null ? currentB : 0
  }

  private async recalculateDownCementingLimit() {
    const current = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.interval.up_pipe),
      sourceUnit: Number(this.interval.up_pipe_uc),
      destUnit: Number(this.interval.down_cementing_uc),
    })

    const currentB = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.interval.down_pipe),
      sourceUnit: Number(this.interval.down_pipe_uc),
      destUnit: Number(this.interval.down_cementing_uc),
    })

    this.downTopCementingMaxValue = current !== null ? current : 0
    this.downBotCementingMaxValue = currentB !== null ? currentB : 0
  }

  private async arrangeElements(elementId: number) {
    if (!this.busyInterval) {
      this.busyInterval = true
      const result = await Wellbore.ARRANGE_INTERVALS_ELEMENTS(elementId)
      if (result) await Wellbore.RELOAD_ELEMENTS(elementId)
      this.busyInterval = false
    }
  }

  private async arrangeIntervals() {
    if (!this.busy) {
      this.busy = true
      const result = await Wellbore.ARRANGE_INTERVALS(Number(this.wellboreId))
      if (result) await Wellbore.RELOAD_INTERVALS(Number(this.wellboreId))
      this.busy = false
    }
  }

  private offDragOverClass() {
    this.isDragOver = null
  }

  private onChangeEditing(value: boolean) {
    this.isElementEditing = value
  }

  private async onDragEnd(
    data: { id: number; number: number },
    number: number,
    idDrop?: number
  ) {
    const { id, number: initNumber } = data
    const idCheck = idDrop !== undefined ? idDrop !== id : true
    if (idCheck && number !== initNumber) {
      this.busy = true
      try {
        const result = await Wellbore.MOVE_TO_INTERVALS({ id, number })
        if (result) {
          void Wellbore.CLEAR_DATA('intervals')
          await Wellbore.RELOAD_INTERVALS(Number(this.wellboreId))
          this.busy = false
        }
      } catch (error) {
        this.busy = false
      }
    }
  }

  private async onLoadIntervals() {
    if (!this.busy && !this.intervalsFullLoaded) {
      this.busy = true
      await Wellbore.GET_INTERVALS({ wellboreId: Number(this.wellboreId) })
      this.busy = false
    }
  }

  private async onLoadPipeTypes() {
    if (!this.busy) {
      this.busy = true
      await Wellbore.GET_PIPE_TYPES()
      this.busy = false
    }
  }

  private async onLoadIntervalTypes() {
    if (!this.busy) {
      this.busy = true
      await Wellbore.GET_INTERVAL_TYPES()
      this.busy = false
    }
  }

  @Watch('interval.up_interval')
  private async onChangeUpInterval(val: number) {
    this.syncAll(true)
    await this.recalculatePipeLimit()
  }

  @Watch('interval.up_interval_uc')
  private async onChangeUpIntervalUc(val: number) {
    this.syncAll(true)
    await this.recalculateDepthLimit()
    await this.recalculatePipeLimit()
  }

  @Watch('interval.down_interval')
  private async onChangeDownInterval(val: number) {
    this.syncAll(true)
    await this.recalculatePipeLimit()
  }

  @Watch('interval.down_interval_uc')
  private async onChangeDownIntervalUc(val: number) {
    this.syncAll(true)
    await this.recalculateDepthLimit()
    await this.recalculatePipeLimit()
  }

  @Watch('interval.up_pipe')
  private async onChangeUpPipe(val: number) {
    if (this.interval.cementing && this.interval.full_cementing) {
      this.syncCementing()
    }
    await this.recalculatePipeLimit()
    await this.recalculateUpCementingLimit()
  }

  @Watch('interval.up_pipe_uc')
  private async onChangeUpPipeUc(val: number) {
    if (this.interval.cementing && this.interval.full_cementing) {
      this.syncCementing()
    }
    await this.recalculatePipeLimit()
    await this.recalculateUpCementingLimit()
  }

  @Watch('interval.down_pipe')
  private async onChangeDownPipe(val: number) {
    if (this.interval.cementing && this.interval.full_cementing) {
      this.syncCementing()
    }
    await this.recalculatePipeLimit()
    await this.recalculateDownCementingLimit()
  }

  @Watch('interval.down_pipe_uc')
  private async onChangeDownPipeUc(val: number) {
    if (this.interval.cementing && this.interval.full_cementing) {
      this.syncCementing()
    }
    await this.recalculatePipeLimit()
    await this.recalculateDownCementingLimit()
  }

  @Watch('interval.up_cementing_uc')
  private async onChangeUpCementingUc(val: number) {
    await this.recalculateUpCementingLimit()
  }

  @Watch('interval.down_cementing_uc')
  private async onChangeDownCementingUc(val: number) {
    await this.recalculateDownCementingLimit()
  }

  @Watch('interval.up_cementing')
  private async onChangeUpCementing(val: number) {
    await this.recalculateUpCementingLimit()
  }

  @Watch('interval.down_cementing')
  private async onChangeDownCementing(val: number) {
    await this.recalculateDownCementingLimit()
  }

  @Watch('interval.full_pipe')
  private onChangeFullPipe(val: boolean) {
    if (val) {
      this.syncPipe()
    }
  }

  @Watch('interval.cementing')
  private onChangeCementing(val: boolean) {
    if (val && this.interval.full_cementing) {
      this.syncCementing()
    }
  }

  @Watch('interval.full_cementing')
  private onChangeFullCementing(val: boolean) {
    if (val) {
      this.syncCementing()
    }
  }

  @Watch('intervals', { immediate: true })
  private async onEndLoadIntervals(arr: TIntervals) {
    if (arr.length)
      this.listIntervals = [...this.intervals].sort(
        (a, b) => Number(a.number) - Number(b.number)
      )
    if (
      this.$route.query.type === 'interval' ||
      this.$route.query.type === 'pipe'
    ) {
      const id = Number(this.$route.query.id)
      const item = arr.find((item) => Number(item.id) === id)
      if (item) await this.editPoint(item)
    } else if (this.$route.query.type === 'element') {
      const elem = await Wellbore.GET_ELEMENT(Number(this.$route.query.id))
      if (elem && elem.interval) {
        const interval =
          typeof elem.interval === 'number' ? elem.interval : elem.interval.id
        const item = arr.find((item) => Number(item.id) === interval)
        if (item) await this.editPoint(item)
      }
    }
  }

  @Watch('isEditDialog')
  private onChangeEditDialogValue(val: boolean) {
    eventBus.$emit('disableWellboreTypes', val)
  }

  private async onDelete(id: number) {
    this.busy = true
    await Wellbore.DELETE_INTERVAL({
      intId: id,
      wellboreId: Number(this.wellboreId),
    })
    this.busy = false
  }

  private async mounted() {
    await Promise.all([
      Wellbore.GET_INTERVAL_TYPES(),
      Wellbore.GET_PIPE_TYPES(),
      this.onLoadIntervals(),
      this.recalculateDepthLimit(),
      this.recalculateUpCementingLimit(),
      this.recalculateDownCementingLimit(),
    ])
  }
}

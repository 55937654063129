import { DataMock } from '@/helpers/consts'
import { eventBus, getError } from '@/helpers/eventBus'

import axiosInstance from '@/services/api'

import {
  TCalculatorParams,
  TCalculatorResults,
  TCalculators,
  ICalculatorVolumeResult,
  ICalculatorStrengthResult,
  TWedgegrips,
  ICalcStrengthInputDataForm,
  ICalcVolumeInputDataForm,
  TItemWellboreInfo,
  TItemEquipComposition,
  TCalculationVariant,
  TChartResults,
  IPagedData,
  TTechOperations,
  IWedgegrip,
  TBooleanKeys,
  IWellEquipmentsetInfo,
  TCalculationVariantShare,
} from '@/types'
import { Dictionary } from 'vue-router/types/router'

export async function calculateVolume(data: {
  params: TCalculatorParams
  inputData: ICalcVolumeInputDataForm | null
}): Promise<ICalculatorVolumeResult | null> {
  const inputData = {
    code: 1,
    wellbore: Number(data.params.wellbore),
    equipment_set: Number(data.params.equip),
    input_data: data.inputData,
  }

  try {
    eventBus.$emit('calculatorPercent', true)
    const response = await axiosInstance.post<ICalculatorVolumeResult>(
      '/calc/',
      inputData
    )
    if (response?.data) {
      eventBus.$emit('calculatorPercent', false)
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('calculatorPercent', false)
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function calculateStrength(data: {
  params: TCalculatorParams
  inputData: ICalcStrengthInputDataForm | null
  settings: TBooleanKeys
}): Promise<ICalculatorStrengthResult | null> {
  if (data.inputData) {
    const { verification, wedge_grip, ...str } = data.inputData

    const inputData = {
      code: 2,
      wellbore: Number(data.params.wellbore),
      equipment_set: Number(data.params.equip),
      input_data: {
        ...str,
        verification: data.settings.strVerif ? verification : undefined,
      },
      wedgegrip: data.settings.strWedgegrips ? wedge_grip : undefined,
    }

    try {
      eventBus.$emit('calculatorPercent', true)
      const response =
        await axiosInstance.post<ICalculatorStrengthResult | null>(
          '/calc/',
          inputData
          // {
          //   onDownloadProgress: (progressEvent: ProgressEvent) => {
          //     const percentage = Math.round(
          //       (progressEvent.loaded * 1700) / progressEvent.total
          //     )
          //     eventBus.$emit('calculatorPercent', percentage)
          //   },
          // }
        )
      if (response?.data) {
        eventBus.$emit('calculatorPercent', false)
        return response.data
      }
      return null
    } catch (error: unknown) {
      eventBus.$emit('calculatorPercent', false)
      eventBus.$emit('showError', getError(error))
      return null
    }
  }
  return null
}

export async function getCalculations(
  page: number | null,
  query?: TCalculatorParams
) {
  try {
    const response = await axiosInstance.get<IPagedData<TCalculatorResults>>(
      '/calculations/',
      { params: { ...query, page } }
    )
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getCalculationsRepeat(query?: TCalculatorParams) {
  try {
    const response = await axiosInstance.get<IPagedData<TCalculatorResults>>(
      '/calculations/',
      { params: { ...query, page_size: -1 } }
    )
    if (response?.data.results) {
      return response.data.results
    }

    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function getCalculation(id: string) {
  try {
    const response = await axiosInstance.get<TCalculationVariant>(
      `/calculations/${id}/`
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getCalculators(query?: Dictionary<string>) {
  try {
    const response = await axiosInstance.get<IPagedData<TCalculators>>(
      '/calctypes/',
      {
        params: { page_size: -1, ...query },
      }
    )
    if (response?.data?.results) {
      return response.data?.results
    }

    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function getWedgegrips(
  page: number | null,
  query?: Dictionary<string>
): Promise<IPagedData<TWedgegrips>> {
  try {
    const response = await axiosInstance.get<IPagedData<TWedgegrips>>(
      '/wedgegrips/',
      { params: { page, ...query } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function createWedgegrip(
  data: IWedgegrip
): Promise<IWedgegrip | null> {
  try {
    const response = await axiosInstance.post<IWedgegrip>('/wedgegrips/', data)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveWedgegrip(data: IWedgegrip) {
  try {
    const id = String(data.id)

    const response = await axiosInstance.patch<IWedgegrip>(
      `/wedgegrips/${id}/`,
      data
    )
    if (response?.data) {
      return response.data
    }
    return
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return
  }
}

export async function getReportFile(id: number): Promise<Blob | null> {
  try {
    const response = await axiosInstance.get<Blob>(`/calc/report/${id}/`, {
      responseType: 'blob',
    })

    if (response) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getKzp(data: {
  base: string | null
  drilling_way: string | null
  profile: string | null
  drilling_conditions: string | null
}) {
  let result = 0
  const { base, drilling_way, profile, drilling_conditions } = data
  if (base && drilling_way && profile && drilling_conditions) {
    if (base === 'stationary') {
      if (drilling_way === 'VZD') {
        if (profile === 'vertical') {
          if (drilling_conditions === 'normal') {
            result = 1.3
          } else if (drilling_conditions === 'complicated') {
            result = 1.35
          }
        } else if (profile === 'inclined') {
          if (drilling_conditions === 'normal') {
            result = 1.35
          } else if (drilling_conditions === 'complicated') {
            result = 1.4
          }
        }
      } else if (drilling_way === 'VSP') {
        if (profile === 'vertical') {
          if (drilling_conditions === 'normal') {
            result = 1.4
          } else if (drilling_conditions === 'complicated') {
            result = 1.45
          }
        } else if (profile === 'inclined') {
          if (drilling_conditions === 'normal') {
            result = 1.45
          } else if (drilling_conditions === 'complicated') {
            result = 1.5
          }
        }
      }
    } else if (base === 'floating') {
      if (drilling_way === 'VZD') {
        if (profile === 'vertical') {
          if (drilling_conditions === 'normal') {
            result = 1.35
          } else if (drilling_conditions === 'complicated') {
            result = 1.4
          }
        } else if (profile === 'inclined') {
          if (drilling_conditions === 'normal') {
            result = 1.4
          } else if (drilling_conditions === 'complicated') {
            result = 1.45
          }
        }
      } else if (drilling_way === 'VSP') {
        if (profile === 'vertical') {
          if (drilling_conditions === 'normal') {
            result = 1.45
          } else if (drilling_conditions === 'complicated') {
            result = 1.5
          }
        } else if (profile === 'inclined') {
          if (drilling_conditions === 'normal') {
            result = 1.5
          } else if (drilling_conditions === 'complicated') {
            result = 1.55
          }
        }
      }
    }
  } else return Promise.resolve(null)

  return Promise.resolve(result)
}

export async function getEquipmentsetInfo(
  wellbore: number,
  equip: number
): Promise<IWellEquipmentsetInfo | null> {
  try {
    const response = await axiosInstance.post<IWellEquipmentsetInfo>(
      '/well_equipmentset_info/',
      { wellbore: wellbore, equipment_set: equip }
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getConstructionInfo(
  wellbore: number
): Promise<TItemWellboreInfo> {
  try {
    const response = await axiosInstance.post<TItemWellboreInfo>(
      '/well_construction_info/',
      { wellbore }
    )
    if (response?.data) {
      return response.data
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function getEquipInfo(
  equipment_set: number
): Promise<TItemEquipComposition> {
  try {
    const response = await axiosInstance.post<TItemEquipComposition>(
      '/equipment_set_info/',
      { equipment_set }
    )
    if (response?.data) {
      return response.data
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function toggleShared(data: TCalculationVariantShare) {
  const id = data.id
  try {
    const response = await axiosInstance.patch<TCalculationVariantShare>(
      `/calculations/${id}/`,
      data
    )
    if (response?.data) {
      eventBus.$emit(
        response.data.shared ? 'showAlert' : 'showInfo',
        response.data.shared ? 'sharedOn' : 'sharedOff'
      )
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getChart(id: string) {
  try {
    const response = await axiosInstance.get<TChartResults>(
      `/calc/chart/${id}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getScheme(id: string) {
  try {
    const response = await axiosInstance.get<string>(`/calc/scheme/${id}/`)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getTechOperations() {
  try {
    const response = await axiosInstance.get<TTechOperations>(
      `/tech_operations/`
    )
    if (response?.data) {
      return response.data
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}


import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WPLogo from './WPLogo.vue'
import WPMenu from './WPMenu.vue'
import WPDialog from './WPDialog.vue'
import { eventBus } from '@/helpers/eventBus'
import { Settings, SettingsSupport } from '../views/user'
import { PasswordNew } from '../views/auth'
import { User } from '@/store/user'

@Component({
  name: 'WPHeader',
  components: {
    WPMenu,
    WPLogo,
    WPDialog,
    Settings,
    SettingsSupport,
    PasswordNew,
  },
})
export default class WPHeader extends Vue {
  private isShowSettings = false

  private isShowSupport = false

  private isShowPassword = false

  private get menu() {
    return [
      { title: this.$t('menu.fields'), name: 'Fields' },
      { title: this.$t('menu.wells'), name: 'Wells' },
      { title: this.$t('menu.calcs'), name: 'Calculators' },
      { title: this.$t('menu.results'), name: 'Results' },
    ]
  }

  private get userLogin() {
    return User.user?.username
  }

  private logout() {
    this.$emit('logout')
  }

  private created() {
    eventBus.$on('openSettings', () => {
      this.isShowSettings = true
    })
    eventBus.$on('openSupport', () => {
      this.isShowSupport = true
    })
    eventBus.$on('openPassword', () => {
      this.isShowPassword = true
    })
    eventBus.$on('closePassword', () => {
      this.isShowPassword = false
    })
    eventBus.$on('closeSettings', () => {
      this.isShowSettings = false
    })
    eventBus.$on('closeSupport', () => {
      this.isShowSupport = false
    })
  }

  private beforeDestroy() {
    eventBus.$off('openSettings')
    eventBus.$off('openSupport')
    eventBus.$off('openPassword')
    eventBus.$off('closeSettings')
    eventBus.$off('closeSupport')
    eventBus.$off('closePassword')
  }
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  WPButtonIcon,
  WPTextfield,
  WPTextfieldMeasure,
  WPRadioGroup,
  WPBlockBtn,
  WPDivider,
  WPInfoBlock,
  WPGroup,
  WPCombobox,
  WPSwitch,
  WPGroupMultiline,
  WPCheckbox,
  WPColorPicker,
  WPBlockHint,
  WPLoader,
  WPExpPanel,
  WPLayoutBorder,
} from '@/components'
import TheWellboreEquipElementAreas from './TheWellboreEquipElementAreas.vue'
import TheWellboreEquipElementsLock from './TheWellboreEquipElementsLock.vue'

import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'

import {
  TEquipElementTypes,
  TEquipElements,
  IEquipElement,
  TSteelGrades,
  ISteelGrade,
  IEquipElementType,
  IConnectionSymbols,
  IEquip,
  IConnectionSymbol,
} from '@/types'
import { eventBus } from '@/helpers/eventBus'
import { timeout } from '@/services/common'

export interface IEquipElementUnitKeys {
  length_uc: number
  outer_diameter_uc: number
  inner_diameter_uc: number
  running_meter_weight_uc: number
  limit_endurance_uc: number
  repeat_length_uc: number
  wall_thickness_uc: number
  max_axial_stretching_uc: number
  max_axial_compression_uc: number
  max_torque_uc: number
}

export interface ISteelUnitKeys {
  density_uc: number
  module_elastic_uc: number
  limit_fluidity_uc: number
  limit_strength_uc: number
}

export interface ISteelKeys {
  density: number | null
  module_elastic: number | null
  limit_fluidity: number | null
  limit_strength: number | null
  PoissonsRatio: number | null
}

const mockElement: IEquipElement = {
  equipe_elementtype: null,
  connection_present: false,
  number: null,
  color: '',
  length: null,
  length_uc: 100,
  repeatability: false,
  repeat_length: 0,
  repeat_length_uc: 100,
  full_length: false,
  complexity: false,
  outer_diameter: null,
  outer_diameter_uc: 100,
  inner_diameter_or_thickness: false,
  wall_thickness: null,
  wall_thickness_uc: 100,
  inner_diameter: null,
  inner_diameter_uc: 100,
  running_meter_weight: null,
  running_meter_weight_uc: 1800,
  steelgrade: null,
  limit_endurance: null,
  limit_endurance_uc: 500,
  rock_destruction_tool: false,
  max_axial_stretching: null,
  max_axial_stretching_uc: 600,
  max_axial_compression: null,
  max_axial_compression_uc: 600,
  max_torque: null,
  max_torque_uc: 400,
  downhole_motor: false,
}

const mockSteelGrade: ISteelGrade = {
  name: '',
  density: null,
  density_uc: 700,
  module_elastic: null,
  module_elastic_uc: 500,
  limit_fluidity: null,
  limit_fluidity_uc: 500,
  limit_strength: null,
  limit_strength_uc: 500,
  PoissonsRatio: null,
}

@Component({
  components: {
    WPButtonIcon,
    WPTextfield,
    WPTextfieldMeasure,
    WPRadioGroup,
    WPBlockBtn,
    WPDivider,
    WPInfoBlock,
    WPGroup,
    WPCombobox,
    WPSwitch,
    WPGroupMultiline,
    WPCheckbox,
    WPColorPicker,
    WPBlockHint,
    WPLoader,
    TheWellboreEquipElementAreas,
    TheWellboreEquipElementsLock,
    WPExpPanel,
    WPLayoutBorder,
  },
})
export default class TheWellboreEquipElements extends Vue {
  @Prop({ type: Number, required: true })
  private equip?: number

  @Prop({ type: [Number, String], default: 0 })
  private maxLength?: number | string

  @Prop({ type: Number, default: 100 })
  private maxLengthUc?: number

  @Prop({ type: Boolean, default: false })
  private validParent?: boolean

  private maxLengthWithUc = 0

  private valid = false

  private editIndex: number | null = null

  private editId?: number = undefined

  private isFormOpened = false

  private loading = true

  private busy = false

  private symbolsKey = 0

  private isShowAddBtnList = false

  private isShowAddBtnListId = 0

  private isShowEditBtnList = false

  private isShowEditBtnListId = 0

  private disableForm = false

  private colors = [
    ['#000000', '#b183ed'],
    ['#7f7f7f', '#96b5d1'],
    ['#afabab', '#a4c290'],
    ['#eff5a2', '#aefdf9'],
    ['#dea883', '#00b0f0'],
  ]

  private elementType: IEquipElementType = {
    name: '',
  }

  private steelgrade: ISteelGrade = {
    ...mockSteelGrade,
  }

  private element: IEquipElement = {
    ...mockElement,
  }

  private symbols: IConnectionSymbols = {}

  private isScrolled = false

  private get elementTypeMethods() {
    return [
      {
        text: this.$t('elements.selectInner'),
        value: true,
      },
      {
        text: this.$t('elements.selectThickness'),
        value: false,
      },
    ]
  }

  private get selectedSteelGrade() {
    let id = Number(this.element.steelgrade)

    if (id) {
      const result = this.steelgrades.find((item) => item.id === id)
      return result ? result : this.steelgrade
    } else {
      return this.steelgrade
    }
  }

  private get isFullLengthEnabled() {
    if (this.isShowAddBtnList) {
      return this.isShowAddBtnListId === this.listElements[0].id
    } else return false
  }

  private get isEdit() {
    return this.editIndex !== null
  }

  private get elementTypes(): TEquipElementTypes {
    return Wellbore.equipeElementTypes
  }

  private get elements(): TEquipElements {
    return Wellbore.equipeElements
  }

  private get listElements(): TEquipElements {
    return [...this.elements].sort(
      (a, b) => Number(b.number) - Number(a.number)
    )
  }

  private get steelgrades(): TSteelGrades {
    return Wellbore.steelgrades
  }

  private get isAreas(): boolean {
    return !!Wellbore.equipeAreas.length
  }

  private get isBtnDisabled(): boolean {
    return !this.valid || (this.element.complexity && !this.isAreas)
  }

  private get isNotEditableSteel(): boolean {
    return this.selectedSteelGrade.user === null
  }

  private get equipObj(): IEquip | undefined {
    const result = Wellbore.equips.find((item) => item.id === this.equip)
    return result
  }

  private get isRockDestructionDisabled() {
    let obj = this.elements.find((item) => item.id === this.element.id)
    let result = false
    this.elements.map((item) => {
      if (item.rock_destruction_tool) result = true
    })
    if (this.element.downhole_motor) return true
    if (obj) {
      return obj.rock_destruction_tool ? false : result
    } else {
      return result
    }
  }

  private get isDownholeMotorDisabled() {
    let obj = this.elements.find((item) => item.id === this.element.id)
    let result = false
    this.elements.map((item) => {
      if (item.downhole_motor) result = true
    })
    if (this.element.rock_destruction_tool) return true
    if (obj) {
      return obj.downhole_motor ? false : result
    } else {
      return result
    }
  }

  private get isNewSteelgrade() {
    return (
      this.element.steelgrade === null ||
      typeof this.element.steelgrade === 'string'
    )
  }

  private get maxLengthWithUcText() {
    return `${this.maxLengthWithUc} ${this.getMeasure(
      this.element.repeatability
        ? this.element.repeat_length_uc
        : this.element.length_uc
    )}`
  }

  private get isBtnsDisabled() {
    return this.isEdit || this.isFormOpened
  }

  private getMeasure(key: number) {
    return Unit.units.find((item) => item.code === key)?.symbol || ''
  }

  private changeMeasure(val: number, key: keyof IEquipElementUnitKeys) {
    this.element[key] = val
  }

  private changeSteelMeasure(val: number, key: keyof ISteelUnitKeys) {
    this.selectedSteelGrade[key] = val
  }

  private changeSteelValue(val: number, key: keyof ISteelKeys) {
    this.selectedSteelGrade[key] = val
  }

  private getElementTypeName(obj: IEquipElement) {
    return typeof obj.equipe_elementtype === 'object'
      ? obj.equipe_elementtype?.name
      : Wellbore.equipeElementTypes.find(
          (item) => item.id === obj.equipe_elementtype
        )?.name
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private toggleAddBtnList(val: boolean, id: number) {
    this.isShowAddBtnList = val
    this.isShowAddBtnListId = id
  }

  private toggleEditBtnList(val: boolean, id: number) {
    this.isShowEditBtnList = val
    this.isShowEditBtnListId = id
  }

  private showAddBtnList(id: number, index: number) {
    const currentBtn = this.isShowAddBtnList && this.isShowAddBtnListId === id
    const currentEditBtn =
      this.isShowEditBtnList && this.isShowEditBtnListId === id
    const isLast = index === 0
    let isHasEmptyNumber = false
    if (!isLast) {
      const numCurr = this.listElements[index].number || 0
      const numNext = this.listElements[index - 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return (currentBtn && isHasEmptyNumber) || currentEditBtn
    } else return true
  }

  private visibleAddBtnList(id: number, index: number) {
    const isLast = index === 0
    let isHasEmptyNumber = false
    if (!isLast) {
      const numCurr = this.listElements[index].number || 0
      const numNext = this.listElements[index - 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return isHasEmptyNumber
    } else return true
  }

  private async resetForm(isCloseForm = false, isScrollForm = true) {
    const form = this.$refs.equipForm as Array<
      Vue & { resetValidation: () => void }
    >
    if (form[0]) form[0].resetValidation()

    if (isCloseForm) {
      this.isFormOpened = false
      this.toggleAddBtnList(false, 0)
      this.toggleEditBtnList(false, 0)
    } else if (isScrollForm) {
      await this.scrollFunc()
    }
  }

  private getElementInfo(item: IEquipElement) {
    const unitLength = String(this.getUnitSymbol(item.length_uc))
    const unitLengthRepeat = String(this.getUnitSymbol(item.repeat_length_uc))

    const repeat = item.repeatability
    const full = item.full_length
    const simple = !item.complexity
    const data = {
      length: item.length,
      lengthUc: unitLength,
    }
    const dataRepeat = {
      length: item.length,
      lengthUc: unitLength,
      repeat: item.repeat_length,
      repeatUc: unitLengthRepeat,
    }

    if (repeat) {
      if (full) {
        if (simple) {
          return this.$t('elements.elementInfo.repeatFullSimple', data)
        } else {
          return this.$t('elements.elementInfo.repeatFullCompl', data)
        }
      } else {
        if (simple) {
          return this.$t('elements.elementInfo.repeatLengthSimple', dataRepeat)
        } else {
          return this.$t('elements.elementInfo.repeatLengthCompl', dataRepeat)
        }
      }
    } else {
      if (full) {
        if (simple) {
          return this.$t('elements.elementInfo.soloFullSimple', data)
        } else {
          return this.$t('elements.elementInfo.soloFullCompl', data)
        }
      } else {
        if (simple) {
          return this.$t('elements.elementInfo.soloLengthSimple', data)
        } else {
          return this.$t('elements.elementInfo.soloLengthCompl', data)
        }
      }
    }
  }

  private getElementTypeColor(item: IEquipElement) {
    const { equipe_elementtype } = item
    if (typeof equipe_elementtype === 'object') {
      return equipe_elementtype?.color
    } else {
      const color = Wellbore.equipeElementTypes.find(
        (item) => item.id === equipe_elementtype
      )
      return color ? color.color : ''
    }
  }

  private getSteelGradeCombobox(id: number) {
    const steel = this.steelgrades.find((item) => item.id === id)
    return steel || this.selectedSteelGrade
  }

  private clearColor() {
    this.element.color = ''
  }

  private setSymbols(val: IConnectionSymbol) {
    const id = val.id
    if (id) {
      this.symbols[id] = val
      this.symbolsKey++
    }
  }

  private async editPoint(item: IEquipElement, index: number, isScroll = true) {
    if (this.editId !== item.id && !this.disableForm) {
      this.toggleEditBtnList(true, Number(item.id))
      this.toggleAddBtnList(true, Number(item.id))
      if (this.validParent) {
        this.$emit('save')
      }
      this.editId = item.id
      this.editIndex = index
      this.element = { ...item }
      await this.onChangeFullRepeat(item.full_length)

      if (this.element.steelgrade) await this.getSteelgradeById()

      if (!this.isFormOpened) {
        this.isFormOpened = true
        await timeout(0)
      }
      if (isScroll) {
        void this.$nextTick(async () => {
          await this.scrollFunc()
        })
      }
    }
  }

  private async scrollFunc(isTimer = true) {
    if (isTimer) await timeout(300)
    const el = document.querySelector(
      '.wp-form__exp-form_opened'
    ) as HTMLElement

    if (el) {
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  private async saveElementTypeParams() {
    if (this.element.equipe_elementtype) {
      if (typeof this.element.equipe_elementtype === 'number') {
        let elementTypeObj = this.elementTypes.find(
          (item) => item.id === this.element.equipe_elementtype
        )

        if (elementTypeObj) {
          const result = {
            ...elementTypeObj,
            ...this.elementType,
            name: elementTypeObj.name,
          }
          await Wellbore.SAVE_EQUIP_ELEMENT_TYPES(result)
        }
      } else {
        const result = {
          ...this.element.equipe_elementtype,
          ...this.elementType,
          name: this.element.equipe_elementtype.name,
        }
        await Wellbore.SAVE_EQUIP_ELEMENT_TYPES(result)
      }
    }
  }

  private async getSteelgradeById() {
    if (
      this.element.steelgrade &&
      typeof this.element.steelgrade === 'number'
    ) {
      const steel = this.element.steelgrade
      const steelObject = this.steelgrades.find((item) => item.id === steel)
      if (!steelObject) {
        const steelResponse = await Wellbore.GET_STEEL_GRADE_BY_ID(
          this.element.steelgrade
        )
        if (steelResponse) {
          void this.onInputSteelgrade(steelResponse, false)
        }
      } else {
        void this.onInputSteelgrade(steelObject, false)
      }
    }
  }

  private async addElement(isCloseForm = false, isScrollForm = true) {
    this.busy = true
    const valid = await this.checkElementLength()

    if (!valid) {
      eventBus.$emit('showError', `length | ${this.maxLengthWithUcText}`)
      this.busy = false
      return
    }

    if (this.isNewSteelgrade) {
      const obj = {
        ...this.selectedSteelGrade,
      }
      delete obj.id
      const newSteel = await Wellbore.ADD_STEEL_GRADE(obj)
      if (newSteel) {
        this.element.steelgrade = Number(newSteel.id)
      }
    } else if (
      typeof this.element.steelgrade === 'number' &&
      this.selectedSteelGrade.user !== null
    ) {
      await Wellbore.SAVE_STEEL_GRADE(this.selectedSteelGrade)
    }

    if (typeof this.element.equipe_elementtype === 'string') {
      const obj = {
        ...this.elementType,
        name: this.element.equipe_elementtype,
      }
      const newType = await Wellbore.ADD_EQUIP_ELEMENT_TYPES(obj)
      if (newType) {
        this.element.equipe_elementtype = newType
      }
    }

    if (this.isEdit) {
      await this.saveElement(isCloseForm, isScrollForm)
      return
    }

    const element = {
      ...this.element,
      equipment_set: this.equip,
    }

    const newElem = await Wellbore.ADD_EQUIP_ELEMENT(element)
    if (newElem && newElem.id) this.symbols[newElem.id] = { up: '', down: '' }

    if (isCloseForm && newElem) {
      const index = this.listElements.findIndex(
        (item) => item.id === newElem.id
      )
      await this.editPoint(newElem, index)
      this.busy = false
    } else if (newElem) {
      const noNumber = this.elements.find(
        (item) => item.number === Number(newElem?.number) + 1
      )
      if (noNumber) {
        this.element = {
          ...mockElement,
          number: Number(this.listElements[0].number) + 1,
        }
        this.toggleAddBtnList(true, Number(this.listElements[0].id))
      } else {
        this.element = {
          ...mockElement,
          number: Number(newElem?.number) + 1,
        }
        this.toggleAddBtnList(true, Number(newElem?.id))
      }
      if (isScrollForm) {
        await timeout(300)
        await this.scrollFunc()
      }
    }
    this.busy = false
  }

  private async updateElements() {
    await Wellbore.GET_EQUIP_ELEMENTS(Number(this.equip))
  }

  private async saveElement(isCloseForm = false, isScrollForm = true) {
    const element = {
      ...this.element,
      equipment_set: this.equip,
    }

    const resp = await Wellbore.SAVE_EQUIP_ELEMENT(element)
    if (resp) {
      this.editIndex = null
      this.editId = undefined
      this.element = {
        ...mockElement,
        number: Number(this.listElements[0].number) + 1,
      }
      this.steelgrade = {
        ...mockSteelGrade,
      }
      await this.resetForm(isCloseForm, isScrollForm)
    }
    this.busy = false
  }

  private async checkElementLength() {
    let elementsLength = 0
    let promises: Promise<number | null>[] = []
    this.listElements.forEach((item, index) => {
      let length = 0
      let lengthUc = 0
      if (index === this.editIndex) {
        length = this.element.repeatability
          ? this.element.repeat_length
          : Number(this.element.length)
        lengthUc = this.element.repeatability
          ? this.element.repeat_length_uc
          : this.element.length_uc
      } else {
        length = item.repeatability ? item.repeat_length : Number(item.length)
        lengthUc = item.repeatability ? item.repeat_length_uc : item.length_uc
      }
      promises.push(
        Unit.CONVERSE_UNITS({
          sourceVal: length,
          sourceUnit: lengthUc,
          destUnit: 100,
        })
      )
    })

    if (this.editIndex === null) {
      promises.push(
        Unit.CONVERSE_UNITS({
          sourceVal: this.element.repeatability
            ? this.element.repeat_length
            : Number(this.element.length),
          sourceUnit: this.element.repeatability
            ? this.element.repeat_length_uc
            : this.element.length_uc,
          destUnit: 100,
        })
      )
    }
    await Promise.all(promises).then((values) => {
      values.map((value) => {
        if (value) elementsLength = elementsLength + value
      })
    })

    const maxLength = await Unit.CONVERSE_UNITS({
      sourceVal: this.maxLengthWithUc,
      sourceUnit: this.element.length_uc,
      destUnit: 100,
    })

    return Number(elementsLength.toFixed(10)) <= Number(maxLength)
  }

  private async recalculateLength() {
    const maxLength = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.maxLength),
      sourceUnit: Number(this.maxLengthUc),
      destUnit: this.element.repeatability
        ? this.element.repeat_length_uc
        : this.element.length_uc,
    })
    this.maxLengthWithUc = maxLength !== null ? maxLength : 0
  }

  private onHoverBlockBtnList(value: boolean, id: number) {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(value, id)
  }

  private onBlurBlockBtnList() {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(false, 0)
  }

  private onToggleLockForm(val: boolean) {
    this.disableForm = val
  }

  private async onClickExpEmptyPanel(value: boolean) {
    if (this.validParent) {
      this.$emit('save')
      if (this.isFormOpened) {
        this.isFormOpened = false
      } else {
        this.isFormOpened = true
        this.element.number = 1
      }
      if (value) {
        await timeout(100)
        await this.scrollFunc()
      }
    }
  }

  private async onClickExpPanel(value: boolean, item: IEquipElement) {
    if (value !== undefined && item.id) this.toggleAddBtnList(value, item.id)
    if (this.validParent) {
      this.$emit('save')
      if (this.isFormOpened) {
        this.isFormOpened = false
      } else {
        this.isFormOpened = true
        if (!this.isEdit) this.element.number = Number(item.number) + 1
      }
      if (value) {
        await timeout(100)
        await this.scrollFunc()
      }
    }
  }

  private onInputSteelgrade(val: string | ISteelGrade, clear = true) {
    if (typeof val === 'string') {
      const result = { ...this.selectedSteelGrade }
      delete result.id
      delete result.code
      delete result.user
      this.element.steelgrade = val
      this.steelgrade = { ...result, name: val }
    } else {
      this.element.steelgrade = Number(val.id)
      const result = { ...val }
      if (clear) {
        delete result.user
        delete result.id
        delete result.code
      }
      this.steelgrade = { ...result }
    }
  }

  private async onLoadEquipElementTypes() {
    await Wellbore.GET_EQUIP_ELEMENT_TYPES()
  }

  private async onLoadSteelgrades() {
    await Wellbore.GET_STEEL_GRADES()
  }

  private async onDelete(id: number, index: number) {
    await Wellbore.DELETE_EQUIP_ELEMENT(id)
    delete this.symbols[id]
  }

  @Watch('isBtnsDisabled', { immediate: true })
  private onChangeFormOpened(val: boolean) {
    this.$emit('changeEditing', val)
  }

  @Watch('element.equipe_elementtype')
  private onChangeElemType(val: number | IEquipElementType) {
    if (val && typeof val === 'object') {
      this.elementType.name = val.name
    }
  }

  @Watch('element.repeatability')
  private async onChangeRepeatability(val: boolean) {
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('element.full_length')
  private async onChangeFullRepeat(val: boolean) {
    if (val && this.equipObj) {
      const resCheck = await Wellbore.CHECK_EQUIP({
        id: Number(this.equipObj.id),
        elementId: this.isEdit ? this.element.id : undefined,
      })
      const result = Number(resCheck?.remain_length)

      if (this.element.repeatability) {
        const resultWithUc = await Unit.CONVERSE_UNITS({
          sourceVal: result,
          sourceUnit: 100,
          destUnit: this.element.repeat_length_uc,
        })
        this.element.repeat_length =
          Number(resultWithUc) < 0 ? 0 : Number(resultWithUc)
      } else {
        const resultWithUc = await Unit.CONVERSE_UNITS({
          sourceVal: result,
          sourceUnit: 100,
          destUnit: this.element.length_uc,
        })
        this.element.length = Number(resultWithUc) < 0 ? 0 : resultWithUc
      }
    }
  }

  @Watch('elementType.steelgrade')
  private onChangeSteelGrade(val: number | ISteelGrade) {
    if (typeof val === 'number') {
      const steel = this.steelgrades.find((item) => item.id === val)
      if (steel) {
        this.element.steelgrade = Number(steel.id)
      }
    } else if (typeof val === 'object') {
      this.element.steelgrade = Number(val.id)
    }
  }

  @Watch('elements')
  private async onLoadElements(arr: TEquipElements) {
    if (arr && arr.length) {
      const id = Number(this.$route.query.elem)
      if (id && !this.isScrolled) {
        const item = arr.find((item) => Number(item.id) === id)
        const result = [...arr].reverse()
        const itemIndex = result.findIndex((item) => Number(item.id) === id)
        if (item) {
          await this.editPoint(item, itemIndex, true)
        }
      }
      if (!this.isEdit)
        this.element.number = Number(this.listElements[0].number) + 1
      this.isScrolled = true
    }
  }

  @Watch('maxLength')
  private async onChangeMaxLength(val: number) {
    await this.recalculateLength()
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('maxLengthUc')
  private async onChangeMaxLengthUc(val: number) {
    await this.recalculateLength()
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('element.length_uc')
  private async onChangeLengthUc(val: number) {
    await this.recalculateLength()
  }

  @Watch('element.repeat_length_uc')
  private async onChangeRepeatLengthUc(val: number) {
    await this.recalculateLength()
  }

  private async mounted() {
    this.loading = true
    await Promise.all([
      Wellbore.GET_EQUIP_ELEMENTS(Number(this.equip)),
      Wellbore.GET_EQUIP_ELEMENT_TYPES(),
      Wellbore.GET_STEEL_GRADES(),
    ])

    this.elements.forEach((item: IEquipElement) => {
      if (item.id) this.symbols[item.id] = { up: '', down: '' }
    })

    if (this.element.steelgrade) await this.getSteelgradeById()
    await this.recalculateLength()
    this.loading = false
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wp-calculators-list"},[_c('WPLayoutBorder',[_c('h6',[_vm._v(_vm._s(_vm.$t('calculators.volTitle')))]),_c('WPButtonMenu',{staticClass:"mt-4",attrs:{"mini":true,"selected":_vm.checkSelectedCalc('ConstructionSpaceVolumes')},on:{"click":function($event){_vm.goTo(
          'ConstructionSpaceVolumes',
          String(_vm.$t('calculators.calcs.volume')),
          1
        )}}},[_vm._v(" "+_vm._s(_vm.$t('calculators.calcs.volume'))+" ")])],1),_c('WPLayoutBorder',{staticClass:"mt-8"},[_c('h6',[_vm._v(_vm._s(_vm.$t('calculators.mechTitle')))]),_c('WPButtonMenu',{staticClass:"mt-4",attrs:{"mini":true,"selected":_vm.checkSelectedCalc('EquipmentStrengthService')},on:{"click":function($event){_vm.goTo(
          'EquipmentStrengthService',
          String(_vm.$t('calculators.calcs.strength')),
          2
        )}}},[_vm._v(" "+_vm._s(_vm.$t('calculators.calcs.strength'))+" ")]),_c('WPButtonMenu',{staticClass:"mt-4",attrs:{"mini":true,"selected":_vm.checkSelectedCalc('CriticalLimits')},on:{"click":function($event){_vm.goTo('CriticalLimits', String(_vm.$t('calculators.calcs.critLimits')), 3)}}},[_vm._v(" "+_vm._s(_vm.$t('calculators.calcs.critLimits'))+" ")]),_c('WPButtonMenu',{staticClass:"mt-4",attrs:{"mini":true,"selected":_vm.checkSelectedCalc('ForecastsPerformance')},on:{"click":function($event){_vm.goTo(
          'ForecastsPerformance',
          String(_vm.$t('calculators.calcs.forecasts')),
          4
        )}}},[_vm._v(" "+_vm._s(_vm.$t('calculators.calcs.forecasts'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  WPLayoutBorder,
  WPButtonMenu,
  WPDivider,
  WPButton,
  WPGroupMultiline,
  WPSelect,
  WPRadioSwitch,
} from '@/components'

@Component({
  components: {
    WPLayoutBorder,
    WPButtonMenu,
    WPDivider,
    WPGroupMultiline,
    WPButton,
    WPSelect,
    WPRadioSwitch,
  },
})
export default class CalculatorsList extends Vue {
  @Prop({ default: '' })
  private selectedCalc?: string

  private checkSelectedCalc(name = '') {
    return this.selectedCalc === name
  }

  private goTo(path = '', name = '', code = 0) {
    this.$emit('goTo', { path, name, code })
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { Auth } from '@/store/auth'
import { User } from '@/store/user'
import { Unit } from '@/store/units'
import {
  WPTextfield,
  WPTextfieldPassword,
  WPButton,
  WPLogo,
  WPButtonPlain,
} from '@/components'
import { ILoginData } from '@/types'
import { Init } from '@/store/init'

@Component({
  components: {
    WPButtonPlain,
    WPLogo,
    WPButton,
    WPTextfieldPassword,
    WPTextfield,
  },
})
export default class Login extends Vue {
  private username = ''

  private password = ''

  private get valid() {
    return this.username && this.password
  }

  private get initOptions() {
    return Init.settings
  }

  private async login(loginDataFromMobile?: ILoginData, route?: string) {
    let loginData: ILoginData = {
      username: this.username,
      password: this.password,
      initLang: this.$i18n.locale,
    }
    if (loginDataFromMobile && route) {
      loginData = loginDataFromMobile
    }

    const correctLogin = await Auth.LOGIN(loginData)

    if (correctLogin) {
      await Promise.all([
        Unit.GET_UNITS(),
        Unit.GET_UNITTYPES(),
        User.GET_SETTINGS(),
        User.GET_USER_DATA(),
      ])
      this.$i18n.locale = User.settings.language
        ? User.settings.language
        : 'ru-ru'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      this.$theme.set(User.settings.theme)

      if (loginDataFromMobile && route) {
        void this.$router.push(route)
        return
      }

      let params = { ...this.$route.query }

      const redirect =
        this.$route.query && this.$route.query.redirect
          ? String(this.$route.query?.redirect)
          : null

      if (redirect) {
        delete params.redirect
        void this.$router.push({ path: redirect, query: params })
      } else {
        void this.$router.push('/')
      }
    }
  }

  private goToRegistration() {
    if (this.initOptions.enable_user_registration)
      void this.$router.push({ name: 'Registration' })
  }

  private resetPassword() {
    void this.$router.push({ name: 'Password' })
  }

  private handleMessage(event: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const message = JSON.parse(event.data)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (message.type === 'login') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        void this.login(message.data, message.route)
      }
    } catch (error) {
      return
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  private async created() {
    window.addEventListener('message', this.handleMessage)
    document.addEventListener('message', this.handleMessage)

    const credentials =
      this.$route.query && this.$route.query.auto_auth
        ? {
            login: String(this.$route.query?.login),
            password: String(this.$route.query?.password),
          }
        : null

    if (credentials) {
      this.username = credentials.login
      this.password = credentials.password
      await this.login()
    }
  }

  private beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
    document.removeEventListener('message', this.handleMessage)
  }
}


import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  WPCard,
  WPButtonMenu,
  WPButtonIcon,
  WPTextfield,
  WPOpacity,
  WPTextInfo,
  WPButton,
  WPBlockHint,
  WPProgressBar,
  WPGroup,
  WPTextfieldMeasure,
  WPBlockInfo,
  WPMiniHeader,
  WPDivider,
  WPTextInfoMini,
} from '@/components'

import { ResultsCalculationElements } from '../results/'

import { RouteParamsMixin } from '@/mixins'

import { Field } from '@/store/field'
import { Calculator } from '@/store/calculators'
import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'

import { eventBus } from '@/helpers/eventBus'

import {
  ICalcStrengthInputDataForm,
  ICalcVolumeInputDataForm,
  TItemWellboreInfo,
  TItemEquipComposition,
  ICheckStructData,
  TBooleanKeys,
  TCheckStructResult,
  IWellEquipmentsetInfo,
} from '@/types'
import { checkStructText } from '@/services/common'

export interface IWindowKeys {
  isEquipOpened: boolean
  isInfoOpened: boolean
  isSettingsOpened: boolean
  isWellboreInfoOpened: boolean
  isVisualOpened: boolean
}

@Component({
  components: {
    WPCard,
    WPButtonMenu,
    WPButtonIcon,
    WPTextfield,
    WPOpacity,
    WPTextInfo,
    WPButton,
    WPBlockHint,
    WPProgressBar,
    WPGroup,
    WPTextfieldMeasure,
    WPBlockInfo,
    WPMiniHeader,
    WPTextInfoMini,
    WPDivider,
    ResultsCalculationElements,
  },
})
export default class CalculatorsLayout extends Mixins(RouteParamsMixin) {
  @Prop({ default: '' })
  private initDataModal?: string

  @Prop({ default: '' })
  private selectedCalc?: string

  @Prop({ default: true })
  private calculatePoss?: boolean

  @Prop({ default: () => ({}) })
  private visualSettings?: TBooleanKeys

  @Prop({ required: true })
  private inputData?: ICalcStrengthInputDataForm | ICalcVolumeInputDataForm

  private isInfoOpened = false

  private isSettingsOpened = false

  private isWellboreInfoOpened = false

  private isVisualOpened = false

  private isEquipOpened = false

  private progress: string | number = 0

  private constructionInfo: TItemWellboreInfo = []

  private equipmentsetInfo: IWellEquipmentsetInfo | null = null

  private equipInfo: TItemEquipComposition = []

  private get params() {
    return this.$route.query
  }

  private get isInitDataOpened() {
    return this.initDataModal !== ''
  }

  private get equip() {
    return Wellbore.equips.find(
      (equip) => equip?.id && equip.id === Number(this.params.equip)
    )
  }

  private open(window: keyof IWindowKeys) {
    if (window) {
      this[window] = true
    }
  }

  private close(window?: keyof IWindowKeys) {
    if (window) {
      this[window] = false
    } else {
      if (
        this.isWellboreInfoOpened ||
        this.isEquipOpened ||
        this.isInitDataOpened
      ) {
        this.isWellboreInfoOpened = false
        this.isEquipOpened = false
        eventBus.$emit('closeInitDataModal')
      } else {
        this.isInfoOpened = false
        this.isSettingsOpened = false
        this.isWellboreInfoOpened = false
        this.isVisualOpened = false
        eventBus.$emit('closeInitDataModal')
      }
    }
  }

  private checkPageName(name = '') {
    return this.$route.name === name
  }

  private goToParams() {
    void this.$router.push({
      name: `CalculatorsSelects`,
      query: { ...this.params, calculator: this.selectedCalc },
    })
  }

  private goToVisualization() {
    let routeData = this.$router.resolve({
      name: 'TheWellboreVisualization',
      params: {
        id: String(this.params.field),
        wellId: String(this.params.well),
        wellboreId: String(this.params.wellbore),
      },
      query: {
        equip: String(this.params.equip),
      },
    })
    window.open(routeData.href, '_blank')
  }

  private cancel() {
    void this.$router.push({
      name: 'Calculators',
    })
  }

  private toFields() {
    void this.$router.push({
      name: 'Fields',
    })
  }

  private editWellbore() {
    let routeData = this.$router.resolve({
      name: 'TheWellbore',
      params: {
        id: String(this.params.field),
        wellId: String(this.params.well),
        wellboreId: String(this.params.wellbore),
      },
      query: {
        calculator: this.selectedCalc,
        equip: String(this.$route.query.equip),
      },
    })
    window.open(routeData.href, '_blank')
  }

  private async checkStruct() {
    let data: ICheckStructData = {
      wellbore: Number(this.params.wellbore),
      equip: Number(this.params.equip),
    }
    const result = await Wellbore.CHECK_STRUCT(data)
    const text = checkStructText(
      result as TCheckStructResult,
      String(this.$t('wellbore.warningText')),
      String(this.$t('wellbore.errorText')),
      String(this.$t('wellbore.criticalText'))
    )
    if (result && result.length) {
      eventBus.$emit('showDialog', {
        title: this.$t('wellbore.dialogCheckTitle'),
        text: text,
        btnDismissText: this.$t('calculators.goToCalc'),
        btnAcceptText: this.$t('calculators.goToConstructor'),
      })
    }
  }

  private async calculate() {
    if (this.inputData) {
      this.progress = 0
      let id: number | undefined = undefined
      switch (this.selectedCalc) {
        case 'ConstructionSpaceVolumes':
          const data = this.inputData as ICalcVolumeInputDataForm
          const upInt = await Unit.CONVERSE_UNITS({
            sourceVal: data.up_interval,
            sourceUnit: data.up_interval_uc,
            destUnit: 100,
          })
          const downInt = await Unit.CONVERSE_UNITS({
            sourceVal: data.down_interval,
            sourceUnit: data.down_interval_uc,
            destUnit: 100,
          })
          if (upInt !== null && downInt !== null && upInt <= downInt) {
            id = await Calculator.CALCULATE_VOLUME({
              params: this.params,
              inputData: this.inputData as ICalcVolumeInputDataForm,
            })
          } else {
            eventBus.$emit('showError', 'calcVolumeIntervalError')
            this.open('isSettingsOpened')
            this.$emit('initDataError')
            return
          }
          break
        case 'EquipmentStrengthService':
          id = await Calculator.CALCULATE_STRENGTH({
            params: this.params,
            inputData: this.inputData as ICalcStrengthInputDataForm,
            settings: this.visualSettings || {},
          })
          break
      }
      if (id) {
        void Calculator.RELOAD_CALCULATIONS()
        void this.$router.push({
          name: 'ResultsCalculation',
          params: {
            calcId: String(id),
          },
        })
      }
    }
  }

  private async onOpenInfo() {
    this.equipmentsetInfo = await Calculator.GET_EQUIPMENTSET_INFO({
      wellbore: Number(this.params.wellbore),
      equip: Number(this.params.equip),
    })
    this.open('isInfoOpened')
  }

  private async onOpenWellboreInfo() {
    this.constructionInfo = await Calculator.GET_CONSTRUCTION_INFO(
      Number(this.params.wellbore)
    )
    this.open('isWellboreInfoOpened')
  }

  private async onOpenEquipInfo() {
    this.equipInfo = await Calculator.GET_EQUIPMENT_INFO(
      Number(this.params.equip)
    )
    this.open('isEquipOpened')
  }

  private async mounted() {
    if (
      !(
        this.params.field &&
        this.params.well &&
        this.params.wellbore &&
        this.params.equip
      )
    ) {
      void this.$router.push({
        name: 'Calculators',
      })
    } else {
      eventBus.$on('dialogClick', () => this.editWellbore())
      await Promise.all([
        Field.GET_FIELDS(),
        Field.GET_WELLS({ fieldId: Number(this.params.field) }),
        Field.GET_WELLBORES({ wellId: Number(this.params.well) }),
        Wellbore.GET_EQUIPS({ wellboreId: Number(this.params.wellbore) }),
        Calculator.GET_CALCULATORS(),
      ])
      this.constructionInfo = await Calculator.GET_CONSTRUCTION_INFO(
        Number(this.params.wellbore)
      )
      this.equipmentsetInfo = await Calculator.GET_EQUIPMENTSET_INFO({
        wellbore: Number(this.params.wellbore),
        equip: Number(this.params.equip),
      })
      this.equipInfo = await Calculator.GET_EQUIPMENT_INFO(
        Number(this.params.equip)
      )
      await this.checkStruct()
    }
  }

  private beforeDestroy() {
    eventBus.$off('dialogClick')
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseButton from './base/WPBaseButton.vue'

@Component({
  components: { WPBaseButton },
})
export default class WPDroppableArea extends Vue {
  @Prop({ default: '', required: true })
  private namespace?: string

  @Prop({ default: false })
  private doubleHeight?: boolean

  @Prop({ default: '' })
  private height?: string

  private isDragOver = false

  private get slots() {
    return this.$slots.default
  }

  private onDragOver($event: MouseEvent) {
    this.isDragOver = true
  }

  private onDragLeave($event: MouseEvent) {
    this.isDragOver = false
  }

  private onDragEnd($event: MouseEvent) {
    this.$emit('drag-drop', $event)
  }
}


import { Component, Mixins, Prop } from 'vue-property-decorator'
import WPBaseTextfield from './base/WPBaseTextfield.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseTextfield },
})
export default class WPTextfield extends Mixins(ValidationMixin) {
  @Prop({ default: '' })
  public readonly value?: string | number

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly type?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: '' })
  private readonly suffix?: string

  @Prop({ default: false })
  private readonly clearable?: boolean

  @Prop({ default: false })
  private readonly flat?: boolean

  @Prop({ default: false })
  private readonly mini?: boolean

  private onBlur(value: string | number) {
    if (this.value === '') {
      if (this.type === 'number') {
        if (this.required) this.$emit('input', 0)
        else this.$emit('input', null)
      } else this.$emit('input', '')
      return
    }
    if (value === null && !this.required) {
      this.$emit('input', null)
      return
    }
    if (this.type === 'number') {
      this.$emit('input', Number(value))
      return
    }
    this.$emit('input', value)
  }

  private onInput(value: string | number) {
    this.$emit('input', value)
  }
}

import { eventBus, getError } from '@/helpers/eventBus'

import axiosInstance from '@/services/api'
import {
  TProfilePoints,
  IProfilePoint,
  TIntervalTypes,
  TIntervals,
  IInterval,
  TPipeTypes,
  IElement,
  TElementTypes,
  IElementType,
  TElements,
  IArea,
  TAreas,
  IEquip,
  TEquips,
  TEquipElementTypes,
  IEquipElementType,
  TEquipElements,
  IEquipElement,
  TEquipAreas,
  IEquipArea,
  TCarvingTypes,
  TLockTypes,
  TCustomConnectionTypes,
  TSteelGrades,
  ISteelGrade,
  IEquipConnection,
  TProfileShapes,
  IIntervalType,
  IFileUpload,
  ICustomConnectionType,
  ICheckStructData,
  IPagedData,
  IPipeType,
  IWellboreValidation,
  IActivationData,
  IWellboreIntegrationTypes,
  IWellboreByStatus,
  IWellbore,
  IActivateTransferWellbore,
  ILockType,
  ICarvingType,
  IProfileShape,
  TVariableStringConnectionTypes,
  TProfileGraphData,
  TProfilePointStatuses,
  TCheckStructResult,
  ICheckInterval,
  IProfileCheckStructData,
  ITransferInclinometryData,
  IError,
  IMoveToData,
} from '@/types'

import { DataMock } from '@/helpers/consts'
import { Dictionary } from 'vue-router/types/router'

export async function getProfilePointsByWellboreId(
  wellboreId: number,
  page: number | null
): Promise<IPagedData<TProfilePoints>> {
  try {
    const response = await axiosInstance.get<IPagedData<TProfilePoints>>(
      `/profilepoints/`,
      {
        params: { wellbore: wellboreId, page },
      }
    )
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function deleteProfilePoint(pointId: number) {
  try {
    const response = await axiosInstance.delete<any>(
      `/profilepoints/${pointId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `pointDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addProfilePoint(point: IProfilePoint) {
  try {
    const response = await axiosInstance.post<IProfilePoint>(
      `/profilepoints/`,
      point
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `pointCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function clearProfilePoints(id: number) {
  try {
    const response = await axiosInstance.delete<IProfilePoint>(
      `/profilepoints_by_owner/${id}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `pointCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveProfilePoint(point: IProfilePoint) {
  try {
    const id = String(point.id)
    delete point.id
    const response = await axiosInstance.put<IProfilePoint>(
      `/profilepoints/${id}/`,
      point
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `pointSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getProfilePointsFile(
  smart = false
): Promise<Blob | null> {
  try {
    const response = await axiosInstance.get<Blob>(
      `/profilepoints/import_xls/?smart=${String(smart)}`,
      { responseType: 'blob' }
    )
    if (response) {
      return response?.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function uploadProfilePointsFile(
  data: IFileUpload
): Promise<boolean> {
  try {
    const formData = new FormData()
    formData.append('file', data.file)
    const response = await axiosInstance.put(
      `/profilepoints/import_xls/${String(data.wellbore)}/?clear=${String(
        data.clear
      )}`,
      formData
    )
    if (response) {
      return true
    }

    return false
  } catch (error: IError | unknown) {
    const { response } = error as IError
    if (response.status === 415) {
      eventBus.$emit('showError', 'fileUploadError')
    } else eventBus.$emit('showError', getError(error))
    return false
  }
}

export async function getIntervalTypes(
  page: number | null
): Promise<IPagedData<TIntervalTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TIntervalTypes>>(
      '/intervaltypes/',
      {
        params: { page_size: -1 },
      }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addIntervalType(name: string) {
  try {
    const response = await axiosInstance.post<IIntervalType>(
      `/intervaltypes/`,
      {
        name,
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addInterval(interval: IInterval) {
  try {
    const response = await axiosInstance.post<IInterval>(
      `/intervals/`,
      interval
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `intervalCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getIntervals(
  wellboreId: number,
  page: number | null
): Promise<IPagedData<TIntervals>> {
  try {
    const response = await axiosInstance.get<IPagedData<TIntervals>>(
      '/intervals/',
      {
        params: { wellbore: wellboreId, page },
      }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function saveInterval(interval: IInterval) {
  try {
    const id = String(interval.id)
    delete interval.id
    if (typeof interval.intervaltype === 'object') {
      interval.intervaltype = Number(interval.intervaltype?.id)
    }
    if (typeof interval.pipetype === 'object') {
      interval.pipetype = Number(interval.pipetype?.id)
    }
    const response = await axiosInstance.put<IInterval>(
      `/intervals/${id}/`,
      interval
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `intervalSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteInterval(intId: number) {
  try {
    const response = await axiosInstance.delete<any>(`/intervals/${intId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `intervalDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getPipeTypes(
  page: number | null
): Promise<IPagedData<TPipeTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TPipeTypes>>(
      '/pipetypes/',
      { params: { page_size: -1 } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addPipeType(name: string) {
  try {
    const response = await axiosInstance.post<IPipeType>(`/pipetypes/`, {
      name,
    })
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getElementTypes(
  page: number | null
): Promise<IPagedData<TElementTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TElementTypes>>(
      '/elementtypes/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addElementType(name: string) {
  try {
    const response = await axiosInstance.post<IElementType>('/elementtypes/', {
      name,
    })

    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addElement(element: IElement) {
  try {
    const data = { ...element }
    if (typeof data.elementtype === 'object') {
      data.elementtype = Number(data.elementtype?.id)
    }

    if (data.inner_diameter === null) delete data.inner_diameter
    if (data.wall_thickness === null) delete data.wall_thickness

    const response = await axiosInstance.post<IElement>(`/elements/`, data)
    if (response?.data) {
      eventBus.$emit('showAlert', `elementCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getElement(elementId: number) {
  try {
    const response = await axiosInstance.get<IElement>(`/elements/${elementId}`)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getElements(intervalId: number): Promise<TElements> {
  try {
    const response = await axiosInstance.get<IPagedData<TElements>>(
      '/elements/',
      {
        params: { interval: intervalId, page_size: -1 },
      }
    )
    if (response?.data.results) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function saveElement(element: IElement) {
  try {
    const id = String(element.id)
    delete element.id
    const data = { ...element }
    if (typeof data.elementtype === 'object') {
      data.elementtype = Number(data.elementtype?.id)
    }
    if (typeof data.interval === 'object') {
      data.interval = Number(data.interval?.id)
    }

    if (data.inner_diameter === null) delete data.inner_diameter
    if (data.wall_thickness === null) delete data.wall_thickness

    const response = await axiosInstance.put<IElement>(`/elements/${id}/`, data)
    if (response?.data) {
      eventBus.$emit('showAlert', `elementSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteElement(elementId: number) {
  try {
    const response = await axiosInstance.delete<any>(`/elements/${elementId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `elementDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getAreas(elementId: number): Promise<TAreas> {
  try {
    const response = await axiosInstance.get<IPagedData<TAreas>>('/areas/', {
      params: { element: elementId, page_size: -1 },
    })
    if (response?.data.results) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function addArea(area: IArea) {
  try {
    const response = await axiosInstance.post<IArea>(`/areas/`, area)
    if (response?.data) {
      eventBus.$emit('showAlert', `areaCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveArea(area: IArea) {
  try {
    const id = String(area.id)
    delete area.id
    if (typeof area.element === 'object') {
      area.element = Number(area.element?.id)
    }
    const response = await axiosInstance.put<IArea>(`/areas/${id}/`, area)
    if (response?.data) {
      eventBus.$emit('showAlert', `areaSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteArea(areaId: number) {
  try {
    const response = await axiosInstance.delete<any>(`/areas/${areaId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `areaDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getEquips(
  wellboreId: number,
  page: number | null,
  query?: Dictionary<string>
): Promise<IPagedData<TEquips>> {
  try {
    const response = await axiosInstance.get<IPagedData<TEquips>>(
      '/equipmentset/',
      {
        params: { wellbore: wellboreId, page, ...query },
      }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getEquip(id: number): Promise<IEquip | null> {
  try {
    const response = await axiosInstance.get<IEquip | null>(
      `/equipmentset/${id}`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addEquip(equip: IEquip) {
  try {
    const response = await axiosInstance.post<IEquip>(`/equipmentset/`, equip)
    if (response?.data) {
      eventBus.$emit('showAlert', `equipCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveEquip(equip: IEquip) {
  try {
    const id = String(equip.id)
    delete equip.id
    if (typeof equip.wellbore === 'object') {
      equip.wellbore = Number(equip.wellbore?.id)
    }
    const response = await axiosInstance.put<IEquip>(
      `/equipmentset/${id}/`,
      equip
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `equipSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteEquip(equipId: number) {
  try {
    const response = await axiosInstance.delete<any>(
      `/equipmentset/${equipId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `equipDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function cloneEquip(equipId: number) {
  try {
    const response = await axiosInstance.post<IEquip>(
      `/equipmentset/clone/${equipId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `equipClone`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getEquipElementTypes(
  page: number | null
): Promise<IPagedData<TEquipElementTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TEquipElementTypes>>(
      '/equipeelementtypes/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addEquipElementType(elemType: IEquipElementType) {
  try {
    const response = await axiosInstance.post<IEquipElementType>(
      '/equipeelementtypes/',
      elemType
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveEquipElementType(type: IEquipElementType) {
  const id = String(type.id)
  delete type.id
  delete type.user
  delete type.code

  try {
    const response = await axiosInstance.patch<IEquipElementType>(
      `/equipeelementtypes/${id}/`,
      type
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getEquipElements(
  equipId: number
): Promise<TEquipElements> {
  try {
    const response = await axiosInstance.get<IPagedData<TEquipElements>>(
      '/equipeelements/',
      {
        params: { equipment_set: equipId },
      }
    )
    if (response?.data.results) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function addEquipElement(element: IEquipElement) {
  try {
    const data = { ...element }
    if (typeof data.equipe_elementtype === 'object') {
      data.equipe_elementtype = Number(data.equipe_elementtype?.id)
    }

    if (data.inner_diameter === null) delete data.inner_diameter
    if (data.wall_thickness === null) delete data.wall_thickness
    if (data.max_axial_stretching === null) delete data.max_axial_stretching
    if (data.max_axial_compression === null) delete data.max_axial_compression
    if (data.max_torque === null) delete data.max_torque

    const response = await axiosInstance.post<IEquipElement>(
      `/equipeelements/`,
      data
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `elementCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveEquipElement(element: IEquipElement) {
  try {
    const id = String(element.id)
    delete element.id
    const data = { ...element }
    if (typeof data.equipe_elementtype === 'object') {
      data.equipe_elementtype = Number(data.equipe_elementtype?.id)
    }
    if (typeof data.equipment_set === 'object') {
      data.equipment_set = Number(data.equipment_set?.id)
    }

    if (data.inner_diameter === null) delete data.inner_diameter
    if (data.wall_thickness === null) delete data.wall_thickness
    if (data.max_axial_stretching === null) delete data.max_axial_stretching
    if (data.max_axial_compression === null) delete data.max_axial_compression
    if (data.max_torque === null) delete data.max_torque

    const response = await axiosInstance.put<IEquipElement>(
      `/equipeelements/${id}/`,
      data
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `elementSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteEquipElement(elementId: number) {
  try {
    const response = await axiosInstance.delete<any>(
      `/equipeelements/${elementId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `elementDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getEquipAreas(elementId: number): Promise<TEquipAreas> {
  try {
    const response = await axiosInstance.get<IPagedData<TEquipAreas>>(
      '/equipeareas/',
      {
        params: { equipe_element: elementId },
      }
    )
    if (response?.data.results) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function addEquipArea(area: IEquipArea) {
  try {
    const response = await axiosInstance.post<IEquipArea>(`/equipeareas/`, area)
    if (response?.data) {
      eventBus.$emit('showAlert', `areaCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveEquipArea(area: IEquipArea) {
  try {
    const id = String(area.id)
    delete area.id
    if (typeof area.equipe_element === 'object') {
      area.equipe_element = Number(area.equipe_element?.id)
    }
    const response = await axiosInstance.put<IEquipArea>(
      `/equipeareas/${id}/`,
      area
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `areaSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteEquipArea(areaId: number) {
  try {
    const response = await axiosInstance.delete<any>(`/equipeareas/${areaId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `areaDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getSteelGrades(
  page: number | null
): Promise<IPagedData<TSteelGrades>> {
  try {
    const response = await axiosInstance.get<IPagedData<TSteelGrades>>(
      '/steelgrades/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addSteelGrade(steelgrade: ISteelGrade) {
  try {
    const response = await axiosInstance.post<ISteelGrade>(
      `/steelgrades/`,
      steelgrade
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveSteelGrade(steelgrade: ISteelGrade) {
  try {
    const id = String(steelgrade.id)

    const response = await axiosInstance.patch<ISteelGrade>(
      `/steelgrades/${id}/`,
      steelgrade
    )
    if (response?.data) {
      return response.data
    }
    return
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return
  }
}

export async function getWellboreVisualizaion(
  wellboreId: number,
  equipId: number | null
): Promise<Blob | undefined> {
  try {
    const params = equipId ? { equipment_set: equipId } : null
    const response = await axiosInstance.get<Blob>(
      `/visualization/wellbore/${wellboreId}/`,
      {
        params,
      }
    )
    if (response?.data) {
      return response.data
    }
    return
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return
  }
}

export async function getCarvingTypes(
  page?: number | null
): Promise<IPagedData<TCarvingTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TCarvingTypes>>(
      '/carvingtypes/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addCarvingConnectionType(item: ICarvingType) {
  try {
    const result =
      typeof item.profile_shape === 'object'
        ? {
            ...item,
            profile_shape: item.profile_shape?.id,
          }
        : item
    const response = await axiosInstance.post<ICarvingType>(
      `/carvingtypes/`,
      result
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveCarvingConnectionType(item: ICarvingType) {
  try {
    const result =
      typeof item.profile_shape === 'object'
        ? {
            ...item,
            profile_shape: item.profile_shape?.id,
          }
        : item
    const response = await axiosInstance.patch<ICarvingType>(
      `/carvingtypes/${String(item.id)}/`,
      result
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getLockTypes(
  page?: number | null
): Promise<IPagedData<TLockTypes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TLockTypes>>(
      '/locktypes/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addLockConnectionType(item: ILockType) {
  try {
    const result =
      typeof item.carving === 'object'
        ? {
            ...item,
            carving: item.carving?.id,
          }
        : item
    const response = await axiosInstance.post<ILockType>(`/locktypes/`, result)
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveLockConnectionType(item: ILockType) {
  try {
    const result =
      typeof item.carving === 'object'
        ? {
            ...item,
            carving: item.carving?.id,
          }
        : item
    const response = await axiosInstance.patch<ILockType>(
      `/locktypes/${String(item.id)}/`,
      result
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getCustomConnectionTypes(
  page?: number | null
): Promise<IPagedData<TCustomConnectionTypes>> {
  try {
    const response = await axiosInstance.get<
      IPagedData<TCustomConnectionTypes>
    >('/customconnectiontypes/', { params: { page } })
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addCustomConnectionType(item: ICustomConnectionType) {
  try {
    const response = await axiosInstance.post<ICustomConnectionType>(
      `/customconnectiontypes/`,
      item
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveCustomConnectionType(item: ICustomConnectionType) {
  try {
    const response = await axiosInstance.patch<ICustomConnectionType>(
      `/customconnectiontypes/${String(item.id)}/`,
      item
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getProfileShapes(
  page?: number | null
): Promise<IPagedData<TProfileShapes>> {
  try {
    const response = await axiosInstance.get<IPagedData<TProfileShapes>>(
      '/profileshapes/',
      { params: { page } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getProfilePointStatuses(): Promise<
  IPagedData<TProfilePointStatuses>
> {
  try {
    const response = await axiosInstance.get<IPagedData<TProfilePointStatuses>>(
      '/profilepoints/statuses/',
      { params: { page_size: -1 } }
    )
    if (response?.data) {
      return response.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addProfileShape(name: string) {
  try {
    const response = await axiosInstance.post<IProfileShape>(
      `/profileshapes/`,
      { name: name }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getEquipElementConnection(
  elementId: number
): Promise<IEquipConnection | null> {
  try {
    const response = await axiosInstance.get<IEquipConnection>(
      `/equipeelementconnections/${elementId}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addEquipElementConnection(connection: IEquipConnection) {
  try {
    const response = await axiosInstance.post<IEquipConnection>(
      `/equipeelementconnections/`,
      connection
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `connectionCreate`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveEquipElementConnection(connection: IEquipConnection) {
  try {
    const id = String(connection.equipe_element)

    const result = connection
    const variableString: TVariableStringConnectionTypes = [
      'carving_type_full',
      'carving_type_up',
      'carving_type_down',
      'lock_type_full',
      'lock_type_up',
      'lock_type_down',
      'custom_type_full',
      'custom_type_up',
      'custom_type_down',
    ]
    variableString.map((item) => {
      if (typeof result[item] === 'string') result[item] = null
    })

    const response = await axiosInstance.patch<IEquipConnection>(
      `/equipeelementconnections/${id}/`,
      result
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `connectionSave`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteEquipElementConnection(equipElementId: number) {
  try {
    const response = await axiosInstance.delete<any>(
      `/equipeelementconnections/${equipElementId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `connectionDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function checkStruct(data: ICheckStructData) {
  try {
    const response = await axiosInstance.post<TCheckStructResult>(
      `/check_struct/wellbore/${data.wellbore}/`,
      null,
      {
        params: { equipment_set: data.equip },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function checkProfile(data: IProfileCheckStructData) {
  try {
    const response = await axiosInstance.post<TCheckStructResult>(
      `/profilepoints/check_struct/${data.wellbore}/`,
      null,
      {
        params: {
          up: data.up,
          up_uc: data.up_uc,
          down: data.down,
          down_uc: data.down_uc,
        },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function checkInterval(id: number, elementId?: number) {
  try {
    const response = await axiosInstance.get<ICheckInterval>(
      `/intervals/validate_info/${id}/`,
      {
        params: { ignore_element_id: elementId },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function checkEquip(id: number, elementId?: number) {
  try {
    const response = await axiosInstance.get<ICheckInterval>(
      `/equipmentset/validate_info/${id}/`,
      {
        params: { ignore_element_id: elementId },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function checkFullStruct(data: ICheckStructData) {
  try {
    const response = await axiosInstance.post<TCheckStructResult>(
      `/wellbores/check_struct/${data.wellbore}/`,
      null,
      {
        params: { equipment_set: data.equip },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function activateIntegration(data: IActivationData) {
  try {
    const response = await axiosInstance.post<IWellboreIntegrationTypes>(
      `/wellbores/activate_integration/${data.wellboreId}/`,
      null,
      {
        params: { current_depth: data.depth, current_depth_uc: data.depthUc },
      }
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deactivateIntegration(id: number | string) {
  try {
    const response = await axiosInstance.post<IWellbore | null>(
      `/wellbores/deactivate_integration/${id}/`
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellboreValidateInfo(
  wellboreId: number
): Promise<IWellboreValidation | null> {
  try {
    const response = await axiosInstance.get<IWellboreValidation>(
      `/wellbores/validate_info/${wellboreId}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellboreByStatus(data: IWellboreByStatus) {
  try {
    const response = await axiosInstance.post<IWellbore>(
      `/wellbores/by_status/`,
      data
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellboreActivationInfo(wellboreId: string) {
  try {
    const response = await axiosInstance.post<string>(
      `/wellbores/integration_info/${wellboreId}/`
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function activateWellboreTransfer(
  data: IActivateTransferWellbore
) {
  try {
    const id = String(data.id)
    const response = await axiosInstance.post<IWellbore>(
      `/wellbores/toggle_data_transfer/${id}/`,
      null,
      {
        params: { data_transfer: data.data_transfer },
      }
    )
    eventBus.$emit(
      'showAlert',
      data.data_transfer
        ? `wellboreTransferOn | ${response.data.name}`
        : `wellboreTransferOff | ${response.data.name}`
    )

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getSteelgradeById(
  id: number
): Promise<ISteelGrade | null> {
  try {
    const response = await axiosInstance.get<ISteelGrade>(`/steelgrades/${id}/`)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getLockTypeById(id: number): Promise<ILockType | null> {
  try {
    const response = await axiosInstance.get<ILockType>(`/locktypes/${id}/`)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getCarvingTypeById(
  id: number
): Promise<ICarvingType | null> {
  try {
    const response = await axiosInstance.get<ICarvingType>(
      `/carvingtypes/${id}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getCustomTypeById(
  id: number
): Promise<ICustomConnectionType | null> {
  try {
    const response = await axiosInstance.get<ICustomConnectionType>(
      `/customconnectiontypes/${id}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getProfileShapeById(
  id: number
): Promise<IProfileShape | null> {
  try {
    const response = await axiosInstance.get<IProfileShape>(
      `/profileshapes/${id}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getProfileGraph(
  id: number
): Promise<TProfileGraphData | null> {
  try {
    const response = await axiosInstance.get<TProfileGraphData>(
      `/profilepoints/chart/${id}/`
    )
    if (response?.data) {
      return response.data
    }
    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function normalizeInclinometry(id: number) {
  try {
    await axiosInstance.post<boolean>(`/profilepoints/post_process/${id}/`)
    return true
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function transferInclinometry(data: ITransferInclinometryData) {
  try {
    const response = await axiosInstance.post<boolean>(
      `/profilepoints/copy/${data.id}/${data.destId}/`
    )
    if (response?.data) {
      return response.data
    }
    return true
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function addProfilePointIntegration(point: IProfilePoint) {
  try {
    const response = await axiosInstance.post<IProfilePoint>(
      `/profilepoints/smart/`,
      point
    )
    if (response?.status === 200) {
      eventBus.$emit('showAlert', `pointCreateIntegration`)
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function uploadProfilePointsFileIntegration(
  data: IFileUpload
): Promise<boolean> {
  try {
    const formData = new FormData()
    formData.append('file', data.file)
    const response = await axiosInstance.put(
      `/profilepoints/import_xls/${String(data.wellbore)}/?clear=${String(
        data.clear
      )}&smart=true`,
      formData
    )
    if (response) {
      return true
    }

    return false
  } catch (error: unknown) {
    const { response } = error as IError
    if (response.status === 415) {
      eventBus.$emit('showError', 'fileUploadError')
    } else eventBus.$emit('showError', getError(error))
    return false
  }
}

export async function checkIntegration(wellbore: number) {
  try {
    const response = await axiosInstance.post(
      `/wellbores/activate_integration_enabled/${wellbore}/`
    )
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function arrangeIntervals(wellbore: number) {
  try {
    const response = await axiosInstance.post(`/intervals/reorder/${wellbore}/`)
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function arrangeIntervalsElements(interval: number) {
  try {
    const response = await axiosInstance.post(`/elements/reorder/${interval}/`)
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function arrangeIntervalsElementsAreas(element: number) {
  try {
    const response = await axiosInstance.post(`/areas/reorder/${element}/`)
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function arrangeEquipElements(equip: number) {
  try {
    const response = await axiosInstance.post(
      `/equipeelements/reorder/${equip}/`
    )
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function arrangeEquipElementsAreas(equipelement: number) {
  try {
    const response = await axiosInstance.post(
      `/equipeareas/reorder/${equipelement}/`
    )
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function moveToIntervals(data: IMoveToData) {
  try {
    const response = await axiosInstance.post(
      `/intervals/move_to/${data.id}/${data.number}/`
    )
    if (response?.status === 200) {
      return true
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}


import { Component, Vue } from 'vue-property-decorator'
import {
  WPTextfield,
  WPTextfieldPassword,
  WPButton,
  WPCard,
  WPDivider,
  WPLogo,
} from '@/components'

import { Auth } from '@/store/auth'

@Component({
  components: {
    WPLogo,
    WPButton,
    WPTextfieldPassword,
    WPTextfield,
    WPDivider,
    WPCard,
  },
})
export default class PasswordChange extends Vue {
  private passwordConfirm = ''

  private password = ''

  private get valid() {
    return (
      this.password &&
      this.passwordConfirm &&
      this.password === this.passwordConfirm
    )
  }

  private async send() {
    const result = await Auth.RESET_PASSWORD_CONFIRM({
      new_password: this.password,
      uid: '',
      token: '',
      initLang: this.$i18n.locale,
    })
    if (result !== null) {
      this.back()
    }
  }

  private back() {
    void this.$router.push({ name: 'Login' })
  }
}

export const en = {
  common: {
    enterName: 'Enter a name',
    enterValue: 'Enter a value',
    enterDate: 'Select a date',
    enterSearch: 'Enter a text',
    search: 'Search',
    ok: 'OK',
    addButton: 'Add',
    cancelButton: 'Cancel',
    sendButton: 'Send',
    back: 'Close',
    config: 'Settings',
    logout: 'Logout',
    confirm: 'Apply',
    save: 'Save',
    up: 'Up',
    down: 'Down',
    continue: 'Continue',
    apply: 'Confirm',
    notSelected: 'Not chosen',
    support: 'Support',
    password: 'Password',
    yes: 'Yes',
    no: 'No',
  },

  dict: {
    field: 'Field',
    well: 'Well',
    wellbore: 'Wellbore',
    wellboreType: 'Wellbore type',
    wellboreFull: 'Wellbore',
    equip: 'Bottomhole assembly',
    equipFull: 'Bottomhole assembly',
    interval: 'Interval',
    cluster: 'Cluster',
  },

  errorPage: {
    title: 'Error',
    link: 'Link is invalid',
    back: 'Go back to the main page',
  },

  permissionPage: {
    title: 'Error',
    link: 'You do not have permission to view this page',
    back: 'Go back to the main page',
  },

  components: {
    alertError: 'Error code',
    breadcrumbs: {
      calc: 'Simulation and calculation',
      calcResult: 'Calculation result',
      settings: 'Settings',
      params: 'System specifications',
      support: 'Support',
      report: 'Report a problem',
    },
    selectEmpty: 'There is no data',
    color: 'Select colour',
    datetime: 'Select date and time',
    datetimeLabel: 'Date and time',
  },

  hints: {
    altitude: 'Altitude - elevation above sea level',
    declination:
      'Angle between geographical and magnetic azimuths at the wellhead',
    intervalChange:
      'Modifying the setting interval after creating bottomhole assembly elements is not recommended. If necessary, be sure to check the elements length after changing the interval!',
    initData: 'Check that initial data have been filled in before calculating!',
    techOperation:
      'When resistance factor is set automatically, the assembly running velocity takes the value of 1.15 for POOH, 0.85 for RIH process',
    fortress:
      'Equivalent measure of rock hardness for any drillable material. In the case of rocks, the following values apply: 7.8 – for soft rocks, 6.9 – for medium-hard rocks, 5.5 – for hard rocks',
    calcStep: 'Distance between the calculation points',
    repeat:
      'The element may be consecutively repeated within a pre-set well interval',
    complexity:
      'Save the element and click Edit to transform the element into a compound one. A compound element allows the user to create a custom element with any geometric properties',
    innerDiameterOrThickness:
      'The inner diameter/wall thickness for certain types of elements is specified conventionally (e.g. for drill bit, downhole motor, etc.)',
    interval:
      'Interval is a well section drilled with a bit of the same diameter',
    coef: 'The ratio can range from 0.1 to 0.5',
    cavernosity:
      'Cavernosity factor shows how many times the volume or area of the wellbore increases vs. the wellbore corresponding to the bit diameter',
    changeInterval:
      'Modifying the string setting interval after creating string elements is not recommended. If necessary, be sure to check the elements length after changing the interval!',
    diffConnection:
      '“For the whole assembly” – the calculation of connections is performed inside the element with a calculation step. The connections are one-type and have same parameters.\n“For the element ends only” – the calculation is performed for two connections at the opposite ends of the element. The connection type and parameters are set for each element separately',
    customConnection:
      'Custom connection type with specified allowable tensile force and torque',
    disembarkation:
      'If using an upset pipe, strength calculation is performed based on the pipe body',
    limitEndurance:
      'The highest cyclic stress value at which the material can be operated for an unlimited period of time without fracture',
    round:
      'Roundoff precision is a number of relevant decimal places represented as the result of a calculation. For example: if roundoff precision is 4, then the value 10.3464678009879 will be rounded to 10.3465. The value 0.0000645763 will be rounded to 0.00006458',
    drillingConditions:
      'When set automatically, safety factor value is impacted by operating conditions.  The operating conditions are considered normal if the complications are rare or absent. The operating conditions are considered complicated if the complications are possible in every well drilled in this area',
    shared:
      'If you want to share access to the calculation results with other users, click here. To remove the access, click one more time and the field “Share access” will become inactive',
    gridDeclination:
      'The angle at the given point between its meridian and the line parallel to the abscissa axis or the axial meridian',
    wellboreProfile: `<div>
      <div class="the-wellbore-profile__hint"><span class="the-wellbore-profile__hint-point the-wellbore-profile__hint-predict"></span> Prediction</div>
      <div class="the-wellbore-profile__hint"><i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light the-wellbore-profile__hint-icon" style="font-size: 14px; color: #0094D9;"></i> Acceptable</div> 
      <div class="the-wellbore-profile__hint"><span class="the-wellbore-profile__hint-point the-wellbore-profile__hint-fact"></span> Actual</div>
      <div class="the-wellbore-profile__hint"><i aria-hidden="true" class="v-icon notranslate mdi mdi-alert theme--light the-wellbore-profile__hint-icon" style="font-size: 14px; color: red;"></i> Doubtful</div>
      <div class="the-wellbore-profile__hint"><i aria-hidden="true" class="v-icon notranslate mdi mdi-alert theme--light the-wellbore-profile__hint-icon" style="font-size: 14px; color: orange;"></i> Trajectory misalignment</div>
    </div>`,
    intervalNumber:
      'Enter the sequence number of the interval in which the assembly will be used',
    integrationButton:
      'It is possible to enable or disable data transmission from the actual wellbore only',
  },

  menu: {
    fields: 'Fields',
    wells: 'Wells',
    calcs: 'Simulation and calculations',
    results: 'Calculation results',
  },

  alert: {
    errors: {
      fail: 'The system is under maintenance and currently unstable. We apologize for any inconvenience',
      length:
        'Error saving element: total elements length cannot exceed {msg} m',
      calcReportNotSupported:
        'Report creation is not yet available for this type of calculator',
      calcVolumeIntervalError:
        'The upper interval cannot be greater than the lower one!',
      pipeIntervalError:
        'The upper interval of the pipe cannot be greater than the lower one!',
      lengthIntervalError:
        'The upper interval cannot be greater than the lower one',
      pipeCementingIntervalError:
        'The upper cementing interval cannot be greater than the lower one!',
      areasLength:
        'Error saving section - total sections length exceeds element length! Max. section length: {msg} m',
      noPermission: "You don't have access to: {msg}",
      fileUploadError:
        '<b>Error code 415</b> – Unsupported media type in request',
    },
    texts: {
      resetPass:
        'Password recovery request has been created! Please check your email',
      newPass: 'Password has been successfully changed! Use it to log in',
      saved: 'Data has been successfully saved!',
      profileSaved: 'Changes have been saved!',
      support:
        'Your request has been successfully submitted! Please wait for a reply to your email',
      field: 'Field "{msg}" successfully created!',
      fieldSave: 'Field "{msg}" successfully saved!',
      fieldDelete: 'Field "{msg}" successfully removed!',
      well: 'Well "{msg}" successfully created!',
      wellSave: 'Well "{msg}" successfully saved!',
      wellDelete: 'Well "{msg}" successfully removed!',
      wellbore: 'Wellbore "{msg}" successfully created!',
      wellboreSaved: 'Wellbore "{msg}" successfully saved!',
      wellboreDelete: 'Wellbore successfully removed!',
      wellboreClone: 'Wellbore successfully cloned!',
      wellboreTransferOn: 'Data transfer successfully activated!',
      wellboreTransferOff: 'Data transfer successfully deactivated!',
      pointDelete: 'Profile point successfully removed!',
      pointCreate: 'Profile point successfully created!',
      pointCreateIntegration:
        'The request to add a profile point has been successfully sent!',
      pointSave: 'Profile point successfully saved!',
      intervalCreate: 'Interval successfully created!',
      intervalSave: 'Interval successfully saved!',
      intervalDelete: 'Interval successfully removed!',
      elementCreate: 'Element successfully created!',
      elementSave: 'Element successfully saved!',
      elementDelete: 'Element successfully removed!',
      areaCreate: 'Element section successfully created!',
      areaSave: 'Element section successfully saved!',
      areaDelete: 'Element section successfully removed!',
      equipCreate: 'Bottomhole assembly successfully created!',
      equipSave: 'Bottomhole assembly successfully saved!',
      equipDelete: 'Bottomhole assembly successfully removed!',
      equipClone: 'Bottomhole assembly successfully cloned!',
      connectionCreate: 'Bottomhole assembly connection successfully created!',
      connectionSave: 'Bottomhole assembly connection successfully saved!',
      connectionDelete: 'Bottomhole assembly connection successfully removed!',
      copied:
        'Link to these calculation results has been copied to the clipboard',
      sharedOn:
        'Calculation results can be obtained via the shared access link',
      sharedOff:
        'Shared access to the calculation results via the link is closed',
      normalize:
        'Normalization of inclinometry has been successfully completed',
      transfer: 'Directional survey transfer has been successfully completed',
    },
  },

  validations: {
    required: 'Required field',
    min: "Can't be less than {min}",
    max: "Can't be more than {max}",
    email: 'Please enter a valid email',
    zero: 'The field cannot be 0',
    integer: 'The value must be an integer',
  },

  areas: {
    length: 'Section length',
    outer: 'Outer diameter',
    inner: 'Inner diameter',
    save: 'Save section',
    add: 'Add section',
    edit: 'Edit section',
    lengthInfo: 'Section length',
    number: 'Section number',
  },

  resultsScreen: {
    intervals: 'Intervals',
    pcs: 'qty',
    pipes: 'Strings',
    elements: 'elements',
    equipment: 'Bottomhole assembly',
    calculation: 'Calculation',
    resultsCalculations: 'Calculation results',
    filter: 'Filter',
    toNewCalculation:
      'The report list is empty. To add a report, switch to "Simulation and calculations"',
    goToCalculators: 'Simulation and calculations',
    measureTypeM: 'Metric',
    measureTypeUS: 'Imperial',
  },

  fieldScreen: {
    fieldsList: 'Field list',
    fieldsListIsEmpty: 'The fields list is empty',
    fieldSearch: 'Search by fields',
    addField: 'Add field',
    editField: 'Edit field',
    newFieldLabel: 'Field name',
    save: 'Save field',
    create: 'Create field',
  },

  wells: {
    list: 'Well list',
    search: 'Search by well',
    cardTitle: 'Creating a well',
    cardTitleEdit: 'Editing a well',
    create: 'Create a well',
    save: 'Save a well',
    name: 'Well name',
    type: 'Well type',
    cluster: 'Well cluster',
    empty: 'No wells created for "{msg}"',
    filter: 'Filter',
    selectField: 'Select a field',
    selectType: 'Select a type',
    selectCluster: 'Select a cluster',
    clear: 'Reset filter',
    emptyWells:
      'No well added yet or no results found for selected filters. To create a new well, switch to "Fields" and select a field to create a well',
  },

  wellbores: {
    search: 'Search by wellbores',
    cardTitle: 'Creating a wellbore',
    create: 'Create a wellbore',
    name: 'Wellbore name',
    depthCurrent: 'Current bottomhole depth',
    depthProject: 'Design bottomhole depth',
    alt: 'Altitude',
    height: 'Rotary table elevation',
    declination: 'Magnetic declination',
    empty: 'No wellbores created for "{msg}"',
  },

  wellbore: {
    editTitle: 'Edit a wellbore',
    save: 'Save changes',
    info: 'General information',
    params: 'Wellbore specifications',
    points: 'Borehole profile',
    intervals: 'Well design',
    equips: 'Bottomhole assembly',
    vizualization: 'Show well design',
    check: 'Check wellbore and bottomhole assembly',
    checkTitle: 'Check wellbore and bottomhole assembly',
    checkBtn: 'Check',
    calculate: 'Perform calculations',
    back: 'Cancel',
    goToCalc: 'Switch to calculation',
    calcs: 'Calculators',
    equip: 'Bottomhole assembly',
    dialogCheckTitle:
      'The result of checking the completed data on the wellbore and equipmentset',
    dialogCheckSuccess:
      'The data of the well design and bottomhole assembly are filled in correctly',
    activation: {
      activate: 'Enable integration',
      deactivate: 'Complete integration',
      deactivateTitle: 'Integration completion',
      formTitle: 'Actual (drilled) wellbore condition',
      success: 'Integration successfully enabled',
      info: 'Connection information',
      copy: 'Copy information',
      staticWellbore: 'Static',
      dynamicWellbore: 'Dynamic',
      factWellbore: 'Actual',
      planActual: 'Plan-vs-actual validation',
      bindData: 'Data transfer',
      unbindData: 'Disable data transfer',
      unbindTitle: 'Disabling data transfer',
      bindWarning: 'This step will stop data exchange with the integrator',
      activationWarning:
        'This step will delete the actual and dynamic wellbores',
    },
    warningText: 'Warning',
    errorText: 'Error',
    criticalText: 'Critical error',
  },

  equips: {
    title: 'Bottomhole assembly list',
    equip: 'Bottomhole assembly',
    save: 'Save bottomhole assembly',
    add: 'Create bottomhole assembly',
    name: 'Bottomhole assembly name',
    enterName: 'Enter a name',
    purpose: 'Bottomhole assembly purpose',
    enterPurpose: 'Enter a purpose',
    empty: 'No bottomhole assembly created for the wellbore',
    interval: 'Setting interval',
    up: 'Top',
    down: 'Bottom',
    depth: 'Formation interval',
    intervals: 'Application interval',
    forDrilling: 'Drilling',
    notForDrilling: 'Special services',
    length: 'Bottomhole assembly length',
    autolength: 'Automatic length update',
    equipComposition: 'String bottomhole assembly',
  },

  wellboreMain: {
    title: 'General information',
    save: 'Save changes',
    name: 'Wellbore name',
    enterName: 'Enter a wellbore name',
  },

  wellboreParams: {
    title: 'Wellbore specifications',
    saveParams: 'Save changes',
    currentDepth: 'Current bottomhole depth',
    projectDepth: 'Design bottomhole depth',
    altitude: 'Altitude',
    rotorHeight: 'Rotary table elevation',
    magneticDeclination: 'Magnetic declination',
    gridDeclination: 'Meridian Convergence',
    resistanceCoefs: 'Resistance coefficients',
    resistanceCoefUp: 'Resistance coefficient (↑)',
    resistanceCoefDown: 'Resistance coefficient (↓)',
    blockValid: 'Blocking validations',
    ignoreInc: 'Ignore directional survey blocking validations',
    ignoreRes: 'Ignore blocking validations for calculations',
    notIgnore: 'Do not ignore',
    ignoreSingle: 'Ignore once',
    ignoreAlways: 'Ignore always',
    blockDrilling: 'Drilling rig parameters',
    drillingWorkTorque: 'Maximum rig operating torque',
    drillingLoadCapacity: 'Drilling rig load capacity',
  },

  wellboreProfile: {
    title: 'Borehole profile',
    point: 'Profile point',
    depth: 'Measuring point depth',
    depthMini: 'Depth',
    edit: 'Edit point',
    add: 'Add profile point',
    radioSelect: 'Azimuth type',
    number: 'Measuring point number',
    depthPoint: 'Depth along wellbore at the point of directional survey',
    zenith: 'Inclination angle',
    azimuth: 'Azimuth',
    azimuthM: 'Magnetic azimuth',
    azimuthG: 'Geographic azimuth',
    azimuthGr: 'GRID azimuth',
    save: 'Save profile',
    optionM: 'Magnetic azimuth',
    optionG: 'Geographic azimuth',
    optionGr: 'GRID azimuth',
    azimuthTypeM: 'magnetic',
    azimuthTypeG: 'geographic',
    azimuthTypeGr: 'GRID',
    fileImport: 'Autocomplete (by template)',
    downloadFile: 'Download template',
    importXls: 'Import XLS',
    fileName: 'Template.xls',
    empty: 'No profile points created for wellbore',
    selectAction: 'Select action',
    validationWellbore: 'Wellbore profile validation',
    viewGraph: 'View wellbore profile',
    transferToFact: 'Transfer to the actual wellbore',
    transferToDyn: 'Transfer to the dynamic wellbore',
    normalize: 'Directional survey normalization',
    clearPoints: 'Delete all profilepoints',
    modal: 'Import XLS',
    modalText: 'Select the method of loading new profile points',
    modalAdd: 'Add',
    modalReplace: 'Replace',
    clear: {
      modal: 'Alert',
      text: 'Are you sure?',
      confirm: 'OK',
      cancel: 'Cancel',
    },
    migrate: {
      btn: 'Directional survey transfer',
      alert:
        'Directional survey transfer is available only for dynamic and actual wellbore with activated integration',
      modal: 'Directional survey transfer',
      text_fd:
        'Are you sure you want to transfer all the profile points from the actual wellbore to the dynamic wellbore? All profile points in the dynamic wellbore will be completely replaced!',
      text_df:
        'Are you sure you want to transfer all the profile points from the dynamic wellbore to the actual wellbore? All profile points in the actual wellbore will be completely replaced!',
      confirm: 'OK',
      cancel: 'Cancel',
    },
    graph: {
      title: 'Wellbore profile diagram',
      fact: 'Actual',
      future: 'Prediction',
      depth: 'Plan',
    },
    status: 'Profile point status',
    statusPlan: 'Plan',
    statusPredict: 'Prediction',
    statusFact: 'Actual',
    actual: 'Acceptable',
    notactual: 'Not acceptable',
    unreliable: 'Doubtful',
    truthful: 'Truthful',
    mismatch: 'Trajectory misalignment',
    date: 'Measurement date and time',
    dialogCheckProfileTitle:
      'The result of checking the completed data on the wellbore profile',
    dialogCheckProfileSuccess:
      'The data of the wellbore profile is filled in correctly',
    continueCheckProfile: 'Start validation',
    withProcessing: 'Verified',
    withoutProcessing: 'Non verified',
    processingHint:
      '“Verified” means quality check of the incoming data performed by the software (see Operation Manual)',
  },

  wellboreVizualization: {
    title: 'Well design',
    close: 'Close',
    params: 'Well design specifications',
    confirm: 'Apply',
    equip: 'Bottomhole assembly',
    show: 'Display',
    infoIntervals: 'Intervals overview',
    infoElements: 'Strings overview',
    infoEquips: 'Bottomhole assembly overview',
    disableClicks: 'Disable clickability',
    mm: 'mm',
    m: 'm',
    depth: 'Depth',
    radius: 'Radius',
    gradients: 'Gradient',
    noGradient: 'No gradient selected',
  },

  calculators: {
    pageTitle: 'Simulation and calculations',
    volTitle: 'Volumes',
    mechTitle: 'Mechanics',
    calcs: {
      volume: 'Well space volume',
      strength: 'Expected and acceptable stresses',
      critLimits: 'Critical limits',
      forecasts: 'Forecasts and performance',
    },
    emptyText: 'To perform the calculation, create a field and a well',
    emptyCreate: 'Start creating',
    selectWell: 'Well sellection',
    goToCalc: 'Switch to calculation',
    goToConstructor: 'Open wellbore constructor',
    inProcess: 'In development',
    textRepeat:
      'You have already made a similar calculation {date}. Should the previously entered input data be copied?',
    newCalculation: 'Create a new calculation',
    copyInputData: 'Copy the initial data',
    showResult: 'View calculation',
    rtk: 'By OTM',
    nortk: 'Without OTM',
    opDrilling: 'Drilling operations',
    opCasing: 'Casing descent operations',
  },

  onboarding: {
    welcome: 'Welcome to Well Intervention Modeling System',
    fields: 'Fields',
    fieldsText: 'Create and browse fields for easier well filtering',
    wells: 'Wells',
    wellsText:
      'Enter information on well design and downhole equipment to start modelling',
    calcs: 'Simulation and calculations',
    calcsText:
      'Create realistic well models of any complexity and run simulations',
    results: 'Results and reports',
    resultsText: 'Review simulation results and share reports',
    profile: 'Your profile',
    profileText:
      'Press the WellPro logo to access the profile settings and exit the app',
    next: 'Next',
    skip: 'Get to work',
  },

  login: {
    error: 'Error',
    toEnterToSystem: 'Use your account to log in',
    enterLogin: 'Enter a login',
    login: 'Login',
    enterPassword: 'Enter a password',
    password: 'Password',
    forgetPassword: 'Forgot your password?',
    enter: 'Sign in',
    register: 'Registration',
  },

  passReset: {
    title: 'Create a password recovery request',
    problemType: 'Recovery password',
    cardTitle: 'Enter your data',
    email: 'Contact email',
    emailLabel: 'Enter a email',
    type: 'Problem type',
    typeLabel: 'Select a type',
    username: 'User account',
    usernameLabel: 'Enter your login',
  },

  passChange: {
    title: 'Change password',
    newPass: 'New password',
    newPassLabel: 'Enter a new password',
    newPassConfirm: 'Confirm password',
    newPassConfirmLabel: 'Confirm your new password',
    oldPass: 'Current password',
    oldPassLabel: 'Enter a current password',
  },

  registration: {
    title: 'Registration',
    userRegistered: 'The user has been successfully registered',
    error: 'Error',
    needToRegistration:
      'Please enter your email, preferred login and password to register',
    email: 'E-mail',
    enterEmail: 'Enter a email',
    login: 'Login',
    enterLogin: 'Enter a login',
    password: 'Password',
    enterPassword: 'Enter a password',
    passwordConfirm: 'Confirm password',
    retryPassword: 'Repeat a password',
    register: 'Register',
    back: 'Back',
    disabledTitle: 'Registration is not available!',
    disabledText:
      'The ability to register users is available only to the administrator through the administration panel. To register, contact the Well Pro administrator.',
  },

  profile: {
    title: 'Settings',
    units: 'Unit system',
    locale: 'App language',
    color: 'App colour scheme',
    save: 'Save changes',
    support: 'Contact support',
    unitsM: 'Metric system',
    unitsUS: 'Imperial measurement system',
    digits: 'Roundoff precision',
    warning:
      'Carefully! When saving the language settings, the page will automatically reload. Save your data before changing the application language!',
  },

  support: {
    title: 'Contact support',
    type: 'Problem type',
    selectType: 'Select a type',
    comment: 'Comment',
    enterComment: 'Please specify your request',
    email: 'Contact email',
    enterEmail: 'Enter your email address',
  },

  calculatorsLayout: {
    info: 'Well overview',
    initData: 'Initial data',
    calculate: 'Perform calculation',
    steps: 'Calculation stages',
    intervalsCount: 'Number of intervals',
    pipesCount: 'Number of casing strings',
    elementsCount: 'Number of string elements',
    equipElementsCount: 'Number of drilling tool elements',
    selectWell: 'Select another well',
    construction: 'Well design',
    vizualization: 'Display on well design',
    editInitData: 'Edit initial data',
    updown: 'Limits',
    diameter: 'Bit diameter',
    cavernosity: 'Cavernosity factor',
    cased: 'Cased hole',
    notCased: 'Open hole',
    type: 'Casing type',
    interval: 'Setting interval',
    fullLength: 'From wellhead to the bottom of the interval',
    intervalCementing: 'Cementing interval',
    wallFriction: 'Wall friction factor',
    fullLengthPipe: 'Along the string length',
    composition: 'String assembly',
    fileName: 'Report.docx',
    equipInfo: 'Bottomhole assembly',
    visualTitle: 'Data display settings',
    wallFrictionCasedDown: 'Wall friction cased (↓)',
    wallFrictionCasedUp: 'Wall friction cased (↑)',
    wallFrictionUp: 'Wall friction (↑)',
    wallFrictionDown: 'Wall friction (↓)',
  },

  calcs: {
    initData: 'Initial data',
    save: 'Save changes',
    forecastPerformance: 'Performance forecast',
    volume: {
      interval: 'Calculation interval',
      updown: 'Calculation interval limits',
      up: 'Top',
      down: 'Bottom',
      stages: {
        mining: 'Borehole volume',
        pipes: 'Visible volume of casing strings',
        equips: 'Visible volume of all bottomhole assemblies',
        pipesMaterial: 'Material volume of all casing strings',
        equipsMaterial: 'Material volume of all bottomhole assemblies',
        pipesInner: 'Internal volume of all casing strings',
        equipsInner: 'Internal volume of all bottomhole assembly elements',
        well: 'Hole volume',
        annulus: 'Annulus volume',
        effective: 'Well net volume',
        tool: 'Hole volume under the drilling tool',
        annular: 'Interstring space volume',
      },
    },
    strength: {
      conditions: 'Calculation terms',
      resistanceCoefficientUp: 'Resistance coefficient (↑)',
      resistanceCoefficientDown: 'Resistance coefficient (↓)',
      kzp: 'Safety factor',
      wedgeGrip: 'Slip',
      verifConditions: 'Verification conditions',
      step: 'Calculation pitch',
      addLoads: 'Extra load',
      addMoments: 'Additional torques',
      operation: 'Process operation',
      fortress: 'Hardness of rock to be destroyed',
      drillingConditions: 'Operating conditions (drilling/workover)',
      circulation: 'Drilling mud circulation',
      pressure: 'Wellhead annulus pressure',
      density: 'Density of solids',
      concentration: 'Solids concentration',
      channelArea: 'Choke channel area',
      pri: 'Axial bit load',
      mudDensity: 'Mud density',
      mudConsumption: 'Mud flow rate',
      rpmEquip: 'Bottomhole assembly rotation frequency',
      rpmZD: 'Downhole motor rotation frequency',
      speed: 'Assembly running velocity',
      pressureZTS: 'Differential pressure at MWD system',
      pressureVZD: 'Differential pressure at DHM',
      pressureDoloto: 'Pressure drop across the bit (at the nozzle point)',
      kzps: 'Safety factor',
      kzpFull: 'Safety factor',
      nippeles: 'Nipple safety factor',
      mufts: 'Collar safety factor',
      tightness: 'Connections integrity factor',
      customValue: 'Custom value',
      base: 'Platform',
      method: 'Bit rotation method',
      profile: 'Profile',
      wedgeGripType: 'Spider slip type',
      wedgeGripTypeSelect: 'Select spider slip type',
      params: 'Spider slip specifications',
      angle: 'Pipe wrap angle',
      count: 'Number of slips',
      ratio: 'Coverage factor',
      workLength: 'Slip operating length',
      value: 'Ctg(α_sl + φ) value',
      wedgeAngle: 'Slip inclination angle',
      frictionAngle: 'Friction angle',
      stepFull: 'Pitch for calculation points',
      depth: 'Depth',
      effort: 'Axial load',
      valAdd: 'Value',
      newLoad: 'Add load at the point',
      addMomentsFull: 'Additional torques',
      torque: 'Torque',
      newMoment: 'Add torque at the point',
      counts: 'qty',
      azimuthType: 'Type of azimuth in the calculation results',
      showAddResutls: 'Show basic calculations for additional calculations',
      interval: 'Operation interval',
      hookTensileForce: 'Current hook weight',
      rotorTorque: 'Current rotor torque',
      updateParams: 'Update friction model',
      blockEquip: 'Downhole assembly parameters',
      blockWellbore: 'Well parameters',
      blockCirculation: 'Circulation parameters',
      oscillation: 'Oscillation',
      isOscillation: 'Oscillation',
      selects: {
        up: 'Drilling tool POOH',
        down: 'Drilling tool RIH',
        rotating: 'Rotation off bottom after POOH',
        rotatingDown: 'Rotation off bottom after RIH',
        drilling: 'Drilling',
        backreaming: 'Backreaming',
        soft: 'Soft rock',
        medium: 'Medium rock',
        solid: 'Hard rock',
        land: 'Onshore and offshore from fixed platforms',
        float: 'From floating platforms',
        vzd: 'With DHM',
        vsp: 'With TDS (incl. rotor)',
        vertical: 'Vertical',
        directional: 'Directional',
        normal: 'Normal',
        complicated: 'Complicated',
      },
      stages: {
        force: 'Tensile force, buckling effect and lateral force',
        load: 'Max. axial tensile force in the spider slip',
        torque: 'Torque',
        bending: 'Bending moment',
        stress: 'Stress calculation',
        fatigue: 'Fatigue wear',
        extension: 'Extension and torsion of the drill string',
        coefs: 'Bottomhole assembly stiffness factors',
      },
      info: {
        depth: 'Depth',
        effort: 'Axial load',
        torque: 'Torque',
      },
      menuPanels: {
        main: 'Basic conditions',
        secondary: 'Additional conditions',
        friction: 'Verification of forces and friction',
        load: 'Slip grapple axial load',
      },
    },
  },

  infoElements: {
    repeat: 'Repetitive',
    solo: 'Single',
    diff: 'Compound',
    simple: 'Simple',
    length: 'Length',
    repeatLength: 'Repetition length',
    intervalLength: 'Repeating interval',
    repeatCount: 'Number of elements',
    composition: 'Element composition',
    outer: 'Outer diameter',
    inner: 'Inner diameter',
    thickness: 'Element wall thickness',
  },

  results: {
    title: 'Calculation results',
    empty: 'No matching results found, please change your search settings',
    clear: 'Reset filters',
    filters: 'Filter',
    confirm: 'Apply',
    name: 'By calculation name',
    field: 'By field',
    well: 'By well',
    wellbore: 'By wellbore',
    cluster: 'By cluster',
    equip: 'By bottomhole assembly',
    lang: 'By calculation language',
    langRu: 'Russian',
    langEn: 'English',
    unitSystem: 'By unit system',
  },

  result: {
    results: 'Calculation results',
    empty: 'You have not selected any calculation results to display!',
    title: 'Calculation results',
    show: 'Show the selection',
    interval: 'Selected calculation interval',
    points: 'Selected calculating points',
    conditions: 'Operation conditions',
    emptyResults: 'No results found for this calculation',
    actions: 'Actions',
    getReport: 'Compile a report',
    showChart: 'Display on the graph',
    showVisualization: 'Display on the construction',
    share: 'Share',
    copyInputDate: 'Repeat calculation',
    depth: 'Measuring point depth',
    up: 'Drilling tool POOH',
    down: 'Drilling tool RIH',
    overfillDown: 'Rotation off bottom after RIH',
    overfillUp: 'Rotation off bottom after POOH',
    drilling: 'Drilling',
    backreaming: 'Backreaming',
    soft: 'Soft rock',
    medium: 'Medium rock',
    solid: 'Hard rock',
    normal: 'Normal',
    complicated: 'Complicated',
    plus: 'Yes',
    minus: 'No',
    result: 'Result',
    count: 'qty',
    setShared: 'Make public',
    chart: 'Charts',
    emptyChart: 'There are no charts for this calculation',
    updateParam: 'Yes',
    notUpdateParam: 'No',
    showResult: 'Open calculation result',
  },

  elements: {
    edit: 'Edit element',
    add: 'Add element',
    addNew: 'Add new element',
    numberPipe: 'String element number',
    number: 'Equipment element number',
    type: 'Element type',
    params: 'Physical properties',
    meterWeight: 'Linear weight',
    limit: 'Endurance strength',
    steelgrade: 'Strength grade / Steel grade',
    steelParams: 'Strength specifications',
    density: 'Density',
    elastic: 'Elastic modulus',
    fluidity: 'Yield point',
    limitStrength: 'Ultimate strength',
    poisson: "Poisson's ratio",
    color: 'Element outline colour',
    colorPipe: 'Element outline colour',
    solo: 'Single',
    repeat: 'Repetitive',
    length: 'Element length',
    lengthPipe: 'Element length',
    repeatLength: 'Repetition length',
    fullLength: 'Up to wellhead',
    fullLengthInterval: 'Along the string length',
    simple: 'Simple',
    complexity: 'Compound',
    outerDiameter: 'Element outer diameter',
    methodType: 'Filling-in method based on',
    innerDiameter: 'Element inner diameter',
    thickness: 'Element wall thickness',
    save: 'Save changes',
    selectInner: 'Inner diameter',
    selectThickness: 'Wall thickness',
    elementInfo: {
      repeatFullSimple:
        'Repeated over {length} {lengthUc} over the entire length, simple',
      repeatFullCompl:
        'Repeated over {length} {lengthUc} over the entire length, compound',
      soloFullSimple: 'Single over the entire length, simple',
      soloFullCompl: 'Single over the entire length, compound',
      repeatLengthSimple:
        'Repeated over {length} {lengthUc} stretching {repeat} {repeatUc}, simple',
      repeatLengthCompl:
        'Repeated over {length} {lengthUc} stretching {repeat} {repeatUc}, compound',
      soloLengthSimple: 'Single over {length} {lengthUc}, simple',
      soloLengthCompl: 'Single over {length} {lengthUc}, compound',
    },
    rockDestruction: 'Rock destruction tool',
    downholeMotor: 'Downhole motor',
    maxAxialStretching: 'Allowable axial tensile load',
    maxAxialCompression: 'Allowable axial compressive load',
    maxTorque: 'Allowable torque',
  },

  intervals: {
    list: 'Intervals list',
    k: 'K(cavernosity)',
    d: 'D(bit)',
    composition: 'Well design',
    save: 'Save an interval',
    add: 'Create an interval',
    number: 'Design interval number',
    type: 'Enter interval type',
    params: 'Interval properties',
    coefWall: 'Wall friction factor',
    coefCavernosity: 'Cavernosity factor',
    boundaries: 'Interval limits',
    diameter: 'Bit diameter',
    cased: 'Cased hole',
    notCased: 'Open hole',
    pipeType: 'Casing type',
    pipeInterval: 'String setting interval',
    fullLength: 'From wellhead to the bottom of the interval',
    cementing: 'Cementing',
    intervalCementingFull: 'Cementing interval',
    up: 'Top',
    down: 'Bottom',
    fullLengthPipe: 'Along the string length',
    pipeComposition: 'String assembly',
    empty: 'No drilling intervals created for the wellbore',
    pipe: 'Casing string',
    intervalCementing: 'Cementing interval',
    notCementing: 'No cementing is carried out',
    count: 'Number of elements',
    from: 'From',
    to: 'to',
    paramsCased: 'Cased properties',
    borderUpdate: 'Automatic border update',
    addBtnList: 'Add interval',
    selectAction: 'Select action',
    arrangeIntervals: 'Arrange intervals',
    arrangeElements: 'Arrange elements',
    arrangeAreas: 'Arrange areas',
    elementComposition: 'Compound element assembly',
  },

  connections: {
    edit: 'Edit connection',
    add: 'Enter a connection',
    method: 'Connection specification method',
    type: 'Connection type',
    lockType: 'Tool-joint type',
    lockParams: 'Tool-joint specifications',
    carvingType: 'Thread type',
    outer: 'Outer diameter',
    inner: 'Inner diameter',
    limitStrength: 'Max. axial load',
    carvingCoef: 'Thread friction factor',
    carvingParams: 'Thread specifications',
    profile: 'Cross-sectional shape',
    count: 'Number of wraps per inch',
    step: 'Thread pitch',
    nameApi: 'Int. standard carving type',
    taper: 'Taper rate',
    diameter: 'Pitch diameter of the gauge point',
    countersink: 'Countersink diameter',
    carvingHeight: 'Untruncated thread height',
    carvingDepth: 'Thread depth',
    disembarkation: 'Plain end pipe',
    maxRotate: 'Max. torque',
    maxStrength: 'Max. tensile force',
    customType: 'Custom connection type',
    connectionParams: 'Connection specifications',
    connUp: 'Connection to the wellhead',
    isConnect: 'Availability of a connection',
    nippeleParams: 'Nipple specifications',
    hardness: 'Stiffness factor',
    nippeleEffort: 'Axial load in nipple',
    nippeleCarving: 'Nipple thread specification',
    muftParams: 'Collar specifications',
    muftEffort: 'Axial force in collar',
    muftButt: 'Collar face specification',
    nippeleLength: 'Nipple length',
    connDown: 'Connection to the bottomhole',
    save: 'Save connection',
    delete: 'Delete connection',
    cancel: 'Cancel',
    selects: {
      together: 'For the whole assembly',
      apart: 'For the element ends only',
      nippele: 'Nipple',
      muft: 'Collar',
      lock: 'Tool-joint',
      carving: 'Thread',
      custom: 'Other',
    },
    symbols: {
      lock: 'T-J',
      carving: 'Th',
      custom: 'O',
      nippele: '(N)',
      muft: '(C)',
    },
  },

  mobile: {
    title: 'Attention!',
    text: 'The mobile version of WellPro is in the status of closed beta testing. To log in and work with WellPro, you need to use a Desktop browser',
    continue: 'Continue on site',
    toApp: 'Open in app',
    toShop: 'Download app',
  },
}

export default en

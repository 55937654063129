
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBlockBtn extends Vue {
  @Prop({ default: 'mdi-trash-can-outline' })
  private icon?: string

  @Prop({ default: false })
  private actionBtn?: boolean

  @Prop({ default: 'mdi-content-copy' })
  private actionBtnIcon?: string

  @Prop({ default: false })
  private multiActionBtn?: boolean

  @Prop({ default: 'mdi-arrow-collapse-vertical' })
  private multiActionBtnIcon?: string

  @Prop({ default: 28 })
  private iconSizeDefault?: number

  @Prop({ default: 24 })
  private iconSizeMini?: number

  @Prop({ default: true })
  private actionBtnConfirm?: boolean

  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: false })
  private disableIcons?: boolean

  @Prop({ default: true })
  private hiddenIcons?: boolean

  @Prop({ default: false })
  private bottomIcon?: boolean

  @Prop({ default: false })
  private withBtn?: boolean

  @Prop({ default: false })
  private topIcon?: boolean

  @Prop({ default: false })
  private iconBorder?: boolean

  @Prop({ default: '' })
  private iconHint?: string

  private height: number | null = null

  private event = 'click'

  private isConfirm = false

  private timer = 0

  private get isDelete() {
    return this.icon === 'mdi-trash-can-outline'
  }

  private emitClick() {
    if (!this.disabled) {
      this.isConfirm = false
      this.$emit(this.event)
    }
  }

  private cancel() {
    this.isConfirm = false
  }

  private emitHover() {
    if (!this.disabled && !this.disableIcons) this.$emit('hover')
  }

  private emitBlur() {
    if (!this.disabled) this.$emit('blur')
  }

  private emitHoverAppend() {
    if (!this.disabled && !this.disableIcons) this.$emit('hoverAppend')
  }

  private emitBlurAppend() {
    if (!this.disabled) this.$emit('blurAppend')
  }

  private emitHoverPrepend() {
    if (!this.disabled && !this.disableIcons) this.$emit('hoverPrepend')
  }

  private emitBlurPrepend() {
    if (!this.disabled) this.$emit('blurPrepend')
  }

  private onClick(event: string) {
    if (!this.disabled) {
      this.event = event
      if (this.actionBtnConfirm || this.event === 'click') {
        this.isConfirm = true
      } else {
        this.$emit(this.event)
      }
    }
  }

  private beforeDestroy() {
    void clearTimeout(this.timer)
  }
}
